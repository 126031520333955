import React from 'react'
import { useTheme } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'

const FormCheckboxField = ({
  label,
  checked,
  required,
  disabled,
  error,
  helpertext,
  props,
  handleChange,
  customLabelStyle,
}) => {
  const theme = useTheme()

  const labelStyles = {
    ...customLabelStyle,
    display: 'inline',
    fontFamily: theme.typography.regular.fontFamily
  }

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={checked} />}
        label={<Box sx={labelStyles}>{label}</Box>}
        required={required}
        disabled={disabled}
        onChange={e => handleChange(e.target.checked, props)}
        sx={{ color: error && theme.palette.vividRed.main }}
      />
      { helpertext && <FormHelperText>{helpertext}</FormHelperText> }
    </>    
  )
}

export default FormCheckboxField