import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { emptyUserData } from '../../store/reducers/userData'
import DrawerElement from '../common/DrawerElement'
import HomeContent from './HomeContent'

const ForceLogout = () => {
  const dispatch = useDispatch()
  const { userId, validationString } = useParams()

  useEffect(() => {
    if (userId && validationString) {
      dispatch(emptyUserData())
    }
  }, [dispatch, userId, validationString])

  return (
    <DrawerElement content={<HomeContent />} />
  )
}

export default ForceLogout