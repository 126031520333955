import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { IconSecondaryBox } from '../../themes/styles'
import FormTextField from '../common/formFields/FormTextField'
import FormDatePickerField from '../common/formFields/FormDatePickerField'

const DomainPaymentsSubheader = ({
  filterKeyword,
  setFilterKeyword,
  filterStartDate,
  setFilterStartDate,
  filterEndDate,
  setFilterEndDate,
}) => {
  const texts = useSelector(state => state.settings.langTexts)

  const [searchDomain, setSearchDomain] = useState('')

  const handleChangeSearchDomain = (value) => {
    setSearchDomain(value)
  }

  const handleChangeStartDate = (value) => {
    setFilterStartDate(value)
  }

  const handleChangeEndDate = (value) => {
    setFilterEndDate(value)
  }

  const handleSubmitSearch = () => {
    setFilterKeyword(searchDomain)
  }

  const handleResetSearch = () => {
    setSearchDomain('')
    setFilterKeyword('')
    setFilterStartDate('')
    setFilterEndDate('')
  }

  const searchAdornment = (
    <InputAdornment position='start'>
      <IconButton onClick={handleSubmitSearch} edge='end'>
        <SearchIcon />
      </IconButton>      
    </InputAdornment>
  )

  useEffect(() => {
    setSearchDomain(filterKeyword)
  }, [filterKeyword])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '50px' }}>
      <Box>
        <FormTextField
          type='text'
          label={texts.searchByDomainName}
          placeholder={texts.searchByDomainName}
          value={searchDomain}
          required={false}
          error={false}
          lines='1'
          handleChange={handleChangeSearchDomain}
          endAdornment={searchAdornment}
          fieldWidth='450px'
        />
      </Box>
      <Box sx={{ marginLeft: '20px' }}>
        <FormDatePickerField
          label={texts.period + ' ' + texts._from}
          value={filterStartDate}
          required={false}
          error={false}
          handleChange={handleChangeStartDate}
          fieldWidth='200px'
        />
      </Box>
      <Box sx={{ marginLeft: '20px' }}>
        <FormDatePickerField
          label={texts.period + ' ' + texts._to}
          value={filterEndDate}
          required={false}
          error={false}
          handleChange={handleChangeEndDate}
          fieldWidth='200px'
        />
      </Box>
      {
        (searchDomain || filterStartDate || filterEndDate) && (
          <Box sx={{ marginLeft: '10px' }}>
            <IconSecondaryBox onClick={handleResetSearch}>
              <CloseIcon sx={{ fontSize: '36px' }} />
            </IconSecondaryBox>
          </Box>
        )
      }
    </Box>
  )
}

export default DomainPaymentsSubheader