import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import { SuccessButton } from '../../themes/styles'
import settings from '../../config/settings'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestClients, requestAuthByRefreshToken } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { setUserProfile, setUserTokens } from '../../store/reducers/userData'
import { isValidPersonalId, isValidCompanyId } from '../../helpers/string'
import FormTextField from '../common/formFields/FormTextField'
import FormSelectField from '../common/formFields/FormSelectField'
import BackdropElement from '../common/BackdropElement'

const AccountAdd = ({ setModalOpen, handleOpenNotificationModal }) => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width > settings.desktopBreakpoint ? '600px' : ''

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: theme.palette.vividRed.main,
  }

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.vividRed.main,
  }

  const modalContentStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    textAlign: 'center',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)

  const [accountType, setAccountType] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [personalId, setPersonalId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [errors, setErrors] = useState({})
  const [backdropOpen, setBackdropOpen] = useState(false)

  const accountTypeList = [
    { value: 'person', title: texts.accountTypePerson },
    { value: 'company', title: texts.accountTypeCompany },
  ]

  const scrollToId = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleChangeAccountType = (value) => {
    setAccountType(value)

    setName('')
    setLastname('')
    setPersonalId('')
    setCompanyName('')
    setCompanyId('')

    if (value) {
      setErrors((prev) => {
        return {
          ...prev,
          accountType: '',
          name: '',
          lastname: '',
          personalId: '',
          companyName: '',
          companyId: '',
        }
      })
    }    
  }

  const handleChangeName = (value) => {
    setName(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, name: '' }
      })
    }
  }

  const handleChangeLastname = (value) => {
    setLastname(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, lastname: '' }
      })
    }
  }

  const handleChangePersonalId = (value) => {
    if (value.length < 12) {
      setPersonalId(value)

      if (isValidPersonalId(value)) {
        setErrors((prev) => {
          return { ...prev, personalId: '' }
        })
      }
    }    
  }

  const handleChangeCompanyName = (value) => {
    setCompanyName(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, companyName: '' }
      })
    }
  }

  const handleChangeCompanyId = (value) => {
    if (value.length < 10) {
      setCompanyId(value)

      if (isValidCompanyId(value)) {
        setErrors((prev) => {
          return { ...prev, companyId: '' }
        })
      }
    }    
  }

  const sendRequest = async (postData) => {
    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getAccounts

    const response = await requestClients(requestUrl, 'POST', postData, userData, dispatch, navigate)
    
    setBackdropOpen(false)

    if (response && response.id) {
      setModalOpen(false)

      dispatch(setUserProfile({ profile: response }))

      const auth = await requestAuthByRefreshToken(userData.tokens.refreshToken)

      if (auth.access_token) {  
        dispatch(setUserTokens({ tokens: { accessToken: auth.access_token, refreshToken: auth.refresh_token, } }))
      }

      const newAccountName = accountType === 'company' ? companyName : name + ' ' + lastname

      handleOpenNotificationModal({
        title: texts.createNewAccount,
        content: <Box sx={modalContentStyles}>{texts.accountCreated.replace('###ACCOUNT###', newAccountName)}</Box>,
      })
    } else {
      setErrors((prev) => {
        return { ...prev, general: texts.errorOccured }
      })
    }
  }

  const handleSubmit = () => {
    setBackdropOpen(true)
    let errorsObject = {}

    if (!accountType) {
      errorsObject.accountType = texts.pleaseFillOut
    }

    if (accountType === 'person' && !name) {
      errorsObject.name = texts.pleaseFillOut
    }

    if (accountType === 'person' && !lastname) {
      errorsObject.lastname = texts.pleaseFillOut
    }

    if (accountType === 'person' && !personalId) {
      errorsObject.personalId = texts.pleaseFillOut
    }

    if (accountType === 'person' && personalId && !isValidPersonalId(personalId)) {
      errorsObject.personalId = texts.errorPersonalId
    }

    if (accountType === 'company' && !companyName) {
      errorsObject.companyName = texts.pleaseFillOut
    }

    if (accountType === 'company' && !companyId) {
      errorsObject.companyId = texts.pleaseFillOut
    }

    if (accountType === 'company' && companyId && !isValidCompanyId(companyId)) {
      errorsObject.companyId = texts.errorCompanyId
    }

    if (Object.keys(errorsObject).length === 0) {
      const postData = accountType === 'company' ? {
        account_type: accountType,
        company_name: companyName,
        company_id: companyId,
      } : {
        account_type: accountType,
        name,
        lastname,
        personal_id: personalId,
      }

      sendRequest(postData)
    } else {
      setBackdropOpen(false)
    }

    if (Object.keys(errorsObject).length > 0) {
      scrollToId('formStart')
    }

    setErrors(errorsObject)
  }

  return (
    <Box id='formStart' sx={{ minWidth: formWidth }}>

      {
        errors.general && (
          <Box sx={{ marginBottom: '20px' }}>
            <Alert severity='error' sx={alertStyles}>{errors.general}</Alert>
          </Box>
        )
      }

      <Box sx={{ marginBottom: '20px' }}>
        <FormSelectField
          label={texts.accountType}
          value={accountType}
          required={true}
          error={errors.accountType ? true : false}
          helpertext={errors.accountType && <Box sx={errorHelperStyles} component='span'>{errors.accountType}</Box>}
          selectItems={accountTypeList}
          handleChange={handleChangeAccountType}
        />
      </Box>

      {
        accountType === 'person' && (
          <>
            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label={texts.name}
                placeholder={texts.name}
                value={name}
                required={true}
                error={errors.name ? true : false}
                helpertext={errors.name && <Box sx={errorHelperStyles} component='span'>{errors.name}</Box>}
                lines='1'
                handleChange={handleChangeName}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label={texts.lastname}
                placeholder={texts.lastname}
                value={lastname}
                required={true}
                error={errors.lastname ? true : false}
                helpertext={errors.lastname && <Box sx={errorHelperStyles} component='span'>{errors.lastname}</Box>}
                lines='1'
                handleChange={handleChangeLastname}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label={texts.personalId}
                placeholder={texts.personalId}
                value={personalId}
                required={true}
                error={errors.personalId ? true : false}
                helpertext={errors.personalId && <Box sx={errorHelperStyles} component='span'>{errors.personalId}</Box>}
                lines='1'
                handleChange={handleChangePersonalId}
              />
            </Box>
          </>
        )
      }

      {
        accountType === 'company' && (
          <>
            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label={texts.companyName}
                placeholder={texts.companyName}
                value={companyName}
                required={true}
                error={errors.companyName ? true : false}
                helpertext={errors.companyName && <Box sx={errorHelperStyles} component='span'>{errors.companyName}</Box>}
                lines='1'
                handleChange={handleChangeCompanyName}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label={texts.companyId}
                placeholder={texts.companyId}
                value={companyId}
                required={true}
                error={errors.companyId ? true : false}
                helpertext={errors.companyId && <Box sx={errorHelperStyles} component='span'>{errors.companyId}</Box>}
                lines='1'
                handleChange={handleChangeCompanyId}
              />
            </Box>
          </>
        )
      }

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.save}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default AccountAdd