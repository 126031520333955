import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tokens: {},
  profile: {},
}

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserTokens: (state, action) => {
      state.tokens = action.payload.tokens
    },
    setUserProfile: (state, action) => {
      state.profile = action.payload.profile
    },
    setUser: (state, action) => {
      return action.payload
    },
    emptyUserData: (state) => {
      return initialState
    }
  }
})

export const { setUserTokens, setUserProfile, setUser, emptyUserData } = userDataSlice.actions

export default userDataSlice.reducer