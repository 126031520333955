import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { PageMenuItemBox, PageMenuItemActiveBox } from '../../themes/styles'
import urlClick from '../../helpers/navigation'
import PopperElement from '../common/PopperElement'
import DomainRules from './DomainRules'

const DomainsMenuItem = ({ title, url, files }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const texts = useSelector(state => state.settings.langTexts)

  const handleClick = (e) => {
    urlClick(e, navigate, url)
  }

  return (
    <>
      { pathname === url && <PageMenuItemActiveBox>{texts[title]}</PageMenuItemActiveBox> }
      
      { (pathname !== url && url) && <PageMenuItemBox onClick={handleClick} onMouseDown={handleClick}>{texts[title]}</PageMenuItemBox> }
      
      {
        (pathname !== url && !url && files) && (
          <PopperElement
            actionButton={<PageMenuItemBox>{texts[title]}</PageMenuItemBox>}
            content={<DomainRules files={files} />}
          />
        )
      }
    </>
  )
}

export default DomainsMenuItem