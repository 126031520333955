import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'

const CompactCellElement = ({ keyValue, record, content, valueType, customHeadStyle, customBodyStyle, action }) => {
  const theme = useTheme()

  const headCellStyleInitial = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: theme.palette.secondary.main,
  }

  const bodyCellStyleInitial = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: theme.palette.primary.main,
    cursor: action ? 'pointer' : 'auto',
  }

  // const headCellStyle = customHeadStyle ? { ...headCellStyleInitial, ...customHeadStyle, } : headCellStyleInitial
  // const bodyCellStyle = customBodyStyle ? { ...bodyCellStyleInitial, ...customBodyStyle, } : bodyCellStyleInitial

  const handleClick = () => {
    if (action && record) {
      action(record)
    }
  }

  return (
    <Box>
      <Box sx={headCellStyleInitial}>{content}: </Box>
      <Box sx={bodyCellStyleInitial} onClick={handleClick}>
        { (record && !valueType) && record[keyValue] }

        {
          (record && valueType === 'list' && record[keyValue] && record[keyValue].length > 0) && (
            record[keyValue].map((item, index) => (
              <Box key={index}>{item}</Box>
            ))
          )
        }
      </Box>
    </Box>
  )
}

export default CompactCellElement