import React, { useState, useEffect } from 'react'
import sort from '../../helpers/sort'
import settings from '../../config/settings'
import { request } from '../../network/request'
import { apiDomains } from '../../network/urls'
import DomainsHead from './DomainsHead'
import DomainTariffsTable from './DomainTariffsTable'
import BackdropElement from '../common/BackdropElement'

const DomainTariffsContent = () => {
  const [tariffs, setTariffs] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)

  useEffect(() => {
    const fetchTariffs = async () => {
      setBackdropOpen(true)

      const response = await request(settings.domain + settings.api + apiDomains.getPriceList, 'GET')
      
      if (response && response.length > 0) {
        setTariffs(sort(response, 'priority', 'asc'))
      } else {
        setTariffs([])
      }

      setBackdropOpen(false)
    }

    fetchTariffs()
  }, [])
  
  return (
    <>
      <DomainsHead />

      { tariffs.length > 0 && <DomainTariffsTable tariffs={tariffs} /> }

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainTariffsContent