import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'
import { requestDomains } from '../../network/request'
import settings from '../../config/settings'
import BackdropElement from '../common/BackdropElement'

const DomainNameserversDelete = ({ modalTitle, setModalOpen, setUpdate, handleOpenNotification, domainUid, domainType, name }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [backdropOpen, setBackdropOpen] = useState(false)

  const openErrorModal = (response) => {
    const text = (response && response.data && response.data.message) ? `${texts.errorOccuredWithoutExclamationMark}: ${response.data.message}` : texts.errorOccured

    handleOpenNotification({
      title: modalTitle,
      text,
    })
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)

    const postData = {
      domainType,
      domainUid,
      nameservers: name,
    }

    const response = await requestDomains(settings.fetchDomains + `/?action=client-nameservers-delete&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)

    if (response && response.status && response.status === 100) {
      if (response.data && response.data.result && response.data.result === 1) {
        setModalOpen(false)
        setUpdate(prev => prev + 1)
      } else {
        openErrorModal(response)
      }
    } else {
      openErrorModal(null)
    }

    setBackdropOpen(false)
  }

  return (
    <>
      <Box>{texts.deleteNsRecordNotification.replace('###NS###', name)}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '30px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.confirm}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainNameserversDelete