import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import getTheme from './themes/theme'
import settings from './config/settings'
import ScrollToTop from './helpers/scrollToTop'
import { getIsAccountOwner, getIsSuperadmin, getIsGroupMember } from './helpers/users'
import { setLangTexts } from './store/reducers/settings'
import kaTexts from './lang/kaTexts'
import enTexts from './lang/enTexts'
import LoginPage from './components/users/LoginPage'
import RegisterPage from './components/users/RegisterPage'
import EmailConfirmPage from './components/users/EmailConfirmPage'
import PasswordResetPage from './components/users/PasswordResetPage'
import PasswordResetConfirmPage from './components/users/PasswordResetConfirmPage'
import ValidationEmailResendPage from './components/users/ValidationEmailResendPage'
import ForceLogout from './components/home/ForceLogout'
import HomePage from './components/home/HomePage'
import AccountSettings from './components/users/AccountSettings'
import Domains from './components/domains/Domains'
import DomainPayments from './components/domains/DomainPayments'
import DomainTariffs from './components/domains/DomainTariffs'
import DomainEmailChange from './components/domains/DomainEmailChange'
import DomainEppCodeGenerate from './components/domains/DomainEppCodeGenerate'
import DomainPayWrapper from './components/domains/DomainPayWrapper'
import DomainPayCheckoutResult from './components/domains/DomainPayCheckoutResult'
import Vps from './components/vps/Vps'
import Hosting from './components/hosting/Hosting'
import Internet from './components/internet/Internet'
import Accounts from './components/superadmin/Accounts'
import HeaderSettingsMobile from './components/common/HeaderSettingsMobile'

const App = () => {
  const dispatch = useDispatch()

  const lang = useSelector(state => state.settings.currentLang)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  
  const appTheme = createTheme(getTheme(lang))

  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const isSuperadmin = getIsSuperadmin(userData)
  
  const isVpsGroupMember = getIsGroupMember('vps', currentAccount, userData)
  const isHostingGroupMember = getIsGroupMember('hosting', currentAccount, userData)
  const isDomainsGroupMember = getIsGroupMember('domains', currentAccount, userData)
  const isInternetGroupMember = getIsGroupMember('internet', currentAccount, userData)

  useEffect(() => {
    const currentVersion = localStorage.getItem('appVersionMyGrenaGe');
    
    if (!currentVersion || (currentVersion && currentVersion !== settings.app_version)) {
      localStorage.setItem('appVersionMyGrenaGe', settings.app_version)

      dispatch(setLangTexts({ langTexts: lang === 'en' ? enTexts : kaTexts }))
      
      setTimeout(() => {
        window.location.reload()
      }, 100)      
    } else {
      localStorage.setItem('appVersionMyGrenaGe', settings.app_version)
    }
  }, [dispatch, lang])

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {
            (userData.tokens && userData.tokens.accessToken) ? (
              <>
                <Route path='/' element={<HomePage />} />

                {
                  isSuperadmin && (
                    <>
                      <Route path='/accounts' element={<Accounts />} />
                    </>
                  )
                }

                {
                  (isSuperadmin || isAccountOwner) && (
                    <>
                      <Route path='/account-settings' element={<AccountSettings />} />
                    </>
                  )
                }                

                {
                  (isSuperadmin || isAccountOwner || isVpsGroupMember) &&
                  <>
                    <Route path='/vps' element={<Vps />} />
                  </>
                }

                {
                  (isSuperadmin || isAccountOwner || isHostingGroupMember) &&
                  <>
                    <Route path='/hosting' element={<Hosting />} />
                  </>
                }

                {
                  (isSuperadmin || isAccountOwner || isDomainsGroupMember) &&
                  <>
                    <Route path='/domains' element={<Domains />} />
                    <Route path='/domain-payment-history' element={<DomainPayments />} />
                    <Route path='/domain-tariffs' element={<DomainTariffs />} />
                  </>
                }

                {
                  (isSuperadmin || isAccountOwner || isInternetGroupMember) &&
                  <>
                    <Route path='/internet' element={<Internet />} />
                  </>
                }                
                
                <Route path='/password-reset/:userId/:validationString' element={<ForceLogout />} />
                <Route path='/email-validation/:userId/:validationString' element={<ForceLogout />} />
                <Route path='/settings-mobile' element={<HeaderSettingsMobile />} />
                <Route path='*' element={<HomePage />} />
              </>
            ) : (
              <>
                <Route path='/' element={<LoginPage />} />
                <Route path='/sign-up' element={<RegisterPage />} />
                <Route path='/email-validation/:userId/:validationString' element={<EmailConfirmPage />} />
                <Route path='/password-reset' element={<PasswordResetPage />} />
                <Route path='/password-reset/:userId/:validationString' element={<PasswordResetConfirmPage />} />
                <Route path='/validation-email-resend' element={<ValidationEmailResendPage />} />
                <Route path='*' element={<LoginPage />} />
              </>
            )
          }

          <Route path='/domain-registrant-email-change/:uid' element={<DomainEmailChange />} />
          <Route path='/domain-epp-code-generate/:uid' element={<DomainEppCodeGenerate />} />
          <Route path='/domain-pay/:domain_type/:domain_uid' element={<DomainPayWrapper />} />
          <Route path='/domain-pay-checkout-result/:domain_type/:trans_id/:status_text' element={<DomainPayCheckoutResult />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App