import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'
import settings from '../../config/settings'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestDomains } from '../../network/request'
import { isValidEmail, isValidPhone, isValidPersonalNumber, isValidCompanyCode } from '../../helpers/string'
import parse from 'html-react-parser'
import DomainContactForm from './DomainContactForm'
import DomainGeoContactForm from './DomainGeoContactForm'
import BackdropElement from '../common/BackdropElement'

const DomainContactEdit = ({
  modalTitle,
  setModalOpen,
  setUpdate,
  handleOpenNotification,
  domainType,
  isCompany,
  contactType,
  contactUid,
  nameProp,
  nameEngProp,
  lastnameProp,
  lastnameEngProp,
  orgProp,
  orgEngProp,
  addressProp,
  addressEngProp,
  personalProp,
  phoneProp,
  emailProp,
}) => {
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width < 900 ? `${width - 130}px` : '900px'

  const userData = useSelector(state => state.userData)
  const lang = useSelector(state => state.settings.currentLang)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [name, setName] = useState(nameProp)
  const [nameEng, setNameEng] = useState(nameEngProp)
  const [lastname, setLastname] = useState(lastnameProp)
  const [lastnameEng, setLastnameEng] = useState(lastnameEngProp)
  const [org, setOrg] = useState(orgProp)
  const [orgEng, setOrgEng] = useState(orgEngProp)
  const [address, setAddress] = useState(addressProp)
  const [addressEng, setAddressEng] = useState(addressEngProp)
  const [email, setEmail] = useState(emailProp)
  const [phone, setPhone] = useState(phoneProp)
  const [personal, setPersonal] = useState(personalProp)
  const [errors, setErrors] = useState({})
  const [backdropOpen, setBackdropOpen] = useState(false)
  
  const sendRequest = async (postData) => {
    const requestAction = domainType === 'eng' ? 'client-contact-edit' : 'client-contact-geo-edit'

    const response = await requestDomains(settings.fetchDomains + `/?action=${requestAction}&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
    
    if (response && response.status && response.status === 100) {      
      setUpdate(prev => prev + 1)

      if (response.data && response.data.email_change_pending) {
        handleOpenNotification({
          title: modalTitle,
          text: parse(texts.emailChangeNotification),
        })
      } else {
        setModalOpen(false)
      }
    }
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)
    let errorsObject = {}

    if ((!isCompany || contactType !== 'registrant') && !name) {
      errorsObject[`${contactType}Name`] = texts.pleaseFillOut
    }

    if ((!isCompany || contactType !== 'registrant') && !nameEng && domainType === 'eng') {
      errorsObject[`${contactType}NameEng`] = texts.pleaseFillOut
    }

    if ((!isCompany || contactType !== 'registrant') && !lastname) {
      errorsObject[`${contactType}Lastname`] = texts.pleaseFillOut
    }

    if ((!isCompany || contactType !== 'registrant') && !lastnameEng && domainType === 'eng') {
      errorsObject[`${contactType}LastameEng`] = texts.pleaseFillOut
    }

    if (isCompany && contactType === 'registrant' && !org) {
      errorsObject[`${contactType}Org`] = texts.pleaseFillOut
    }

    if (isCompany && contactType === 'registrant' && !orgEng && domainType === 'eng') {
      errorsObject[`${contactType}OrgEng`] = texts.pleaseFillOut
    }

    if ((domainType === 'eng' || contactType === 'registrant') && !address) {
      errorsObject[`${contactType}Address`] = texts.pleaseFillOut
    }

    if (!addressEng && domainType === 'eng') {
      errorsObject[`${contactType}AddressEng`] = texts.pleaseFillOut
    }

    if (!email) {
      errorsObject[`${contactType}Email`] = texts.pleaseFillOut
    }

    if (email && !isValidEmail(email)) {
      errorsObject[`${contactType}Email`] = texts.registerErrorEmail
    }

    if (!phone) {
      errorsObject[`${contactType}Phone`] = texts.pleaseFillOut
    }

    if (phone && !isValidPhone(phone)) {
      errorsObject[`${contactType}Phone`] = texts.errorPhone
    }

    if (domainType === 'eng' || contactType === 'registrant') {
      if (!personal) {
        errorsObject[`${contactType}Personal`] = texts.pleaseFillOut
      }
  
      if ((!isCompany || contactType !== 'registrant') && personal && !isValidPersonalNumber(personal)) {
        errorsObject[`${contactType}Personal`] = texts.errorPersonalId
      }
  
      if (isCompany && contactType === 'registrant' && personal && !isValidCompanyCode(personal)) {
        errorsObject[`${contactType}Personal`] = texts.errorCompanyId
      }
    }    
    
    if (Object.keys(errorsObject).length === 0) {
      const emailChangeUrl = new URL(window.location.href).origin + '/domain-registrant-email-change/###UID###'

      const postData = {
        contactType,
        contactUid,
        name,
        nameEng,
        lastname,
        lastnameEng,
        org,
        orgEng,
        address,
        addressEng,
        email,
        phone,
        personal,
        lang,
        emailChangeUrl,
      }
      
      await sendRequest(postData)
    }
    
    setBackdropOpen(false)
    setErrors(errorsObject)
  }

  return (
    <Box sx={{ minWidth: formWidth }}>

      {
        domainType === 'eng' &&
        <DomainContactForm
          contactType={contactType}
          isCompany={true}
          isEdit={true}
          name={name}
          setName={setName}
          nameEng={nameEng}
          setNameEng={setNameEng}
          lastname={lastname}
          setLastname={setLastname}
          lastnameEng={lastnameEng}
          setLastnameEng={setLastnameEng}
          org={org}
          setOrg={setOrg}
          orgEng={orgEng}
          setOrgEng={setOrgEng}
          address={address}
          setAddress={setAddress}
          addressEng={addressEng}
          setAddressEng={setAddressEng}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          personal={personal}
          setPersonal={setPersonal}
          errors={errors}
          setErrors={setErrors}
        />
      }

      {
        domainType === 'geo' &&
        <DomainGeoContactForm
          contactType={contactType}
          isCompany={isCompany}
          isEdit={true}
          name={name}
          setName={setName}
          lastname={lastname}
          setLastname={setLastname}
          org={org}
          setOrg={setOrg}
          address={address}
          setAddress={setAddress}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          personal={personal}
          setPersonal={setPersonal}
          errors={errors}
          setErrors={setErrors}
        />
      }

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.save}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default DomainContactEdit