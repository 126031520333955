const domainsList = [
  {
    title: 'myDomains',
    url: '/domains',
  },
  {
    title: 'paymentHistory',
    url: '/domain-payment-history',
  },
  {
    title: 'tariffs',
    url: '/domain-tariffs',
  },
  {
    title: 'rules',
    url: '',
    files: {
      eng: {
        ka: 'https://s3.grena.ge/grena-website-prod/domains/files/Regulation_geo.pdf',
        en: 'https://s3.grena.ge/grena-website-prod/domains/files/Regulation_eng.pdf',
      },
      geo: 'https://s3.grena.ge/grena-website-prod/domains/files/Regulation_geo_%E1%83%92%E1%83%94.pdf',
    }
  },
]

export default domainsList