import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiHosting } from '../../network/urls'
import ContentForm from '../common/forms/ContentForm'
import VpsTarrifsItem from './VpsTarrifsItem'
import ModalNotification from '../common/ModalNotification'
import ModalWindow from '../common/ModalWindow'
import BackdropElement from '../common/BackdropElement'

const VpsTariffsContent = () => {
  const texts = useSelector(state => state.settings.langTexts)

  const [packages, setPackages] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('lg')
  const [backdropOpen, setBackdropOpen] = useState(true)

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleOpenNotification = ({ title, content, size }) => {
    setModalOpen(true)
    setModalWidth(size ? size : 'sm')
    setModalTitle(title)
    setModalContent(<ModalNotification setModalOpen={setModalOpen} notificationText={content} />)
  }

  const handleOpenAddForm = (default_package_name) => {
    setModalOpen(true)
    setModalTitle(texts.order)
    setModalWidth('lg')
    setModalContent(
      <ContentForm
        formName='contact_virtualization'
        default_package_name={default_package_name}
        handleCloseModal={handleCloseModal}
        handleOpenNotification={handleOpenNotification}
      />
    )
  }

  useEffect(() => {
    (async () => {
      const response = await requestClients(settings.domain + settings.api + apiHosting.getVpsPackages, 'GET')
      setPackages(response && response.length > 0 ? response : [])
      setBackdropOpen(false)
    })()

    setTimeout(() => setBackdropOpen(false), 10000)
  }, [])
  
  return (
    <>
      <Grid container spacing={4}>
        {
          packages.map((item, index) => (
            <Grid xs={12} lg={6} xl={4} key={index}>
              <VpsTarrifsItem    
                {...item}
                handleOpenAddForm={handleOpenAddForm}
              />
            </Grid>
          ))
        }
      </Grid>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth={modalWidth}
        onModalClose={handleCloseModal}
      />

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default VpsTariffsContent