import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { SuccessButton, UnderlinedSpaceBetweenSmallBox, KeyNameBox, ValueNameBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { request } from '../../network/request'
import { apiDomains } from '../../network/urls'
import FormSelectField from '../common/formFields/FormSelectField'
import BackdropElement from '../common/BackdropElement'

const DomainPay = ({ setModalOpen, domainType, domainName, domainUid, domainStatus, backUrl }) => {
  const { width } = useWindowDimensions()

  const formWidth = width < 600 ? `${width - 120}px` : '600px'

  const texts = useSelector(state => state.settings.langTexts)

  const [priceOne, setPriceOne] = useState('')
  const [priceMulti, setPriceMulti] = useState('')
  const [term, setTerm] = useState(1)
  const [price, setPrice] = useState('')
  const [fullPrice, setFullPrice] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)

  const termList = [
    { value: 1, title: `1 ${texts.year}` },
    { value: 2, title: `2 ${texts.years}` },
    { value: 3, title: `3 ${texts.years}` },
    { value: 4, title: `4 ${texts.years}` },
    { value: 5, title: `5 ${texts.years}` },
  ]

  const handleChangeTerm = (value) => {
    setTerm(value)
  }

  const getDomainExt = useCallback(() => {
    let domainSplitted = domainName.split('.')
    domainSplitted.shift()
    return '.' + domainSplitted.join('.')
  }, [domainName])

  const getDomainIsPremium = useCallback(() => {
    let domainSplitted = domainName.split('.')
    return domainType === 'eng' && domainSplitted && domainSplitted.length > 0 && domainSplitted[0].length === 2
  }, [domainName, domainType])

  const roundToDecimals = useCallback((num, decimals) => {
    const factor = Math.pow(10, decimals)
    return Math.ceil(num * factor) / factor
  }, [])

  const handleSubmit = () => {
    const redirectUrl = domainType === 'eng' ?
    `${backUrl}?checkout=${domainUid}&term=${term}&email=1` :
    `${backUrl}?checkout=${domainUid}&email=1`

    window.open(redirectUrl, '_self')

    setModalOpen(false)
  }

  useEffect(() => {
    const fetchPrices = async () => {
      setBackdropOpen(true)

      const domainExt = getDomainExt()
      const isPremium = getDomainIsPremium()
      let price_one = ''
      let price_multi = ''
      
      const response = await request(settings.domain + settings.api + apiDomains.getPriceList, 'GET')
      
      if (response && response.length > 0 && domainExt) {
        const tariffElement = response.find(item => item.name === domainExt && item.is_premium === isPremium)

        if (domainStatus === 'pending' && tariffElement && tariffElement.registration_price && tariffElement.registration_price.length > 1) {
          price_one = tariffElement.registration_price[0]
          price_multi = tariffElement.registration_price[1]
        }

        if (domainStatus !== 'pending' && tariffElement && tariffElement.prolong_price && tariffElement.prolong_price.length > 1) {
          price_one = tariffElement.prolong_price[0]
          price_multi = tariffElement.prolong_price[1]
        }
      }

      setPriceOne(parseFloat(price_one))
      setPriceMulti(parseFloat(price_multi))
      setBackdropOpen(false)
    }

    fetchPrices()
  }, [getDomainExt, getDomainIsPremium, domainStatus])

  useEffect(() => {
    const _price = term === 1 ? priceOne : priceMulti
    setPrice(_price * term)
    setFullPrice(roundToDecimals((_price/0.98), 2) * term)
  }, [term, priceOne, priceMulti, roundToDecimals])

  return (
    <>
      {
        price &&
        <Box sx={{ minWidth: formWidth }}>

          <UnderlinedSpaceBetweenSmallBox>
            <KeyNameBox sx={{ fontSize: '18px' }}>{texts.domainAnnualFee}</KeyNameBox>
            <ValueNameBox sx={{ fontSize: '20px' }}>{parseFloat(price).toFixed(2)} {texts.gel}</ValueNameBox>
          </UnderlinedSpaceBetweenSmallBox>

          <UnderlinedSpaceBetweenSmallBox>
            <KeyNameBox sx={{ fontSize: '18px' }}>{texts.comissionFee}</KeyNameBox>
            <ValueNameBox sx={{ fontSize: '20px' }}>{parseFloat(fullPrice - price).toFixed(2)} {texts.gel}</ValueNameBox>
          </UnderlinedSpaceBetweenSmallBox>

          <UnderlinedSpaceBetweenSmallBox>
            <KeyNameBox sx={{ fontSize: '18px' }}>{texts.sum}</KeyNameBox>
            <ValueNameBox sx={{ fontSize: '20px' }}>{parseFloat(fullPrice).toFixed(2)} {texts.gel}</ValueNameBox>
          </UnderlinedSpaceBetweenSmallBox>

          {
            domainType === 'eng' &&
            <UnderlinedSpaceBetweenSmallBox>
              <KeyNameBox sx={{ fontSize: '18px' }}>{texts.term}</KeyNameBox>
              <FormSelectField
                label=''
                value={term}
                required={false}
                selectItems={termList}
                handleChange={handleChangeTerm}
                fieldWidth='200px'
              />
            </UnderlinedSpaceBetweenSmallBox>
          }          

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.pay}</SuccessButton>
          </Box>

        </Box>
      }

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainPay