import React from 'react'
import DrawerElement from '../common/DrawerElement'
import AccountsContent from './AccountsContent'

const Accounts = () => {
  return (
    <DrawerElement content={<AccountsContent />} />
  )
}

export default Accounts