import React from 'react'
import DrawerElement from '../common/DrawerElement'
import AccountSettingsContent from './AccountSettingsContent'

const AccountSettings = () => {
  return (
    <DrawerElement content={<AccountSettingsContent />} />
  )
}

export default AccountSettings