import React from 'react'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/windowDimensions'
import DomainDetailsSubmenuItem from './DomainDetailsSubmenuItem'

const DomainDetailsSubmenu = ({ submenu, hasDns, activePage, setActivePage }) => {
  const { width } = useWindowDimensions()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: width >= 900 ? 'row' : 'column',
        alignItems: width >= 900 ? 'center' : 'flex-start',
        justifyContent: width >= 900 ? 'flex-end' : 'flex-start',
        margin: '20px 0px',
      }}
    >
      {
        submenu.map((item, index) => (
          (item.page !== 'dns' || hasDns) && <DomainDetailsSubmenuItem key={index} {...item} activePage={activePage} setActivePage={setActivePage} />
        ))
      }
    </Box>
  )
}

export default DomainDetailsSubmenu