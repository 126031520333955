import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { PageLabelBox, SuccessButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import DomainsMenu from './DomainsMenu'
import DomainsLinkOldAccount from './DomainsLinkOldAccount'
import ModalWindow from '../common/ModalWindow'

const DomainsHead = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const buttonStyle = width < 900 ? {
    fontSize: theme.typography.regular.fontSize,
  } : {}

  const handleOpenLinkOldAccountModal = () => {
    setModalOpen(true)
    setModalTitle(texts.linkAccount)
    setModalContent(<DomainsLinkOldAccount setModalOpen={setModalOpen} />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: width >= 900 ? 'row' : 'column',
          alignItems: width >= 900 ? 'center' : 'flex-start',
          justifyContent: width >= 900 ? 'space-between' : 'flex-start',
          marginBottom: isAdmin ? '20px' : '50px',
        }}
      >
        <PageLabelBox sx={{ fontSize: width >= 900 ? theme.typography.xlarge.fontSize : theme.typography.large.fontSize, }}>{texts.domains}</PageLabelBox>
        <DomainsMenu />
      </Box>
      
      {
        isAdmin &&
        <Box sx={{ marginBottom: '50px' }}>
          <SuccessButton variant='contained' disableRipple={true} sx={buttonStyle} onClick={handleOpenLinkOldAccountModal}>{texts.syncWithOldPortal}</SuccessButton>
        </Box>
      }      

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth='lg'
        onModalClose={handleCloseModal}
      />
    </>    
  )
}

export default DomainsHead