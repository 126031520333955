import React from 'react'
import Container from '@mui/material/Container'
import Header from '../common/Header'
import ValidationEmailResendForm from './ValidationEmailResendForm'

const ValidationEmailResendPage = () => {
  return (
    <>
      <Header />
      <Container maxWidth='xl'>
        <ValidationEmailResendForm />
      </Container>
    </>
  )
}

export default ValidationEmailResendPage