import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiServices } from '../../network/urls'
import FormTextField from '../common/formFields/FormTextField'
import FormSelectField from '../common/formFields/FormSelectField'
import BackdropElement from '../common/BackdropElement'

const VpsCredentialsRequest = ({ vm_id, handleOpenNotification }) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const formWidth = width < 600 ? `${width*0.9}px` : '600px'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const texts = useSelector(state => state.settings.langTexts)

  const [credentialsList, setCredentialsList] = useState([])
  const [credentials, setCredentials] = useState([])
  const [email, setEmail] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)

  const selectedCredentialsLabelStyle = {
    paddingBottom: '10px',
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.weldonBlue.main,
  }

  const selectedCredentialsStyle = {
    paddingBottom: '5px',
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.secondary.main,
  }

  const getCredentialTitle = (value) => {
    const credentials_object = credentialsList.find(item => item.value === value)
    return credentials_object ? credentials_object.title : ''
  }

  const handleChangeCredentials = (value) => {
    setCredentials(value)
  }

  const handleChangeEmail = (value) => {
    setEmail(value)
  }

  const sendRequest = async (postData) => {
    const requestUrl = settings.domain + settings.clientsUi + apiServices.getVirtualMachinesCredentials + '/' + currentAccount + '/' + vm_id
    await requestClients(requestUrl, 'POST', postData, userData, dispatch, navigate) 
  }
  
  const handleSubmit = async () => {
    setBackdropOpen(true)

    if (email && credentials.length > 0) {
      const postData = { credentials, email }
      await sendRequest(postData)
    }

    setBackdropOpen(false)
    handleOpenNotification({ title: texts.receivePassword, content: texts.vpsPasswordHasBeenSent })
  }

  useEffect(() => {
    const fetchCredentials = async () => {
      const requestUrl = settings.domain + settings.clientsUi + apiServices.getVirtualMachinesCredentials + '/' + currentAccount + '/' + vm_id

      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.length > 0) {
        let credentials_list = []

        response.forEach(item => credentials_list.push({ value: item.id, title: item.title }))

        setCredentialsList(credentials_list)
      } else {
        setCredentialsList([])
      }

      setBackdropOpen(false)
    }

    fetchCredentials()
  }, [userData, currentAccount, vm_id, dispatch, navigate])
  
  return (
    <>
      <Box sx={{ minWidth: formWidth }}>

        <Box sx={{ marginBottom: '20px' }}>
          <FormTextField
            type='text'
            label={texts.email}
            placeholder={texts.email}
            value={email}
            required={true}
            handleChange={handleChangeEmail}
          />
        </Box>

        <Box sx={{ marginBottom: '20px' }}>
          <FormSelectField
            label={texts.passwords}
            value={credentials}
            required={true}
            multiple={true}
            selectItems={credentialsList}
            handleChange={handleChangeCredentials}
          />
        </Box>

        {
          credentials.length > 0 &&
          <>
            <Box sx={selectedCredentialsLabelStyle}>{texts.selectedPasswords}:</Box>
            {
              credentials.map((item, index) => (
                <Box key={index} sx={selectedCredentialsStyle}>{getCredentialTitle(item)}</Box>
              ))
            }
          </>
        }

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.send}</SuccessButton>
        </Box>

      </Box>

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default VpsCredentialsRequest