import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/windowDimensions'
import domainsList from '../../data/domainsList'
import DomainsMenuItem from './DomainsMenuItem'

const DomainsMenu = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const dividerStyle = {
    color: theme.palette.chineseSilver.main,
    margin: '0px 10px',
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: width >= 900 ? 'row' : 'column',
        alignItems: width >= 900 ? 'center' : 'flex-start',
        justifyContent: width >= 900 ? 'flex-end' : 'flex-start',
        margin: width >= 900 ? '10px' : '20px 0px',
      }}
    >
      {
        domainsList.map((item, index) => (
          <React.Fragment key={index}>
            <DomainsMenuItem {...item} />
            {
              (index !== domainsList.length - 1 && width >= 900) && <Box sx={dividerStyle}>|</Box>
            }
          </React.Fragment>          
        ))
      }
    </Box>
  )
}

export default DomainsMenu