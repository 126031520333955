import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { BorderedBlockBox, DomainPriceLabelBox, DomainPriceValueBox, DomainInvoiceLinkBox, DomainPayLinkBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'

const DomainDetailsPayment = ({ price, invoiceUrl, handleOpenPay }) => {
  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)

  const containerStyles = {
    minHeight: '150px',
    margin: width >= 1200 ? '0px 0px 20px 10px' : '0px 0px 20px 0px',
    padding: '0px 20px',
  }

  const handleClickInvoice = () => {
    window.open(invoiceUrl, '_blank')
  }

  return (
    <BorderedBlockBox sx={containerStyles}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <DomainPriceLabelBox>{texts.price}</DomainPriceLabelBox>
          <DomainPriceValueBox>
            <Box>{price}</Box>
            <Box>{texts.gel}</Box>
          </DomainPriceValueBox>
        </Box>

        <DomainInvoiceLinkBox onClick={handleClickInvoice} onMouseDown={handleClickInvoice}>{texts.invoice}</DomainInvoiceLinkBox>

        <DomainPayLinkBox onClick={handleOpenPay}>{texts.pay}</DomainPayLinkBox>
      </Box>
    </BorderedBlockBox>
  )
}

export default DomainDetailsPayment