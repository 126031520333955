import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LogoBox } from '../../themes/styles'
import logoImageEn from '../../themes/images/logo_en.png'
import logoImageKa from '../../themes/images/logo_ka.png'

const Logo = () => {
  const navigate = useNavigate()

  const lang = useSelector(state => state.settings.currentLang)

  const logoImage = lang === 'ka' ? logoImageKa : logoImageEn

  const handleNavigate = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/', '_blank')
    } else if (e.type === 'click') {
      navigate('/')
    }
  }

  return (
    <LogoBox onClick={handleNavigate} onMouseDown={handleNavigate}>
      <img src={logoImage} alt='' style={{ maxHeight: '32px' }} />
    </LogoBox>
  )
}

export default Logo