import React, { useState, useRef } from 'react'
import Box from '@mui/material/Box'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SlickSliderElement = ({
  slides,
  arrows,
  pagination,
  autoplay,
  draggable,
  infinite,
  speed,
  slidesToShow,
  slidesToScroll,
  centerMode,
  sx,
}) => {
  const slider = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)

  const sliderSettings = {
    dots: false,
    autoplay: autoplay ? autoplay : false,
    arrows: false,
    draggable: draggable ? draggable : false,
    infinite: infinite ? infinite : true,
    speed: speed ? speed : 500,
    slidesToShow: slidesToShow ? slidesToShow : 1,
    slidesToScroll: slidesToScroll ? slidesToScroll : 1,
    centerMode: centerMode ? centerMode : false,
  }

  const handleClickPrev = () => {
    slider.current.slickPrev()
  }

  const handleClickNext = () => {
    slider.current.slickNext()
  }

  const handleClickTo = (index) => {
    slider.current.slickGoTo(index)
  }
  
  return (
    <Box
      sx={{
        ...sx,
        position: 'relative',
      }}
    >
      {
        (arrows && slides.length > slidesToShow && centerMode === false) && (
          <>
            { arrows.leftIcon && <Box sx={arrows.leftStyle} onClick={handleClickPrev}>{arrows.leftIcon}</Box> }
            { arrows.rightIcon && <Box sx={arrows.rightStyle} onClick={handleClickNext}>{arrows.rightIcon}</Box> }
          </>
        )
      }

      {
        (pagination && slides.length > slidesToShow) && (
          <Box sx={pagination.style}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {
                slides.map((item, index) => (
                  <Box key={index} onClick={() => handleClickTo(index)}>
                    { index === activeSlide ? pagination.iconActive : pagination.icon }
                  </Box>
                ))
              }
            </Box>
          </Box>
        )
      }

      <Slider
        ref={slider}
        {...sliderSettings}
        afterChange={(e) => setActiveSlide(e)}
      >
        { slides.map(item => item) }
      </Slider>
    </Box>
  )
}

export default SlickSliderElement