import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import useWindowDimensions from '../../helpers/windowDimensions'
// import Button from '@mui/material/Button'
// import CircleIcon from '../common/icons/CircleIcon'

const InternetHead = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)

  const wrapperStyle = {
    borderBottom: `${theme.palette.chineseSilver.main} 1px solid`,
    marginBottom: '10px',
    paddingBottom: '20px',
  }

  const labelStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.xlarge.fontSize : theme.typography.large.fontSize,
    color: theme.palette.primary.main,
  }

  // const helpButtonStyle = {
  //   fontFamily: theme.typography.regular.fontFamily,
  //   fontSize: theme.typography.large.fontSize,
  //   textTransform: 'none',
  //   background: theme.palette.darkCerulean.main,
  //   color: 'white',
  //   height: '50px',
  //   padding: '0px 20px',
  //   cursor: 'pointer',
  //   boxShadow: 'none',
  //   borderRadius: '10px',

  //   '&:hover': {
  //     boxShadow: 'none',
  //     background: theme.palette.darkCerulean.main,
  //     opacity: 0.8,
  //   }
  // }

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={wrapperStyle}
      >
        <Box>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            spacing={2}
          >
            <Box sx={labelStyle}>{texts.internetService}</Box>
            {/* <CircleIcon type='add' size='48' bg='weldonBlue' action={() => {}} /> */}
          </Stack>
        </Box>
        {/* <Button
          variant='contained'
          disableRipple
          disableElevation
          sx={helpButtonStyle}
        >
          {texts.technicalAssistance}
        </Button> */}
      </Stack>
    </>
  )
}

export default InternetHead