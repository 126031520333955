import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import { LoginWrapperBox, PageLabelBox, SecondaryButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestWebpage } from '../../network/request'
import { apiUsers } from '../../network/urls'
import FormTextField from '../common/formFields/FormTextField'
import FormGoogleReCaptcha from '../common/formFields/FormGoogleReCaptcha'
import BackdropElement from '../common/BackdropElement'

const ValidationEmailResendForm = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width > settings.desktopBreakpoint ? '400px' : ''

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: '16px',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const currentLang = useSelector(state => state.settings.currentLang)
  const webpageData = useSelector(state => state.webpageData)

  const captchaRef = useRef('')

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [captchaValidated, setCaptchaValidated] = useState(false)
  const [captchaValidationError, setCaptchaValidationError] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)

  const handleChangeEmail = (value) => {
    setEmail(value)
  }

  const googleCaptchaValidation = async (token) => {
    const response = await fetch(
      `/recaptcha/api/siteverify?secret=${settings.googleReCaptchaSecretKey}&response=${token}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', }
      }
    )
    
    if (!response.ok) {
      const resErrData = await response.json()
      console.log(resErrData)
    } else {
      const resData = await response.json()
      return resData.success
    }   
  }

  const sendRequest = async (postData) => {
    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getUsersValidationEmailResend

    const response = await requestWebpage(requestUrl, 'POST', postData, webpageData, dispatch, navigate)

    setBackdropOpen(false)

    if (response && response.status && response.status === 'resent') {
      navigate('/', { state: { ValidationEmailResendOk: true } })
    } else if (response && response.detail && response.detail.includes('does not exist')) {
      setError(texts.userNotExists)
    } else if (response && response.detail && response.detail.includes('already exists')) {
      setError(texts.userAlreadyActive)
    } else {
      setError(texts.errorOccured)
    }
  }
  
  const handleSubmit = async () => {
    setBackdropOpen(true)
    setError('')
    setCaptchaValidationError(false)

    const reCaptchaResponse = captchaValidated ? true : await googleCaptchaValidation(captchaRef.current.getValue())
    setCaptchaValidated(reCaptchaResponse ? true : false)

    if (email && reCaptchaResponse) {
      sendRequest({ email })      
    } else if (!email) {
      setError(texts.enterEmail)
      setBackdropOpen(false)
    } else {
      setCaptchaValidationError(true)
      setBackdropOpen(false)
    }
  }
  
  return (
    <LoginWrapperBox>
      <Box sx={{ marginBottom: '30px' }}>
        <PageLabelBox>{texts.confirmEmail}</PageLabelBox>
      </Box>

      {
        error && (
          <Box sx={{ width: formWidth, marginBottom: '20px' }}>
            <Alert severity='error' sx={alertStyles}>{error}</Alert>
          </Box>
        )
      }

      <Box sx={{ width: formWidth, marginBottom: '20px' }}>
        <FormTextField
          type='text'
          label={texts.email}
          value={email}
          required={true}
          error={error ? true : false}
          lines='1'
          handleChange={handleChangeEmail}
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormGoogleReCaptcha
          captchaRef={captchaRef}
          lang={currentLang}
          error={captchaValidationError}
        />
      </Box>

      <SecondaryButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.send}</SecondaryButton>

      <BackdropElement open={backdropOpen} />
    </LoginWrapperBox>
  )
}

export default ValidationEmailResendForm