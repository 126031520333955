const georgianAlphabet = ['ა', 'ბ', 'გ', 'დ', 'ე', 'ვ', 'ზ', 'თ', 'ი', 'კ', 'ლ', 'მ', 'ნ', 'ო', 'პ', 'ჟ', 'რ', 'ს', 'ტ', 'უ', 'ფ', 'ქ', 'ღ', 'ყ', 'შ', 'ჩ', 'ც', 'ძ', 'წ', 'ჭ', 'ხ', 'ჯ', 'ჰ']

const latinAlphabet = ['a', 'b', 'g', 'd', 'e', 'v', 'z', 'T', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'J', 'r', 's', 't', 'u', 'f', 'q', 'R', 'y', 'S', 'C', 'c', 'Z', 'w', 'W', 'x', 'j', 'h']

export const convertLatinToGeorgian = (string) => {
  const characters = [...string]
  let result = []
  
  characters.forEach((item, index) => {
    const characterIndex = latinAlphabet.findIndex(item2 => item2 === item)
    
    if (characterIndex !== -1) {
      result.push(georgianAlphabet[characterIndex])
    } else {
      result.push(item)
    }
  })

  return result.join('')
}

export const convertGeorgianToLatin = (string) => {
  const characters = [...string]
  let result = []
  
  characters.forEach((item, index) => {
    const characterIndex = georgianAlphabet.findIndex(item2 => item2 === item)
    
    if (characterIndex !== -1) {
      result.push(latinAlphabet[characterIndex])
    } else {
      result.push(item)
    }
  })

  return result.join('')
}

export const isValidEmail = (string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(string)
}

export const isValidPhone = (string) => {
  return /^[0-9+\b]+$/.test(string)
}

export const isValidPersonalNumber = (string) => {
  return /^[0-9\b]+$/.test(string) && string.length === 11
}

export const isValidCompanyCode = (string) => {
  return /^[0-9\b]+$/.test(string) && string.length === 9
}

export const getIfDomianNeedsProve = (domainName) => {
  const domainArray = domainName.split('.')
  const genericArray = ['edu', 'net', 'org', 'school']

  if (domainArray.length < 3) {
    return
  } else if (genericArray.includes(domainArray[1])) {
    return '.' + domainArray[1] + '.' + domainArray[2]
  } else {
    return
  }
}

const containsUppercase = (str) => {
  return /[A-Z]/.test(str)
}

const containsLowercase = (str) => {
  return /[a-z]/.test(str)
}

const containsNumber = (str) => {
  return /[0-9]/.test(str)
}

const containsSpecialSymbol = (str) => {
  return /[!@#$%^&*()\-+={}[\]:;"'<>,.?/|\\]/.test(str)
}

const isValidNumber = (number) => {
  return /^[0-9]+$/.test(number)
}

export const isValidPassword = (str) => {
  return containsUppercase(str) && containsLowercase(str) && containsNumber(str) && containsSpecialSymbol(str) && str.length > 7
}

export const isValidPersonalId = (str) => {
  return isValidNumber(str) && str.length === 11
}

export const isValidCompanyId = (str) => {
  return isValidNumber(str) && str.length === 9
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getNameFromFullName = (str) => {
  const names = str.split(' ')
  return names && names.length > 0 ? names[0] : ''
}

export const getLastnameFromFullName = (str) => {
  const names = str.split(' ')
  return names && names.length > 1 ? names[1] : ''
}