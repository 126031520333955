import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { isValidEmail, isValidPhone } from '../../../helpers/string'
import FormTextField from '../formFields/FormTextField'
import FormSelectField from '../formFields/FormSelectField'

const ContentFormItem = ({
  field_id,
  priority,
  type,
  required,
  options,
  errors,
  filled,
  updateFilled,
  updateContentTypes,
  formWidth
}) => {
  const lang = useSelector(state => state.settings.currentLang)

  const [label, setLabel] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [value, setValue] = useState('')
  const [selectItems, setSelectItems] = useState([])
  const [choiceType, setChoiceType] = useState('s')
  const [lineNumber, setLineNumber] = useState(1)
  const [contentType, setContentType] = useState('')
  const [integerMin, setIntegerMin] = useState(0)
  const [integerMax, setIntegerMax] = useState(0)
  const [error, setError] = useState(errors.includes(field_id))

  const handleTextChange = (v) => {
    if (type === 'integer') {
      if (v >= integerMin && v <= integerMax) {
        setValue(v)
        updateFilled({ field_id, priority, type, required, value: v })
      }
    } else {
      setValue(v)
      updateFilled({ field_id, priority, type, required, value: v })
    }
    
    if (contentType === 'email') {
      if (isValidEmail(v)) setError(false)
      else setError(true)
    } else if (contentType === 'phone') {
      if (isValidPhone(v)) setError(false)
      else setError(true)
    } else if (contentType === 'id_number') {
      if (v.length === 11) setError(false)
      else setError(true)
    } else {
      if (v) setError(false)
      else if (required) setError(true)
    }
  }

  const handleSelectChange = (v) => {
    setValue(v)
    updateFilled({ field_id, priority, type, required, value: v })
    if (v !== null) setError(false)
    else if (required) setError(true)
  }
  
  useEffect(() => {
    if (options) {
      if (options.label) setLabel(options.label[lang])
      if (options.placeholder) setPlaceholder(options.placeholder[lang])
      if (options.choice_type) setChoiceType(options.choice_type)
      if (options.line_number) setLineNumber(options.line_number)
      if (options.range) {
        setIntegerMin(options.range.min)
        setIntegerMax(options.range.max)
      }

      if (options.content_type) {
        setContentType(options.content_type)
        updateContentTypes({ field_id, contentType: options.content_type })
      }

      if (options.choices) {
        let temp = []
        options.choices.forEach(item => temp.push({ value: item.value, title: item.title[lang] }))
        const tempObject = options.choices.find(item => item.is_selected)
        if (tempObject) setValue(tempObject.value)
        setSelectItems(temp)
      }
    }
  }, [field_id, lang, options, updateContentTypes])

  useEffect(() => {
    setError(errors.includes(field_id))
  }, [errors, field_id])

  useEffect(() => {
    const field_object = filled.find(item => item.field_id === field_id)
    setValue(field_object && field_object.value ? field_object.value : '')
  }, [filled, field_id])
  
  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        {
          (type === 'text' && contentType !== 'hidden') && (
            <FormTextField
              label={label}
              placeholder={placeholder}
              value={value}
              required={required}
              error={error}
              lines={lineNumber}
              handleChange={handleTextChange}
              fieldWidth={formWidth}
            />
          )
        }

        {
          ((type === 'select' && choiceType === 's') || contentType === 'hidden') && ( 
            <FormSelectField
              label={label}
              value={value}
              required={required}
              error={error}
              selectItems={selectItems}
              handleChange={handleSelectChange}
              fieldWidth={formWidth}
            />
          )
        }
      </Box>
    </>
  )
}

export default ContentFormItem