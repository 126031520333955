import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import DeleteIcon from '@mui/icons-material/Delete'
import { UnderlinedSpaceBetweenSmallBox, ValueNameBox, IconErrorBox } from '../../themes/styles'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'

const DomainDetailsNameserversItem = ({ name, ip, domainUid, domainType, handleOpenNameserversDelete }) => {
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const handleClickNameserversDelete = () => {
    handleOpenNameserversDelete({
      name,
      domainUid,
      domainType,
    })
  }

  return (
    <UnderlinedSpaceBetweenSmallBox>
      <ValueNameBox>
        <Box>{name}</Box>
        { ip && <Box>{ip}</Box> }
      </ValueNameBox>
      {
        isAdmin &&
        <IconErrorBox onClick={handleClickNameserversDelete}>
          <DeleteIcon />
        </IconErrorBox>
      }      
    </UnderlinedSpaceBetweenSmallBox>
  )
}

export default DomainDetailsNameserversItem