import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { PageLabelBox, IconWarningBox, IconErrorBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import CircleIcon from '../common/icons/CircleIcon'
import TableElement from '../common/table/TableElement'
import CompactTableElement from '../common/table/CompactTableElement'
import BackdropElement from '../common/BackdropElement'

const AccountUsers = ({
  account,
  updateUsers,
  handleOpenAccountUserAddModal,
  handleOpenAccountUserEditModal,
  handleOpenAccountUserDeleteModal,
}) => {
  const theme = useTheme()
  
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const userData = useSelector(state => state.userData)

  const [users, setUsers] = useState([])
  const [tableFields, setTableFields] = useState([])
  const [tableData, setTableData] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(false)

  useEffect(() => {
    const fetchUsers = async () => {
      setBackdropOpen(true)

      const requestUrl = settings.domain + settings.clientsUi + apiUsers.getUsers + '/' + currentAccount
    
      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.length > 0) {
        setUsers(response)
      } else {
        setUsers([])
      }

      setBackdropOpen(false)
    }

    fetchUsers()
  }, [dispatch, navigate, userData, currentAccount, updateUsers])
  
  useEffect(() => {
    setTableFields([
      {
        keyValue: 'email',
        content: texts.email,
      },
      {
        keyValue: 'groups-roles',
        content: texts.groups + ' ' + texts.and + ' ' + texts.roles,
        valueType: 'list',
      },
      {
        keyValue: 'status',
        content: texts.status,
      },      
      {
        keyValue: 'actions',
        content: '',
      },
    ])
  }, [texts])
  
  useEffect(() => {
    const getGroupName = (group) => {
      const groupNameObject = {
        vps: texts.virtualServers,
        hosting: texts.hosting,
        domains: texts.domains,
        internet: texts.internet
      }

      return groupNameObject[group] ? groupNameObject[group] : ' '
    }

    const generateUserGroupsString = (groups, roles) => {
      if (!groups[account.id]) {
        return
      }

      let result = []

      groups[account.id].forEach(item => {
        let isRoleAdmin = false

        if (roles[account.id] && roles[account.id].length > 0) {
          const findRole = roles[account.id].find(item2 => item2 === 'client-' + item + '-admin')
          if (findRole) {
            isRoleAdmin = true
          }
        }

        const roleName = isRoleAdmin ? texts.admin : texts.onlyView

        result.push(getGroupName(item) + ' - ' + roleName)
      })
      
      return result
    }

    const activeStyles = {
      color: theme.palette.greenCrayola.main,
    }

    const deactivatedStyles = {
      color: theme.palette.vividRed.main,
    }

    if (users.length > 0 && tableFields.length > 0) {
      let tempArray = []

      users.forEach(item => {
        let tempObject = { id: item.id }

        tableFields.forEach(item2 => {
          if (item2.keyValue === 'groups-roles') {
            tempObject[item2.keyValue] = account.account_owner.toLowerCase() === item.email.toLowerCase() ? [texts.owner] : generateUserGroupsString(item.groups, item.roles)          
          } else if (item2.keyValue === 'status') {
            tempObject[item2.keyValue] = item.enabled ? (
              <Box sx={activeStyles}>{texts.active}</Box>
            ) : (
              <Box sx={deactivatedStyles}>{texts.deactivated}</Box>
            )
          } else if (item2.keyValue === 'actions') {
            tempObject[item2.keyValue] = account.account_owner.toLowerCase() === item.email.toLowerCase() ? '' : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconWarningBox onClick={() => handleOpenAccountUserEditModal(item)}>
                  <EditIcon />
                </IconWarningBox>
                <IconErrorBox sx={{ marginLeft: '5px' }} onClick={() => handleOpenAccountUserDeleteModal(item)}>
                  <DeleteIcon />
                </IconErrorBox>
              </Box>
            )
          } else if (item[item2.keyValue]) {
            tempObject[item2.keyValue] = item[item2.keyValue]
          } else {
            tempObject[item2.keyValue] = ''
          }
        })

        tempArray.push(tempObject)
      })

      setTableData(tempArray)
    }
  }, [users, tableFields, account, texts, theme, handleOpenAccountUserEditModal, handleOpenAccountUserDeleteModal])
  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: '30px 0px 20px 0px' }}>
        <PageLabelBox>{texts.users}</PageLabelBox>
        <Box sx={{ marginLeft: '10px' }}>
          <CircleIcon type='add' size='32' bg='greenCrayola' action={handleOpenAccountUserAddModal} />
        </Box>
      </Box>

      {
        width >= 900 ?
        <TableElement
          tableData={tableData}
          tableFields={tableFields}
        /> :
        <CompactTableElement
          tableData={tableData}
          tableFields={tableFields}
        />
      }

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default AccountUsers