import React from 'react'
import DrawerElement from '../common/DrawerElement'
import DomainsContent from './DomainsContent'

const Domains = () => {
  return (
    <DrawerElement content={<DomainsContent />} />
  )
}

export default Domains