import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LanguageSwitchBox } from '../../themes/styles'
import kaTexts from '../../lang/kaTexts'
import enTexts from '../../lang/enTexts'
import { setCurrentLang, setLangTexts } from '../../store/reducers/settings'

const LanguageSwitch = () => {
  const dispatch = useDispatch()

  const lang = useSelector(state => state.settings.currentLang)

  const handleSwitchLanguage = () => {
    dispatch(setCurrentLang({ currentLang: lang === 'ka' ? 'en' : 'ka' }))
    dispatch(setLangTexts({ langTexts: lang === 'ka' ? enTexts : kaTexts }))
  }

  return (
    <LanguageSwitchBox onClick={handleSwitchLanguage}>{ lang === 'ka' ? 'ENG' : 'GEO' }</LanguageSwitchBox>
  )
}

export default LanguageSwitch