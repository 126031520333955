import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { SuccessButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestDomains } from '../../network/request'
import Header from '../common/Header'
import BackdropElement from '../common/BackdropElement'
import parse from 'html-react-parser'

const DomainPayCheckoutResult = () => {
  const theme = useTheme()
  const { height } = useWindowDimensions()
  const { domain_type, trans_id, status_text } = useParams()
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)
  const lang = useSelector(state => state.settings.currentLang)
  const userData = useSelector(state => state.userData)

  const isAuthenticated = userData && userData.tokens && userData.tokens.accessToken

  const [notificationText, setNotificationText] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)

  const wrapperStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.xlarge.fontSize,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${height - 80}px`,
  }

  const handleSubmit = () => {
    navigate(isAuthenticated ? '/domains' : '/')
  }

  useEffect(() => {
    const fetchCheckoutResult = async () => {
      const action = domain_type === 'eng' ? 'client-checkout-result' : 'client-checkout-result-geo'

      const postData = { trans_id, lang }

      const response = await requestDomains(settings.fetchDomains + `/?action=${action}`, 'POST', postData, userData, dispatch, navigate)
      
      let notification_text = texts.errorOccured
      
      if (response && response.status && response.status === 100) {
        if (status_text === 'ok' && response.data && response.data.message) {
          notification_text = parse(response.data.message)
        } else if (status_text === 'delay' && response.data && response.data.domain) {
          notification_text = parse(texts.domainWillBeActivatedIn.replace('###DOMAIN###', `<b>${response.data.domain}</b>`))
        }
      }

      setNotificationText(notification_text)

      setBackdropOpen(false)
    }

    if (trans_id) {
      fetchCheckoutResult()
    } else {
      navigate('/')
    }
  }, [trans_id, domain_type, status_text, userData, dispatch, navigate, texts, lang])
  
  return (
    <>
      <Header />

      <Container maxWidth='xl'>
        <Box sx={wrapperStyle}>
          <Box sx={{ marginBottom: '50px' }}>{notificationText}</Box>
          <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit} sx={{ paddingLeft: '36px' }}>{texts.continue} <NavigateNextIcon fontSize='large' /></SuccessButton>
        </Box>
      </Container>

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainPayCheckoutResult