import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'
import { LoginWrapperBox, PageLabelBox, SecondaryLinkBox, CircleWarningBox, SecondaryButton } from '../../themes/styles'
import settings from '../../config/settings'
import useWindowDimensions from '../../helpers/windowDimensions'
import urlClick from '../../helpers/navigation'
import { requestWebpage } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { isValidEmail, isValidPassword, isValidPersonalId, isValidCompanyId } from '../../helpers/string'
import FormTextField from '../common/formFields/FormTextField'
import FormSelectField from '../common/formFields/FormSelectField'
import FormGoogleReCaptcha from '../common/formFields/FormGoogleReCaptcha'
import BackdropElement from '../common/BackdropElement'

const RegisterForm = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width > settings.desktopBreakpoint ? '600px' : ''

  const submitButtonStyles = {
    width: width > settings.padBreakpoint ? 'auto' : '100%',
    marginTop: width > settings.padBreakpoint ? '0px' : '20px',
  }

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.error.main,
    fontSize: '14px',
  }

  const tooltipStyles = {
    fontFamily: theme.typography.regular.fontFamily,
  }

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: '16px',
  }

  const additionalLinksStyles = {
    marginTop: '40px',
    textAlign: 'center',
    fontFamily: theme.typography.regular.fontFamily,
  }

  const texts = useSelector(state => state.settings.langTexts)
  const currentLang = useSelector(state => state.settings.currentLang)
  const webpageData = useSelector(state => state.webpageData)

  const captchaRef = useRef('')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [accountType, setAccountType] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [personalId, setPersonalId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [errors, setErrors] = useState({})
  const [captchaValidated, setCaptchaValidated] = useState(false)
  const [captchaValidationError, setCaptchaValidationError] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)

  const accountTypeList = [
    { value: 'person', title: texts.accountTypePerson },
    { value: 'company', title: texts.accountTypeCompany },
  ]

  const scrollToId = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleClickLogin = (e) => {
    urlClick(e, navigate, '/')
  }

  const handleChangeEmail = (value) => {
    setEmail(value)

    if (isValidEmail(value)) {
      setErrors((prev) => {
        return { ...prev, email: '' }
      })
    }
  }

  const handleChangePassword = (value) => {
    setPassword(value)

    if (isValidPassword(value)) {
      setErrors((prev) => {
        return { ...prev, password: '' }
      })
    }
  }

  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value)

    if (value && value === password) {
      setErrors((prev) => {
        return { ...prev, confirmPassword: '' }
      })
    }
  }

  const handleChangeAccountType = (value) => {
    setAccountType(value)

    setName('')
    setLastname('')
    setPersonalId('')
    setCompanyName('')
    setCompanyId('')

    if (value) {
      setErrors((prev) => {
        return {
          ...prev,
          accountType: '',
          name: '',
          lastname: '',
          personalId: '',
          companyName: '',
          companyId: '',
        }
      })
    }    
  }

  const handleChangeName = (value) => {
    setName(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, name: '' }
      })
    }
  }

  const handleChangeLastname = (value) => {
    setLastname(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, lastname: '' }
      })
    }
  }

  const handleChangePersonalId = (value) => {
    if (value.length < 12) {
      setPersonalId(value)

      if (isValidPersonalId(value)) {
        setErrors((prev) => {
          return { ...prev, personalId: '' }
        })
      }
    }    
  }

  const handleChangeCompanyName = (value) => {
    setCompanyName(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, companyName: '' }
      })
    }
  }

  const handleChangeCompanyId = (value) => {
    if (value.length < 10) {
      setCompanyId(value)

      if (isValidCompanyId(value)) {
        setErrors((prev) => {
          return { ...prev, companyId: '' }
        })
      }
    }    
  }

  const googleCaptchaValidation = async (token) => {
    const response = await fetch(
      `/recaptcha/api/siteverify?secret=${settings.googleReCaptchaSecretKey}&response=${token}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', }
      }
    )
    
    if (!response.ok) {
      const resErrData = await response.json()
      console.log(resErrData)
    } else {
      const resData = await response.json()
      return resData.success
    }   
  }

  const sendRequest = async (postData) => {
    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getSignUp
    
    const response = await requestWebpage(requestUrl, 'POST', postData, webpageData, dispatch, navigate)
    
    setBackdropOpen(false)
    
    if (response && response.status && response.status === 'created') {
      navigate('/', { state: { SignUpOk: true } })
    } else if (response && response.detail && response.detail === 'error:email_exists') {     
      setErrors((prev) => {
        return { ...prev, email: texts.userAlreadyExists.replace('###EMAIL###', email) }
      })

      scrollToId('formStart')
    } else {
      setErrors((prev) => {
        return { ...prev, topNotification: texts.errorOccured }
      })

      scrollToId('formStart')
    }
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)
    let errorsObject = {}
    setCaptchaValidationError(false)

    if (!email) {
      errorsObject.email = texts.pleaseFillOut
    }

    if (email && !isValidEmail(email)) {
      errorsObject.email = texts.registerErrorEmail
    }

    if (!password) {
      errorsObject.password = texts.pleaseFillOut
    }

    if (password && !isValidPassword(password)) {
      errorsObject.password = texts.passwordRequirements
    }

    if (!confirmPassword) {
      errorsObject.confirmPassword = texts.pleaseFillOut
    }

    if (isValidPassword(password) && confirmPassword && password !== confirmPassword) {
      errorsObject.confirmPassword = texts.registerErrorConfirmPassword
    }

    if (!accountType) {
      errorsObject.accountType = texts.pleaseFillOut
    }

    if (accountType === 'person' && !name) {
      errorsObject.name = texts.pleaseFillOut
    }

    if (accountType === 'person' && !lastname) {
      errorsObject.lastname = texts.pleaseFillOut
    }

    if (accountType === 'person' && !personalId) {
      errorsObject.personalId = texts.pleaseFillOut
    }

    if (accountType === 'person' && personalId && !isValidPersonalId(personalId)) {
      errorsObject.personalId = texts.registerErrorPersonalId
    }

    if (accountType === 'company' && !companyName) {
      errorsObject.companyName = texts.pleaseFillOut
    }

    if (accountType === 'company' && !companyId) {
      errorsObject.companyId = texts.pleaseFillOut
    }

    if (accountType === 'company' && companyId && !isValidCompanyId(companyId)) {
      errorsObject.companyId = texts.registerErrorCompanyId
    }

    const reCaptchaResponse = captchaValidated ? true : await googleCaptchaValidation(captchaRef.current.getValue())
    setCaptchaValidated(reCaptchaResponse ? true : false)
    
    if (Object.keys(errorsObject).length === 0 && reCaptchaResponse) {
      const postData = accountType === 'company' ? {
        email,
        password,
        confirm_password: confirmPassword,
        account_type: accountType,
        company_name: companyName,
        company_id: companyId,
      } : {
        email,
        password,
        confirm_password: confirmPassword,
        account_type: accountType,
        name,
        lastname,
        personal_id: personalId,
      }
      
      sendRequest(postData)
    } else {
      setBackdropOpen(false)
    }
    
    if (!reCaptchaResponse) {
      setCaptchaValidationError(true)
    }

    if (Object.keys(errorsObject).length > 0) {
      scrollToId('formStart')
    }

    setErrors(errorsObject)
  }

  return (
    <LoginWrapperBox id='formStart'>
      <Box sx={{ marginBottom: '30px' }}>
        <PageLabelBox>{texts.registerNewAccount}</PageLabelBox>
      </Box>
      
      {
        errors.topNotification && (
          <Box sx={{ marginBottom: '20px' }}>
            <Alert severity='error' sx={alertStyles}>{errors.topNotification}</Alert>
          </Box>
        )
      }

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          label={texts.email}
          placeholder={texts.email}
          value={email}
          required={true}
          error={errors.email ? true : false}
          helpertext={errors.email && <Box sx={errorHelperStyles} component='span'>{errors.email}</Box>}
          lineNumber='1'
          handleChange={handleChangeEmail}
          fieldWidth={formWidth}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between',  }}>
        <Box sx={{ position: 'relative', marginBottom: '20px' }}>
          <FormTextField
            type='password'
            label={texts.password}
            placeholder={texts.password}
            value={password}
            required={true}
            error={errors.password ? true : false}
            helpertext={errors.password && <Box sx={errorHelperStyles} component='span'>{errors.password}</Box>}
            lineNumber='1'
            handleChange={handleChangePassword}
            fieldWidth={formWidth}
          />
          <Box sx={{ position: 'absolute', right: '-30px', top: '16px' }}>
            <Tooltip placement='left' title={<Box sx={tooltipStyles} component='span'>{texts.passwordRequirements}</Box>}>
              <CircleWarningBox>i</CircleWarningBox>
            </Tooltip>
          </Box>
        </Box>        
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          type='password'
          label={texts.confirmPassword}
          placeholder={texts.confirmPassword}
          value={confirmPassword}
          required={true}
          error={errors.confirmPassword ? true : false}
          helpertext={errors.confirmPassword && <Box sx={errorHelperStyles} component='span'>{errors.confirmPassword}</Box>}
          lineNumber='1'
          handleChange={handleChangeConfirmPassword}
          fieldWidth={formWidth}
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormSelectField
          label={texts.accountType}
          value={accountType}
          required={true}
          error={errors.accountType ? true : false}
          helpertext={errors.accountType && <Box sx={errorHelperStyles} component='span'>{errors.accountType}</Box>}
          selectItems={accountTypeList}
          handleChange={handleChangeAccountType}
          fieldWidth={formWidth}
        />
      </Box>

      {
        accountType === 'person' && (
          <>
            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                label={texts.name}
                placeholder={texts.name}
                value={name}
                required={true}
                error={errors.name ? true : false}
                helpertext={errors.name && <Box sx={errorHelperStyles} component='span'>{errors.name}</Box>}
                lineNumber='1'
                handleChange={handleChangeName}
                fieldWidth={formWidth}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                label={texts.lastname}
                placeholder={texts.lastname}
                value={lastname}
                required={true}
                error={errors.lastname ? true : false}
                helpertext={errors.lastname && <Box sx={errorHelperStyles} component='span'>{errors.lastname}</Box>}
                lineNumber='1'
                handleChange={handleChangeLastname}
                fieldWidth={formWidth}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                label={texts.personalId}
                placeholder={texts.personalId}
                value={personalId}
                required={true}
                error={errors.personalId ? true : false}
                helpertext={errors.personalId && <Box sx={errorHelperStyles} component='span'>{errors.personalId}</Box>}
                lineNumber='1'
                handleChange={handleChangePersonalId}
                fieldWidth={formWidth}
              />
            </Box>
          </>
        )
      }

      {
        accountType === 'company' && (
          <>
            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                label={texts.companyName}
                placeholder={texts.companyName}
                value={companyName}
                required={true}
                error={errors.companyName ? true : false}
                helpertext={errors.companyName && <Box sx={errorHelperStyles} component='span'>{errors.companyName}</Box>}
                lineNumber='1'
                handleChange={handleChangeCompanyName}
                fieldWidth={formWidth}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                label={texts.companyId}
                placeholder={texts.companyId}
                value={companyId}
                required={true}
                error={errors.companyId ? true : false}
                helpertext={errors.companyId && <Box sx={errorHelperStyles} component='span'>{errors.companyId}</Box>}
                lineNumber='1'
                handleChange={handleChangeCompanyId}
                fieldWidth={formWidth}
              />
            </Box>
          </>
        )
      }

      <Box sx={{ marginBottom: '20px' }}>
        <FormGoogleReCaptcha
          captchaRef={captchaRef}
          lang={currentLang}
          error={captchaValidationError}
        />
      </Box>

      <SecondaryButton variant='contained' disableRipple={true} sx={submitButtonStyles} onClick={handleSubmit}>{texts.signUp}</SecondaryButton>

      <Box sx={additionalLinksStyles}>
        <SecondaryLinkBox onClick={handleClickLogin} onMouseDown={handleClickLogin}>{texts.alreadyHaveAnAccount}</SecondaryLinkBox>
      </Box>

      <BackdropElement open={backdropOpen} />
    </LoginWrapperBox>
  )
}

export default RegisterForm