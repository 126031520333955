import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import GroupIcon from '@mui/icons-material/Group'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SettingsIcon from '@mui/icons-material/Settings'
import { IconWhiteBox } from '../../themes/styles'
import { setDrawerOpened, setHasInternet } from '../../store/reducers/settings'
import { getIsSuperadmin, getIsAccountOwner, getIsGroupMember } from '../../helpers/users'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiServices } from '../../network/urls'
import drawerList from '../../data/drawerList'
import headerBackground from '../../themes/images/header_background.png'
import DrawerListItem from './DrawerListItem'
import Logo from './Logo'
import LanguageSwitch from './LanguageSwitch'
import LogoutElement from '../users/LogoutElement'
import HeaderUserMenu from './HeaderUserMenu'
import HeaderAccounts from './HeaderAccounts'

const drawerWidth = 300

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  background: '#001834',
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  height: '64px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent:
  'space-between',
  alignItems: 'center',
  background: `#001834 url(${headerBackground}) no-repeat right center`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

const DrawerElement = ({ content }) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const drawerOpened = useSelector(state => state.settings.drawerOpened)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const hasInternet = useSelector(state => state.settings.hasInternet)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)

  const [drawerMenu, setDrawerMenu] = useState([])

  const sxContent = {
    flexGrow: 1,
    padding: '24px',
    marginTop: '64px',
    fontFamily: theme.typography.regular.fontFamily,    
  }

  const handleOpenDrawer = () => {
    dispatch(setDrawerOpened({ drawerOpened: true }))
  }

  const handleCloseDrawer = () => {
    dispatch(setDrawerOpened({ drawerOpened: false }))
  }

  const handleClickSuperadminAccounts = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/accounts', '_blank')
    } else if (e.type === 'click') {
      navigate('/accounts')
    }
  }

  const handleClickSettings = () => {
    navigate('/settings-mobile')
  }

  useEffect(() => {
    (async () => {
      const requestUrl = settings.domain + settings.clientsUi + apiServices.getNetLocations + '/' + currentAccount
      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      const has_internet_from_response = response && response.length > 0
      if (has_internet_from_response !== hasInternet) {
        dispatch(setHasInternet({ hasInternet: has_internet_from_response }))
      }
    })()
  }, [userData, currentAccount, dispatch, navigate, hasInternet])

  useEffect(() => {
    let drawer_menu = []

    drawerList.forEach(item => {
      const group_name = item.url.replace('/', '')
      const isGroupMember = getIsGroupMember(group_name, currentAccount, userData)
      if (isSuperadmin || isAccountOwner || isGroupMember) {
        if (group_name === 'internet' && !hasInternet) {

        } else {
          drawer_menu.push(item)
        }        
      }
    })

    setDrawerMenu(drawer_menu)
  }, [isSuperadmin, isAccountOwner, userData, currentAccount, hasInternet])

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='fixed' open={drawerOpened}>
        <Toolbar>
          <IconButton
            color='inherit'
            onClick={handleOpenDrawer}
            edge='start'
            sx={{
              marginRight: '24px',
              ...(drawerOpened && { display: 'none' }),
            }}
          >
            <MenuIcon fontSize='large' />
          </IconButton>
          <Logo />
          <HeaderAccounts />
        </Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '24px' }}>
          {
            width >= 1200 ?
            <>
              <Box sx={{ marginRight: '20px' }}>
                <LanguageSwitch />
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <HeaderUserMenu />
              </Box>
              {
                isSuperadmin &&
                <Box sx={{ marginRight: '20px' }}>
                  <IconWhiteBox onClick={handleClickSuperadminAccounts} onMouseDown={handleClickSuperadminAccounts}>
                    <GroupIcon fontSize='large' />
                  </IconWhiteBox>
                </Box>                
              }          
              <LogoutElement />
            </> :
            <IconButton sx={{ color: 'white' }} onClick={handleClickSettings}>
              <SettingsIcon />
            </IconButton>
          }   
        </Box>
      </AppBar>
      <Drawer variant='permanent' open={drawerOpened}>
        <DrawerHeader>
          <IconButton onClick={handleCloseDrawer} sx={{ color: '#FFF' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {
            drawerMenu.map((item, index) => (
              <DrawerListItem key={index} {...item} drawerOpened={drawerOpened} />
            ))
          }
        </List>
      </Drawer>
      <Box component='main' sx={sxContent}>
        { typeof content === "function" ? content({ hasInternet }) : content }
      </Box>
    </Box>
  )
}

export default DrawerElement