import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import { SuccessButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestDomains } from '../../network/request'
import FormTextField from '../common/formFields/FormTextField'
import DomainRegistrationForm from './DomainRegistrationForm'
import BackdropElement from '../common/BackdropElement'

const DomainTransferForm = ({ setModalOpen, setModalContent, domainName, extList, handleOpenNotification, setUpdate }) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width < 600 ? `${width*0.9}px` : '600px'

  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const userData = useSelector(state => state.userData)

  const [eppCode, setEppCode] = useState('')
  const [error, setError] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    width: formWidth,
    marginBottom: '20px',
  }

  const handleChangeEppCode = (value) => {
    setEppCode(value)
  }

  const sendRequest = async (postData) => {
    let result = false

    setBackdropOpen(true)

    const response = await requestDomains(settings.fetchDomains + `/?action=client-check-epp-code&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
    
    if (response && response.status && response.status === 100) {      
      result = true
    }

    setBackdropOpen(false)
    return result
  }

  const handleSubmit = async () => {
    if (eppCode) {
      const postData = { domainName, eppCode }

      const checkEppCode = await sendRequest(postData)

      if (checkEppCode) {
        setError(false)
        setModalContent(<DomainRegistrationForm
          setModalOpen={setModalOpen}
          domainName={domainName}
          domainType='eng'
          extList={extList}
          eppCode={eppCode}
          action='transfer'
          handleOpenNotification={handleOpenNotification}
          setUpdate={setUpdate}
        />)
      } else {
        setError(true)
      }      
    } else {
      setError(true)
    }
  }

  return (
    <Box sx={{ minWidth: formWidth }}>

      { error && <Alert severity='error' sx={alertStyles}>{texts.invalidTransferEppCode}!</Alert> }

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          type='text'
          label={texts.transferEppCode}
          placeholder={texts.transferEppCode}
          value={eppCode}
          required={true}
          error={error}
          lines='1'
          handleChange={handleChangeEppCode}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.continue}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default DomainTransferForm