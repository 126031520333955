export const getTextedDate = (date) => {
  const jsDate = date ?  new Date(date) :new Date()
  return ('0' + jsDate.getDate()).slice(-2) + '.' + ('0' + (jsDate.getMonth() + 1)).slice(-2) + '.' + jsDate.getFullYear()
}

export const getFormattedDate = (date) => {
  const jsDate = date ?  new Date(date) :new Date()

  const year = jsDate.getFullYear()
  const month = ('0' + (jsDate.getMonth() + 1)).slice(-2)
  const day = ('0' + jsDate.getDate()).slice(-2)

  return year + '-' + month + '-' + day
}

export const getFormattedDateTime = (date) => {
  const jsDate = date ?  new Date(date) :new Date()

  const year = jsDate.getFullYear()
  const month = ('0' + (jsDate.getMonth() + 1)).slice(-2)
  const day = ('0' + jsDate.getDate()).slice(-2)
  const hours = ('0' + jsDate.getHours()).slice(-2)
  const minutes = ('0' + jsDate.getMinutes()).slice(-2)
  const seconds = ('0' + jsDate.getSeconds()).slice(-2)

  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}

export const calculateDateTime = (type, operation, number, date) => {
  const jsDate = date ?  new Date(date) :new Date()
  
  if (type === 'minutes') {
    if (operation === 'plus') {
      jsDate.setMinutes(jsDate.getMinutes() + parseInt(number))
    } else {
      jsDate.setMinutes(jsDate.getMinutes() - parseInt(number))
    }
  }

  if (type === 'hours') {
    if (operation === 'plus') {
      jsDate.setHours(jsDate.getHours() + parseInt(number))
    } else {
      jsDate.setHours(jsDate.getHours() - parseInt(number))
    }
  }

  if (type === 'days') {
    if (operation === 'plus') {
      jsDate.setDate(jsDate.getDate() + parseInt(number))
    } else {
      jsDate.setDate(jsDate.getDate() - parseInt(number))
    }
  }
  
  if (type === 'months') {
    if (operation === 'plus') {
      jsDate.setMonth(jsDate.getMonth() + parseInt(number))
    } else {
      jsDate.setMonth(jsDate.getMonth() - parseInt(number))
    }
  }
  
  const year = jsDate.getFullYear()
  const month = ('0' + (jsDate.getMonth() + 1)).slice(-2)
  const day = ('0' + jsDate.getDate()).slice(-2)
  const hours = ('0' + jsDate.getHours()).slice(-2)
  const minutes = ('0' + jsDate.getMinutes()).slice(-2)
  const seconds = ('0' + jsDate.getSeconds()).slice(-2)
  
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}