import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import GroupIcon from '@mui/icons-material/Group'
import { HeaderUserMenuLabelBox, IconPrimaryBox } from '../../themes/styles'
import { getIsSuperadmin } from '../../helpers/users'
import DrawerElement from '../common/DrawerElement'
import HeaderAccounts from './HeaderAccounts'
import LanguageSwitch from './LanguageSwitch'
import HeaderUserMenu from './HeaderUserMenu'
import LogoutElement from '../users/LogoutElement'

const HeaderSettingsMobile = () => {
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)

  const isSuperadmin = getIsSuperadmin(userData)

  const [profileEmail, setProfileEmail] = useState('')

  const handleClickSuperadminAccounts = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/accounts', '_blank')
    } else if (e.type === 'click') {
      navigate('/accounts')
    }
  }

  useEffect(() => {
    setProfileEmail((userData && userData.profile && userData.profile.email) ? userData.profile.email : '')
  }, [userData])

  const getContent = () => {
    return (
      <>
        <HeaderUserMenuLabelBox>{profileEmail}</HeaderUserMenuLabelBox>
        <HeaderAccounts />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: '20px'
          }}
        >
          <Box sx={{ marginRight: '20px' }}>
            <LanguageSwitch />
          </Box>
          <Box sx={{ marginRight: '20px' }}>
            <HeaderUserMenu />
          </Box>
          {
            isSuperadmin &&
            <Box sx={{ marginRight: '20px' }}>
              <IconPrimaryBox onClick={handleClickSuperadminAccounts} onMouseDown={handleClickSuperadminAccounts}>
                <GroupIcon fontSize='large' />
              </IconPrimaryBox>
            </Box>
          }          
          <LogoutElement />
        </Box>
      </>
    )
  }

  return (
    <DrawerElement content={getContent()} />
  )
}

export default HeaderSettingsMobile