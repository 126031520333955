import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import CircleIcon from '../common/icons/CircleIcon'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import VpsServerIpAddressItem from './VpsServerIpAddressItem'
import vpsVisualImage from '../../themes/images/vps_visual.png'
import VpsCredentialsRequest from './VpsCredentialsRequest'
import ModalNotification from '../common/ModalNotification'
import ModalWindow from '../common/ModalWindow'

const VpsServerItem = ({
  id,
  name,
  state,
  vm_id,
  cpu,
  ram,
  disk,
  os,
  kernel,
  ip_addresses_strings,
  setSelectedItems,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-vps-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('md')

  const stateColor = state === 'running' ? theme.palette.greenCrayola.main : theme.palette.vividRed.main

  const wrapperStyle = {
    border: '2px solid',
    borderColor: alpha(theme.palette.darkCerulean.main, 0.5),
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    padding: width >= 900 ? '20px 20px 20px 44px' : '10px 10px 10px 22px',
    margin: width >= 900 ? '24px 0px 48px 24px' : '12px 0px 24px 12px',
    position: 'relative',
  }

  const closeIconWrapperStyle = {
    position: 'absolute',
    top: width >= 900 ? '-24px' : '-12px',
    left: width >= 900 ? '-24px' : '-12px',
  }

  const labelStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    color: theme.palette.blueSapphire.main,
    marginBottom: width >= 900 ? '0px' : '20px',
  }

  const stateStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    height: width >= 900 ? '42px' : '30px',
    padding: width >= 900 ? '0px 30px' : '0px 15px',
    background: alpha(stateColor, 0.2),
    borderRadius: '10px',
    color: stateColor,
    textTransform: 'capitalize',
  }

  const visualWrapperStyle = {
    width: '288px',
    height: '302px',
    background: `url(${vpsVisualImage}) no-repeat`,
    position: 'relative',
    marginBottom: '30px',
  }

  const visualIdStyle = {
    position: 'absolute',
    width: '122px',
    height: '122px',
    top: '29px',
    left: '30px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.xlarge.fontSize,
    color: theme.palette.darkCerulean.main,
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const cpuValueStyle = {
    position: 'absolute',
    width: '54px',
    height: '54px',
    top: '20px',
    right: '14px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    color: theme.palette.weldonBlue.main,
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const cpuLabelStyle = {
    position: 'absolute',
    width: '94px',
    top: '92px',
    right: '-6px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    color: theme.palette.weldonBlue.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const ramValueStyle = {
    position: 'absolute',
    width: '54px',
    height: '54px',
    top: '165px',
    right: '40px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    color: theme.palette.hanBlue.main,
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const ramLabelStyle = {
    position: 'absolute',
    width: '94px',
    top: '237px',
    right: '20px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    color: theme.palette.hanBlue.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const hddValueStyle = {
    position: 'absolute',
    width: '54px',
    height: '54px',
    bottom: '14px',
    left: '51px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    color: theme.palette.pictonBlue.main,
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const hddLabelStyle = {
    position: 'absolute',
    width: '94px',
    bottom: '-30px',
    left: '31px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    color: theme.palette.pictonBlue.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const serverParamLabelStyle = {
    height: '53px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.weldonBlue.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    color: 'white',
    borderRadius: '8px 0px 0px 8px',
  }

  const serverParamValueStyle = {
    height: '53px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.platinum.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: theme.palette.darkCerulean.main,
    borderRadius: '0px 8px 8px 0px',
  }

  const receivePasswordButtonStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    textTransform: 'none',
    background: theme.palette.royalOrange.main,
    color: 'white',
    height: width >= 900 ? '50px' : '30px',
    padding: '0px 20px',
    cursor: 'pointer',
    boxShadow: 'none',
    borderRadius: '10px',

    '&:hover': {
      boxShadow: 'none',
      background: theme.palette.royalOrange.main,
      opacity: 0.8,
    }
  }

  const ipHeadStyle = {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.weldonBlue.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    color: 'white',
    borderRadius: '8px 8px 0px 0px',
  }

  const ipBodyStyle = {
    height: '263px',
    background: theme.palette.platinum.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    color: theme.palette.darkCerulean.main,
    borderRadius: '0px 0px 8px 8px',
    paddingBottom: '20px',
    overflowY: 'auto',
  }

  const handleClickClose = () => {
    setSelectedItems(prev => prev.filter(item => item !== id))
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleOpenNotification = ({ title, content, size }) => {
    setModalOpen(true)
    setModalWidth(size ? size : 'sm')
    setModalTitle(title)
    setModalContent(<ModalNotification setModalOpen={setModalOpen} notificationText={content} />)
  }

  const handleOpenCredentialsRequestForm = () => {
    setModalOpen(true)
    setModalWidth('md')
    setModalTitle(texts.receivePassword)
    setModalContent(<VpsCredentialsRequest vm_id={vm_id} handleOpenNotification={handleOpenNotification} />)
  }

  return (
    <Box sx={wrapperStyle}>
      <Box sx={closeIconWrapperStyle}>
        <CircleIcon type='close' size={ width >= 900 ? '48' : '32' } bg='weldonBlue' action={handleClickClose} />
      </Box>

      <Stack
        direction='row'
        flexDirection={ width >= 900 ? 'row' : 'column' }
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        justifyContent={ width >= 900 ? 'space-between' : 'flex-start' }
        sx={{ marginBottom: width >= 900 ? '40px' : '20px' }}
      >
        <Box sx={labelStyle}>{name}</Box>
        <Box>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={stateStyle}
          >
            <Box>{state}</Box>
          </Stack>
        </Box>
      </Stack>

      <Grid container spacing={{ xs: 4, lg: 6, xl: 8 }}>
        <Grid xs={12} lg={4}>
          {
            width >= 900 ?
            <Box sx={visualWrapperStyle}>
              <Box sx={visualIdStyle}>ID: {vm_id}</Box>
              <Box sx={cpuValueStyle}>{cpu}</Box>
              <Box sx={cpuLabelStyle}>CPU Cores</Box>
              <Box sx={ramValueStyle}>{ram}</Box>
              <Box sx={ramLabelStyle}>RAM (GB)</Box>
              <Box sx={hddValueStyle}>{disk}</Box>
              <Box sx={hddLabelStyle}>HDD (GB)</Box>
            </Box> :
            <>
              <Box>ID: {vm_id}</Box>
              <Box>CPU Cores: {cpu}</Box>
              <Box>RAM (GB): {ram}</Box>
              <Box>HDD (GB): {disk}</Box>
              <Box>OS: {os}</Box>
              <Box>Kernel: {kernel}</Box>
            </>
          }          
        </Grid>
        <Grid xs={12} lg={4}>
          {
            width >= 900 &&
            <>
              <Box sx={{ marginBottom: '20px' }}>
                <Grid container>
                  <Grid xs={4}>
                    <Box sx={serverParamLabelStyle}>OS</Box>
                  </Grid>
                  <Grid xs={8}>
                    <Box sx={serverParamValueStyle}>{os}</Box>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ marginBottom: '20px' }}>
                <Grid container>
                  <Grid xs={4}>
                    <Box sx={serverParamLabelStyle}>Kernel</Box>
                  </Grid>
                  <Grid xs={8}>
                    <Box sx={serverParamValueStyle}>{kernel}</Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          }          

          {
            isAdmin &&
            <Box sx={{ marginTop: width >= 900 ? '30px' : '0px' }}>
              <Button
                variant='contained'
                disableRipple
                disableElevation
                sx={receivePasswordButtonStyle}
                onClick={handleOpenCredentialsRequestForm}
              >
                {texts.receivePassword}
              </Button>
            </Box>
          }       
        </Grid>
        <Grid xs={12} lg={4}>
          <Box sx={ipHeadStyle}>{texts.ipAddresses}</Box>
          <Box sx={ipBodyStyle}>
            {
              ip_addresses_strings.map((item, index) => (
                <VpsServerIpAddressItem key={index} ip_address={item.ip_address} interfaceName={item.interface} />
              ))
            }
          </Box>
        </Grid>
      </Grid>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth={modalWidth}
        onModalClose={handleCloseModal}
      />
    </Box>
  )
}

export default VpsServerItem