import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import useWindowDimensions from '../../helpers/windowDimensions'

const InternetSliderItem = ({ id, title, status, color, isSlider, slidesToShow, setSelectedItems, pushCardForward }) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)

  const sliderItemStyle = {
    display: isSlider ? 'block' : 'inline-block',
    width: isSlider ? undefined : `calc(${100/slidesToShow}% - 20px)`,
    margin: '0px 10px',
    borderRadius: '8px',
    border: `${color} 2px solid`,
    cursor: 'pointer',
  }

  const sliderItemHeadStyle = {
    minHeight: width >= 900 ? '105px' : '75px',
    background: color,
    color: 'white',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    padding: '10px',
  }

  const sliderItemFootStyle = {
    minHeight: '50px',
    background: alpha(color, 0.2),
    color: color,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.small.fontSize,
    padding: '10px',
  }

  const handleClick = () => {
    setSelectedItems(prev => [...prev, id])
    pushCardForward(id)
  }

  return (
    <Box sx={sliderItemStyle} onClick={handleClick}>
      <Stack
        direction='column'
        alignItems='flex-start'
        justifyContent='space-around'
        sx={sliderItemHeadStyle}
      >
        <Box>{title}</Box>
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={sliderItemFootStyle}
      >
        <Box>{texts.status}:</Box>
        <Box>{ status === 'active' ? texts.on : texts.off }</Box>
      </Stack>
    </Box>
  )
}

export default InternetSliderItem