import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import { NoResultBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import TableElement from '../common/table/TableElement'
import CompactTableElement from '../common/table/CompactTableElement'

const HostingMyContent = (props) => {
  const { hostingDomains, backdropOpen } = props
  const theme = useTheme()

  const { width, height } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)

  const [tableFields, setTableFields] = useState([])
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setTableFields([
      {
        keyValue: 'domain',
        content: texts.domain,
      },
      {
        keyValue: 'storage',
        content: texts.storage,
        customHeadStyle: {
          textAlign: 'center',
        },
        customBodyStyle: {
          textAlign: 'center',
        },
      },
      {
        keyValue: 'emails',
        content: texts.corporateEmails,
        customHeadStyle: {
          textAlign: 'center',
        },
        customBodyStyle: {
          textAlign: 'center',
        },
      },
      {
        keyValue: 'status',
        content: texts.status,
        customHeadStyle: {
          textAlign: 'right',
        },
        customBodyStyle: {
          textAlign: 'right',
        },
      },
    ])
  }, [texts])

  useEffect(() => {
    const errorStyle = {
      color: theme.palette.vividRed.main
    }

    if (hostingDomains.length > 0 && tableFields.length > 0) {
      let table_data = []

      hostingDomains.forEach(item => {
        let row_object = {}

        tableFields.forEach(item2 => {
          if (item[item2.keyValue] && item2.keyValue === 'storage') {
            row_object[item2.keyValue] = item[item2.keyValue]
          } else if (item[item2.keyValue] && item2.keyValue === 'status') {
            const statusStyle = item[item2.keyValue] === 'active' ? {} : errorStyle
            const statusName = item[item2.keyValue] === 'active' ? texts.active : texts.cancelled

            row_object[item2.keyValue] = <Box sx={statusStyle}>{statusName}</Box>         
          } else if (item[item2.keyValue]) {
            row_object[item2.keyValue] = item[item2.keyValue]
          } else {
            row_object[item2.keyValue] = ''
          }
        })

        table_data.push(row_object)
      })

      setTableData(table_data)
    } else {
      setTableData([])
    }
  }, [hostingDomains, tableFields, texts, theme])
  
  return (
    <>
      {
        width >= 900 ?
        <TableElement
          tableData={tableData}
          tableFields={tableFields}
        /> :
        <CompactTableElement
          tableData={tableData}
          tableFields={tableFields}
        />
      }

      { (tableData.length === 0 && !backdropOpen) && <NoResultBox sx={{ height: `${height/2}px` }}>{texts.noHostingNotification}</NoResultBox> }
    </>
  )
}

export default HostingMyContent