import React from 'react'
import DrawerElement from '../common/DrawerElement'
import DomainPaymentsContent from './DomainPaymentsContent'

const DomainPayments = () => {
  return (
    <DrawerElement content={<DomainPaymentsContent />} />
  )
}

export default DomainPayments