import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { PopperBox } from '../../themes/styles'

const PopperElement = ({ actionButton, content }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Box onClick={handleOpen}>{actionButton}</Box>
      </ClickAwayListener>
      <Popper
        anchorEl={anchorEl}
        open={open}
        transition
        placement='bottom-start'
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <PopperBox>{content}</PopperBox>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default PopperElement