import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/windowDimensions'

const HostingTariffsItemServices = (props) => {
  const { services } = props

  const theme = useTheme()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.settings.currentLang)

  return (
    <Box sx={ width >= 1200 && { minWidth: '800px' } }>
      {
        services.map((item, index) => (
          <Box
          key={index}
          sx={{
            marginBottom: index !== services.length -1 && '10px',
            borderBottom: `${theme.palette.secondary.main} 1px solid`,
            paddingBottom: '5px',
          }}
          >
            {item[lang]}
          </Box>
        ))
      }
    </Box>
  )
}

export default HostingTariffsItemServices