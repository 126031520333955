import darkScrollbar from '@mui/material/darkScrollbar'
import { grey } from '@mui/material/colors'

const getTheme = (lang) => {
  return {
    palette: {
      mode: 'light',
      primary: { main: '#060E36' },
      secondary: { main: '#2E9CD6' },
      text: { main: '#060E36' },
      weldonBlue: { main: '#7F9FA9' },
      darkCerulean: { main: '#074D78' },
      chineseSilver: { main: '#CCCCCC' },
      lightSilver: { main: '#D9D9D9' },
      lavenderIndigo: { main: '#9B51E0' },
      lavender: { main: '#F1E7F9' },
      greenCrayola: { main: '#27AE60' },
      royalOrange: { main: '#F2994A' },
      vividRed: { main: '#F81010' },
      blueSapphire: { main: '#145F87' },
      hanBlue: { main: '#3A5CCF' },
      pictonBlue: { main: '#3AB3E2' },
      platinum: { main: '#EBE6E6 ' },
      customWhite: { main: '#FFFFFF' },
    },
    typography: {
      regular: {
        fontFamily: lang === 'ka' ? 'ingiri' : 'roboto',
        fontSize: '16px',
      },
      english: {
        fontFamily: 'roboto',
      },
      georgian: {
        fontFamily: 'ingiri',
      },
      xxsmall: {
        fontSize: '10px',
      },
      xsmall: {
        fontSize: '12px',
      },
      small: {
        fontSize: '14px',
      },
      large: {
        fontSize: '18px',
      },
      xlarge: {
        fontSize: '20px',
      },
      xxlarge: {
        fontSize: '24px',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            ...darkScrollbar(
              {
                track: grey[200],
                thumb: grey[400],
                active: grey[400]
              }
            ),
            scrollbarWidth: 'thin'
          },
          div: {
            ...darkScrollbar(
              {
                track: grey[200],
                thumb: grey[400],
                active: grey[400]
              }
            ),
            scrollbarWidth: 'thin'
          },
          body: {
            background: '#FFFFFF',
          },
        }
      }
    }
  }
}

export default getTheme