import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { PageLabelBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestDomains } from '../../network/request'
import Header from '../common/Header'
import DomainPay from './DomainPay'
import BackdropElement from '../common/BackdropElement'

const DomainPayWrapper = () => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { domain_type, domain_uid } = useParams()

  const { height } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)

  const [domainDetails, setDomainDetails] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(false)

  const wrapperStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.xlarge.fontSize,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${height - 80}px`,
  }

  useEffect(() => {
    const fetchDomainDetails = async () => {
      setBackdropOpen(true)

      const response = await requestDomains(settings.fetchDomains + `/?action=client-domain-short-details&domain_uid=${domain_uid}&domain_type=${domain_type}`, 'GET', '', '', dispatch, navigate)
      
      if (response && response.status && response.status === 100) {
        setDomainDetails(response.data)
      } else {
        setDomainDetails('')
        navigate('/')
      }

      setBackdropOpen(false)
    }

    if (domain_uid && domain_type) {
      fetchDomainDetails()
    } else {
      navigate('/')
    }    
  }, [dispatch, navigate, domain_uid, domain_type])

  return (
    <>
      <Header />

      <Container maxWidth='xl'>
        <Box sx={wrapperStyle}>
          {
            domainDetails &&
            <>
              <Box sx={{ marginBottom: '50px' }}>
                <PageLabelBox>{texts.domainPayModalLabel.replace('###DOMAIN###', domainDetails.name)}</PageLabelBox>
              </Box>

              <DomainPay
                setModalOpen={() => {}}
                domainType={domain_type}
                domainName={domainDetails.name}
                domainUid={domainDetails.uid}
                domainStatus={domainDetails.status}
                backUrl={domainDetails.back_url}
              />
            </>            
          }
        </Box>
      </Container>

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainPayWrapper