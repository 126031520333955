export const getAccountInfo = (accountId, userData) => {
  if (!accountId || !userData) {
    return
  }

  if (!userData.profile) {
    return
  }

  if (!userData.profile.accounts) {
    return
  }

  if (userData.profile.accounts.length === 0) {
    return
  }

  const accountObject = userData.profile.accounts.find(item => item.id === accountId)

  return accountObject ? accountObject : ''
}

export const getSuperadminAccountInfo = (accountId, accounts) => {
  if (!accountId) {
    return
  }

  if (!accounts) {
    return
  }

  if (accounts.length === 0) {
    return
  }

  const accountObject = accounts.find(item => item.id === accountId)

  return accountObject ? accountObject : ''
}

export const getIsAccountOwner = (accountId, userData) => {
  const account = getAccountInfo(accountId, userData)
  return userData && userData.profile && userData.profile.email && account && account.account_owner && userData.profile.email.toLowerCase() === account.account_owner.toLowerCase()
}

export const getIsGroupMember = (group, accountId, userData) => {
  const groups = userData && userData.profile && userData.profile.groups && userData.profile.groups[accountId] ? userData.profile.groups[accountId] : []
  return groups.includes(group)
}

export const getHasRole = (role, accountId, userData) => {
  const roles = userData && userData.profile && userData.profile.roles && userData.profile.roles[accountId] ? userData.profile.roles[accountId] : []
  return roles.includes(role)
}

export const getIsSuperadmin = (userData) => {
  return userData && userData.profile && userData.profile.is_superadmin ? true : false
}