import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { SecondaryButton } from '../../themes/styles'
import settings from '../../config/settings'
import { requestDomains } from '../../network/request'
import BackdropElement from '../common/BackdropElement'

const DomainDetailsDnsNotification = ({
  setModalOpen,
  setUpdate,
  modalTitle,
  domainType,
  domainUid,
  actionType,
  action,
  handleOpenNotification,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [notificationText, setNotificationText] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(false)

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const openErrorModal = (response) => {
    const text = (response && response.data && response.data.message) ? `${texts.errorOccuredWithoutExclamationMark}: ${response.data.message}` : texts.errorOccured

    handleOpenNotification({
      title: modalTitle,
      text,
    })
  }

  const dnsActivationRequest = async (isActive) => {
    setBackdropOpen(true)

    const postData = {
      domainType,
      domainUid,
      isActive,
    }

    const response = await requestDomains(settings.fetchDomains + `/?action=client-dns-manage-toggle&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
    
    if (response && response.status && response.status === 100) {
      setModalOpen(false)
      setUpdate(prev => prev + 1)
    } else {
      handleOpenNotification({
        title: texts.dnsManagementActivation,
        text: texts.errorOccured,
      })
    }

    setBackdropOpen(false)
  }

  const setGrenaNsRequest = async () => {
    setBackdropOpen(true)

    const postData = {
      domainType,
      domainUid,
    }
    
    const response = await requestDomains(settings.fetchDomains + `/?action=client-nameservers-set-grena&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)

    if (response && response.status && response.status === 100) {
      if (response.data && response.data.result && response.data.result === 1) {
        setModalOpen(false)
        setUpdate(prev => prev + 1)
      } else {
        openErrorModal(response)
      }
    } else {
      openErrorModal(null)
    }

    setBackdropOpen(false)
  }

  const handleSubmit = async () => {
    if (actionType === 'dns_service') {
      await dnsActivationRequest(action)
    }

    if (actionType === 'set_grena_ns') {
      await setGrenaNsRequest()
    }
  }

  useEffect(() => {
    let notification_text = ''

    if (actionType === 'dns_service') {
      notification_text = action ? texts.dnsManagementActivationNotification : texts.dnsManagementDeactivateNotification
    }

    if (actionType === 'set_grena_ns') {
      notification_text = texts.nsChangeNotification
    }

    setNotificationText(notification_text)
  }, [actionType, action, texts])

  return (
    <>
      <Box>{notificationText}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '30px' }}>
        <SecondaryButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.confirm}</SecondaryButton>
      </Box>
      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainDetailsDnsNotification