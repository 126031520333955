import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { BorderedBlockBox, UnderlinedSpaceBetweenSmallBox, KeyNameBox, ValueNameBox } from '../../themes/styles'
import settings from '../../config/settings'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestDomains } from '../../network/request'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import FormCheckboxField from '../common/formFields/FormCheckboxField'

const DomainDetailsInfo = ({
  name,
  domainType,
  domainUid,
  registrationDate,
  expirationDate,
  statusName,
  statusColor,
  hiddenWhoIs,
  setBackdropOpen,
  setUpdate,
  handleOpenNotification,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const statusStyles = { color: statusColor }

  const checkboxLabelStyle = { color: theme.palette.weldonBlue.main }

  const openErrorModal = (response) => {
    const text = (response && response.data && response.data.message) ? `${texts.errorOccuredWithoutExclamationMark}: ${response.data.message}` : texts.errorOccured

    handleOpenNotification({
      title: texts.hideWhoIs,
      text,
    })
  }

  const toggleWhoIsRequest = async (whois) => {
    setBackdropOpen(true)

    const postData = {
      domainUid,
      whois,
    }

    const response = await requestDomains(settings.fetchDomains + `/?action=client-toggle-whois&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)

    if (response && response.status && response.status === 100) {
      if (response.data && response.data.result && response.data.result === 1) {
        setUpdate(prev => prev + 1)
      } else {
        openErrorModal(response)
      }
    } else {
      openErrorModal(null)
    }

    setBackdropOpen(false)
  }

  const handleChangeHiddenWhoIs = async (value) => {
    await toggleWhoIsRequest(value ? 'hide' : 'show')
  }

  return (
    <BorderedBlockBox
      sx={{
        minHeight: width >= 1200 ? '486px' : 'auto',
        margin: width >= 1200 ? '0px 10px 0px 0px' : '0px 0px 20px 0px',
      }}
    >

      <UnderlinedSpaceBetweenSmallBox>
        <KeyNameBox>{texts.domain}</KeyNameBox>
        <ValueNameBox>{name}</ValueNameBox>
      </UnderlinedSpaceBetweenSmallBox>

      <UnderlinedSpaceBetweenSmallBox>
        <KeyNameBox>{texts.registrationDate}</KeyNameBox>
        <ValueNameBox>{registrationDate}</ValueNameBox>
      </UnderlinedSpaceBetweenSmallBox>

      <UnderlinedSpaceBetweenSmallBox>
        <KeyNameBox>{texts.expirationDate}</KeyNameBox>
        <ValueNameBox>{expirationDate}</ValueNameBox>
      </UnderlinedSpaceBetweenSmallBox>

      <UnderlinedSpaceBetweenSmallBox>
        <KeyNameBox>{texts.status}</KeyNameBox>
        <ValueNameBox sx={statusStyles}>{statusName}</ValueNameBox>
      </UnderlinedSpaceBetweenSmallBox>

      {
        domainType === 'eng' && (
          <Box sx={{ marginTop: '20px' }}>
            <FormCheckboxField
              label={texts.hideWhoIs}
              checked={parseInt(hiddenWhoIs) === 1}
              disabled={!isAdmin}
              handleChange={handleChangeHiddenWhoIs}
              customLabelStyle={checkboxLabelStyle}
            />
          </Box>
        )
      }      

    </BorderedBlockBox>
  )
}

export default DomainDetailsInfo