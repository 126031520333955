const sort = (list, sortBy, direction) => {
  if (direction === 'desc') {
    return [...list].sort((a, b) => {
      let x = a[sortBy]
      let y = b[sortBy]

      if (a[sortBy] === null) {
        x = ''
      }

      if (b[sortBy] === null) {
        y = ''
      }

      return x < y ? 1 : -1
    })
  } else if (direction === 'asc') {
    return [...list].sort((a, b) => {
      let x = a[sortBy]
      let y = b[sortBy]

      if (a[sortBy] === null) {
        x = ''
      }

      if (b[sortBy] === null) {
        y = ''
      }

      return x > y ? 1 : -1
    })
  } else if (direction === 'desc_ip') {
    return [...list].sort((a, b) => {
      const num1 = Number(a.split(".").map((num) => (`000${num}`).slice(-3) ).join(""))
      const num2 = Number(b.split(".").map((num) => (`000${num}`).slice(-3) ).join(""))
      return num1 - num2
    })
  } else if (direction === 'asc_ip') {
    return [...list].sort((a, b) => {
      const num1 = Number(a.split(".").map((num) => (`000${num}`).slice(-3) ).join(""))
      const num2 = Number(b.split(".").map((num) => (`000${num}`).slice(-3) ).join(""))
      return num1 - num2
    })
  }
}

export default sort