import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import {
  BorderedBlockBox,
  SubtitleBox,
  UnderlinedSpaceBetweenSmallBox,
  KeyNameBox,
  ValueNameBox,
  IconWarningBox,
} from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'

const DomainDetailsContact = ({
  contactType,
  domainType,
  isCompany,
  contactUid,
  name,
  nameEng,
  lastname,
  lastnameEng,
  org,
  orgEng,
  address,
  addressEng,
  personal,
  phone,
  email,
  handleOpenContactEdit,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.settings.currentLang)
  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const [containerStyles, setContainerStyles] = useState({})

  const geoText = lang === 'en' ? ' (Geo)' : ''
  const engText = lang === 'ka' ? ' (Eng)' : ''

  const valueStyles = { textAlign: 'right', width: '70%' }
  const geoValueStyles = { ...valueStyles, fontFamily: theme.typography.georgian.fontFamily, }
  const engValueStyles = { ...valueStyles, fontFamily: theme.typography.english.fontFamily, }

  let contactLabel = texts.registrant

  if (contactType === 'admin') {
    contactLabel = texts.administrator
  } else if (contactType === 'tech') {
    contactLabel = texts.technicalPerson
  }

  const handleClickContactEdit = () => {
    handleOpenContactEdit({
      domainType,
      isCompany,
      contactType,
      contactUid,
      nameProp: name,
      nameEngProp: nameEng,
      lastnameProp: lastname,
      lastnameEngProp: lastnameEng,
      orgProp: org,
      orgEngProp: orgEng,
      addressProp: address,
      addressEngProp: addressEng,
      personalProp: personal,
      phoneProp: phone,
      emailProp: email,
    })
  }

  useEffect(() => {
    let containerStylesInitial = { marginBottom: '20px' }
    
    if (contactType === 'admin' && width >= 1200) {
      containerStylesInitial = { ...containerStylesInitial, marginRight: '10px' }
    }

    if (contactType === 'tech' && width >= 1200) {
      containerStylesInitial = { ...containerStylesInitial, marginLeft: '10px' }
    }
    
    setContainerStyles({ ...containerStylesInitial })
  }, [contactType, width])

  return (
    <BorderedBlockBox sx={containerStyles}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
        <SubtitleBox>{contactLabel}</SubtitleBox>
        {
          isAdmin &&
          <IconWarningBox onClick={handleClickContactEdit}>
            <EditIcon />
          </IconWarningBox>
        }        
      </Box>

      {
        (!isCompany || contactType !== 'registrant') && (
          <>
            <UnderlinedSpaceBetweenSmallBox>
              <KeyNameBox>{texts.name + geoText}</KeyNameBox>
              <ValueNameBox sx={geoValueStyles}>{name}</ValueNameBox>
            </UnderlinedSpaceBetweenSmallBox>

            {
              domainType === 'eng' && (
                <UnderlinedSpaceBetweenSmallBox>
                  <KeyNameBox>{texts.name + engText}</KeyNameBox>
                  <ValueNameBox sx={engValueStyles}>{nameEng}</ValueNameBox>
                </UnderlinedSpaceBetweenSmallBox>
              )
            }            

            <UnderlinedSpaceBetweenSmallBox>
              <KeyNameBox>{texts.lastname + geoText}</KeyNameBox>
              <ValueNameBox sx={geoValueStyles}>{lastname}</ValueNameBox>
            </UnderlinedSpaceBetweenSmallBox>

            {
              domainType === 'eng' && (
                <UnderlinedSpaceBetweenSmallBox>
                  <KeyNameBox>{texts.lastname + engText}</KeyNameBox>
                  <ValueNameBox sx={engValueStyles}>{lastnameEng}</ValueNameBox>
                </UnderlinedSpaceBetweenSmallBox>
              )
            }
            
          </>
        )
      }

      {
        (isCompany || (contactType !== 'registrant' && domainType === 'eng')) && (
          <>
            <UnderlinedSpaceBetweenSmallBox>
              <KeyNameBox>{texts.organizationName + geoText}</KeyNameBox>
              <ValueNameBox sx={geoValueStyles}>{org}</ValueNameBox>
            </UnderlinedSpaceBetweenSmallBox>

            {
              domainType === 'eng' && (
                <UnderlinedSpaceBetweenSmallBox>
                  <KeyNameBox>{texts.organizationName + engText}</KeyNameBox>
                  <ValueNameBox sx={engValueStyles}>{orgEng}</ValueNameBox>
                </UnderlinedSpaceBetweenSmallBox>
              )
            }            
          </>
        )
      }

      {
        (contactType === 'registrant' || domainType === 'eng') && (
          <UnderlinedSpaceBetweenSmallBox>
            <KeyNameBox>{texts.address + geoText}</KeyNameBox>
            <ValueNameBox sx={geoValueStyles}>{address}</ValueNameBox>
          </UnderlinedSpaceBetweenSmallBox>
        )
      }      

      {
        domainType === 'eng' && (
          <UnderlinedSpaceBetweenSmallBox>
            <KeyNameBox>{texts.address + engText}</KeyNameBox>
            <ValueNameBox sx={engValueStyles}>{addressEng}</ValueNameBox>
          </UnderlinedSpaceBetweenSmallBox>
        )
      }      

      <UnderlinedSpaceBetweenSmallBox>
        <KeyNameBox>{texts.email}</KeyNameBox>
        <ValueNameBox sx={engValueStyles}>{email}</ValueNameBox>
      </UnderlinedSpaceBetweenSmallBox>

      <UnderlinedSpaceBetweenSmallBox>
        <KeyNameBox>{texts.phoneNumber}</KeyNameBox>
        <ValueNameBox sx={engValueStyles}>{phone}</ValueNameBox>
      </UnderlinedSpaceBetweenSmallBox>

      {
        (contactType === 'registrant' || domainType === 'eng') && (
          <UnderlinedSpaceBetweenSmallBox>
            <KeyNameBox>{ isCompany ? texts.organizationCode : texts.personalNumber }</KeyNameBox>
            <ValueNameBox sx={engValueStyles}>{personal}</ValueNameBox>
          </UnderlinedSpaceBetweenSmallBox>
        )
      }

    </BorderedBlockBox>
  )
}

export default DomainDetailsContact