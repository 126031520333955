import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { NoResultBox } from '../../themes/styles'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import useWindowDimensions from '../../helpers/windowDimensions'
import VpsSliderItem from './VpsSliderItem'
import VpsServerItem from './VpsServerItem'
import SlickSliderElement from '../common/SlickSliderElement'
import BackdropElement from '../common/BackdropElement'
import FormSelectField from '../common/formFields/FormSelectField'

const VpsMyContent = (props) => {
  const { virtualMachines, backdropOpen } = props
  const theme = useTheme()

  const { width, height } = useWindowDimensions()

  const drawerOpened = useSelector(state => state.settings.drawerOpened)
  const texts = useSelector(state => state.settings.langTexts)

  const [cardsData, setCardsData] = useState([])
  const [sliderData, setSliderData] = useState([])
  const [slidesToShow, setSlidesToShow] = useState(0)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedItemsList, setSelectedItemsList] = useState([])

  const sliderWrapperStyle = {
    width: drawerOpened ? `${width - 360}px` : `${width - 125}px`,
    borderBottom: `${theme.palette.chineseSilver.main} 1px solid`,
    marginBottom: '40px',
    padding: '0px 5px 10px 5px',
  }

  const sliderArrows = {
    leftIcon: <ArrowLeftIcon sx={{ fontSize: '96px' }} />,
    rightIcon: <ArrowRightIcon sx={{ fontSize: '96px' }} />,
    leftStyle: {
      color: theme.palette.weldonBlue.main,
      position: 'absolute',
      top: '20%',
      left: '-70px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        opacity: 0.8,
      }
    },    
    rightStyle: {
      color: theme.palette.weldonBlue.main,
      position: 'absolute',
      top: '20%',
      right: '-70px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        opacity: 0.8,
      }
    },
  }

  const getSliderItemColor = useCallback((thisIndex) => {
    const sliderItemColors = ['#9B51E0', '#27AE60', '#F2994A', '#2E9CD6']
    return sliderItemColors[thisIndex % 4]
  }, [])

  const handleChangeSelctedItems = (value) => {
    setSelectedItems(value)
  }

  const pushCardForward = (id) => {
    setCardsData(prev => {
      const card_to_push = prev.find(item => item.id === id)
      const filtered_cards = prev.filter(item => item.id !== id)
      return [card_to_push, ...filtered_cards]
    })
  }

  useEffect(() => {
    let selected_items_list = []
    let selected_items = []
    
    virtualMachines.forEach(item => {
      selected_items_list.push({ value: item.id, title: `${item.vm_id} ${item.name}` })
      selected_items.push(item.id)
    })

    setSelectedItemsList(selected_items_list)
    setSelectedItems(selected_items)
    setCardsData(virtualMachines)
  }, [virtualMachines])
  
  useEffect(() => {
    let slider_data = []
    let slides_to_show = 4

    if (virtualMachines.length > 0) {
      virtualMachines.forEach((item, index) => {
        slider_data.push(<VpsSliderItem
          key={index}
          color={getSliderItemColor(index)}
          isSlider={true}
          setSelectedItems={setSelectedItems}
          pushCardForward={pushCardForward}
          {...item}
        />)
      })

      if (width < 900) {
        slides_to_show = 1
      } else if (width < 1300) {
        slides_to_show = 2
      } else if (width < 1800) {
        slides_to_show = 3
      }
    }

    setSliderData(slider_data)
    setSlidesToShow(slides_to_show)
  }, [virtualMachines, width, getSliderItemColor])
  
  return (
    <>
      { (virtualMachines.length === 0 && !backdropOpen) && <NoResultBox sx={{ height: `${height/2}px` }}>{texts.noVpsNotification}</NoResultBox> }

      {
        virtualMachines.length > 0 &&
        <>
          <Box sx={sliderWrapperStyle}>
            {
              virtualMachines.length > slidesToShow ?
              <>
                {
                  width >= 900 ?
                  <SlickSliderElement
                    slides={sliderData}
                    arrows={sliderArrows}
                    slidesToShow={slidesToShow}
                    draggable={true}     
                    centerMode={false}
                    sx={{ margin: '0px 50px' }}
                  /> :
                  <Box sx={{ marginBottom: '20px' }}>
                    <FormSelectField
                      label={texts.virtualServers}
                      value={selectedItems}
                      multiple={true}
                      selectItems={selectedItemsList}
                      handleChange={handleChangeSelctedItems}
                    />
                  </Box>
                }
              </> :
              <>
                {
                  virtualMachines.map((item, index) => (
                    <VpsSliderItem
                      key={index}
                      color={getSliderItemColor(index)}
                      isSlider={false}
                      slidesToShow={slidesToShow}
                      setSelectedItems={setSelectedItems}
                      pushCardForward={pushCardForward}
                      {...item}
                    />
                  ))
                }
              </>
            }
          </Box>

          {
            cardsData.map((item, index) => (
              selectedItems.includes(item.id) && <VpsServerItem key={index} {...item} setSelectedItems={setSelectedItems} />
            ))
          }
        </>
      }

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default VpsMyContent