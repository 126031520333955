import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import useWindowDimensions from '../../helpers/windowDimensions'

const VpsServerIpAddressItem = ({ ip_address, interfaceName }) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const wrapperStyle = {
    padding: '10px 0px',
    borderBottom: `${theme.palette.chineseSilver.main} 1px solid`,
    color: theme.palette.blueSapphire.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.small.fontSize,
  }

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={wrapperStyle}
    >
      <Box sx={{ marginLeft: '10px' }}>{ip_address}</Box>
      <Box sx={{ marginRight: '10px' }}>{interfaceName}</Box>
    </Stack>
  )
}

export default VpsServerIpAddressItem