import { createSlice } from '@reduxjs/toolkit'
import kaTexts from '../../lang/kaTexts'

const initialState = {
  currentLang: 'ka',
  langTexts: kaTexts,
  drawerOpened: false,
  currentAccount: '',
  currentAccountName: '',
  currentAccountType: '',
  hasInternet: false,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentLang: (state, action) => {
      state.currentLang = action.payload.currentLang
    },
    setLangTexts: (state, action) => {
      state.langTexts = action.payload.langTexts
    },
    setDrawerOpened: (state, action) => {
      state.drawerOpened = action.payload.drawerOpened
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload.currentAccount
    },
    setCurrentAccountName: (state, action) => {
      state.currentAccountName = action.payload.currentAccountName
    },
    setCurrentAccountType: (state, action) => {
      state.currentAccountType = action.payload.currentAccountType
    },
    setHasInternet: (state, action) => {
      state.hasInternet = action.payload.hasInternet
    },
  }
})

export const {
  setCurrentLang,
  setLangTexts,
  setDrawerOpened,
  setCurrentAccount,
  setCurrentAccountName,
  setCurrentAccountType,
  setHasInternet,
} = settingsSlice.actions

export default settingsSlice.reducer