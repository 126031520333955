const settings = {
  domain: process.env.REACT_APP_API_GATEWAY,
  fetchDomains: process.env.REACT_APP_API_DOMAINS,
  s3URL: process.env.REACT_APP_S3_URL,
  s3Bucket: process.env.REACT_APP_S3_BUCKET,
  api: '',
  authURL: process.env.REACT_APP_KEYCLOAK_URL,
  authClientID: process.env.REACT_APP_CLIENT_ID,
  authClientSecret: process.env.REACT_APP_CLIENT_SECRET,
  authUserID: process.env.REACT_APP_USER_ID,
  authUserSecret: process.env.REACT_APP_USER_SECRET,
  googleReCaptchaSiteKey: process.env.REACT_APP_GOOGLERECAPTCHA_SITEKEY,
  googleReCaptchaSecretKey: process.env.REACT_APP_GOOGLERECAPTCHA_SECRETKEY,
  desktopBreakpoint: 1199,
  padBreakpoint: 899,
  domainsCount: 50,
  clientsUi: 'clients-ui/',
  app_version: '0.1.16',
}

export default settings