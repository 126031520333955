import React from 'react'
import { useSelector } from 'react-redux'
import { PageMenuItemBox, PageMenuItemActiveBox } from '../../themes/styles'

const VpsMenuItem = (props) => {
  const { title, url, pageName, setPageName } = props

  const texts = useSelector(state => state.settings.langTexts)

  const handleClick = () => {
    setPageName(url)
  }

  return (
    <>
      { 
        pageName === url ?
        <PageMenuItemActiveBox>{texts[title]}</PageMenuItemActiveBox> :
        <PageMenuItemBox onClick={handleClick} onMouseDown={handleClick}>{texts[title]}</PageMenuItemBox>
      }
    </>
  )
}

export default VpsMenuItem