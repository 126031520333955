import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { OutlinedFixedSecondaryButton, IconSecondaryBox } from '../../themes/styles'
import settings from '../../config/settings'
import { request, requestDomains } from '../../network/request'
import { apiDomains } from '../../network/urls'
import { getAccountInfo, getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import { convertLatinToGeorgian, convertGeorgianToLatin } from '../../helpers/string'
import useWindowDimensions from '../../helpers/windowDimensions'
import FormTextField from '../common/formFields/FormTextField'
import FormSelectField from '../common/formFields/FormSelectField'
import CircleIcon from '../common/icons/CircleIcon'
import DomainsFilterStatus from './DomainsFilterStatus'
import ModalWindow from '../common/ModalWindow'
import ModalNotification from '../common/ModalNotification'
import DomainRegistrationForm from './DomainRegistrationForm'
import DomainTransferForm from './DomainTransferForm'

const DomainsSubheader = ({ domains, filterKeyword, setFilterKeyword, filterStatus, setFilterStatus, setBackdropOpen, setUpdate }) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const accountInfo = getAccountInfo(currentAccount, userData)  
  const isCompany = (accountInfo.account_type && accountInfo.account_type === 'company') ? true : false

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const [newDomain, setNewDomain] = useState('')
  const [newDomainExt, setNewDomainExt] = useState('')
  const [extList, setExtList] = useState([])
  const [searchDomain, setSearchDomain] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('lg')

  const statusList = [
    {
      value: 'all',
      title: texts.allDomains,
      color: theme.palette.darkCerulean.main,
    },
    {
      value: 'active',
      title: texts.active,
      color: theme.palette.greenCrayola.main,
    },
    {
      value: 'pending',
      title: texts.waitingForPayment,
      color: theme.palette.vividRed.main,
    },
    {
      value: 'expires',
      title: texts.expires,
      color: theme.palette.royalOrange.main,
    },
    {
      value: 'expired',
      title: texts.expired,
      color: theme.palette.vividRed.main,
    },
  ]

  const sxNewDomainTextField = {
    border: '#c4c4c4 1px solid',
    borderRadius: '8px 0px 0px 8px',
  }

  const sxNewDomainExtField = {
    borderTop: '#b2b4bc 1px solid',
    borderRight: '#b2b4bc 1px solid',
    borderBottom: '#b2b4bc 1px solid',
    borderRadius: '0px 8px 8px 0px',
  }

  const sxNewDomainExtDropdown = {
    border: '#b2b4bc 1px solid',
    borderRadius: '8px',
  }

  const buttonStyle = width < 900 ? {
    fontSize: theme.typography.regular.fontSize,
  } : {}

  const handleChangeNewDomain = (value) => {
    setNewDomain(newDomainExt === '.გე' ? convertLatinToGeorgian(value) : convertGeorgianToLatin(value))
  }

  const handleChangeNewDomainExt = (value) => {
    if (newDomainExt === '.გე' || value === '.გე') {
      setNewDomain('')
    }

    setNewDomainExt(value)
  }

  const handleChangeSearchDomain = (value) => {
    setSearchDomain(value)
  }

  const handleSubmitSearch = () => {
    setFilterKeyword(searchDomain)
  }

  const handleResetSearch = () => {
    setSearchDomain('')
    setFilterKeyword('')
    setFilterStatus('all')
  }

  const searchAdornment = (
    <InputAdornment position='start'>
      <IconButton onClick={handleSubmitSearch} edge='end'>
        <SearchIcon />
      </IconButton>      
    </InputAdornment>
  )

  const handleOpenDomainRegistrationForm = ({ title, domainName, domainType, extList }) => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(title)
    setModalContent(<DomainRegistrationForm
      setModalOpen={setModalOpen}
      domainName={domainName}
      domainType={domainType}
      lastDomainUid={ domains.length > 0 ? domains[0].uid : '' }
      extList={extList}
      action='create'
      handleOpenNotification={handleOpenNotification}
      setUpdate={setUpdate}
    />)
  }

  const handleOpenDomainTransferForm = ({ title, domainName, extList }) => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(title)
    setModalContent(<DomainTransferForm
      setModalOpen={setModalOpen}
      setModalContent={setModalContent}
      domainName={domainName}
      extList={extList}
      handleOpenNotification={handleOpenNotification}
      setUpdate={setUpdate}
    />)
  }

  const handleOpenNotification = ({ title, content, size }) => {
    setModalOpen(true)
    setModalWidth( size ? size : 'sm')
    setModalTitle(title)
    setModalContent(<ModalNotification setModalOpen={setModalOpen} notificationText={content} />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleRegisterDomain = async () => {
    if (isCompany && newDomainExt === '.pvt.ge') {
      return
    }

    if (!isCompany && (newDomainExt === '.edu.ge' || newDomainExt === '.net.ge' || newDomainExt === '.org.ge' || newDomainExt === '.school.ge')) {
      return
    }

    if (newDomain && newDomainExt) {
      setBackdropOpen(true)

      const response = await requestDomains(settings.fetchDomains + '?action=whois&domain=' + newDomain + newDomainExt)
      
      if (response && response.data && response.data.status && response.data.status === 'Available') {
        handleOpenDomainRegistrationForm({
          title: newDomain + newDomainExt + texts._domainRegistration,
          domainName: newDomain + newDomainExt,
          domainType: newDomainExt === '.გე' ? 'geo' : 'eng',
          extList,
        })
      } else if (response && response.data && response.data.status && response.data.status === 'Ok') {
        handleOpenNotification({
          title: newDomain + newDomainExt + texts._domainRegistration,
          content: texts.domainIsUnavailable,
        })
      } else {
        handleOpenNotification({
          title: newDomain + newDomainExt + texts._domainRegistration,
          content: texts.invalidDomainName,
        })
      }

      setBackdropOpen(false)
    } else {
      handleOpenNotification({
        title: texts.domainRegistration,
        content: texts.invalidDomainName,
      })
    }
  }

  const handleTransferDomain = async () => {    
    const domain_object = domains.find(item => item.name === newDomain + newDomainExt)
    const allowTransfer = domain_object ? false : true

    if (newDomain && newDomainExt && allowTransfer) {
      setBackdropOpen(true)

      const response = await requestDomains(settings.fetchDomains + '?action=whois&domain=' + newDomain + newDomainExt)
      
      if (response && response.data && response.data.status && response.data.status === 'Available') {
        handleOpenDomainRegistrationForm({
          title: newDomain + newDomainExt + texts._domainRegistration,
          domainName: newDomain + newDomainExt,
          domainType: 'eng',
          extList,
        })
      } else if (response && response.data && response.data.status && response.data.status === 'Ok') {
        handleOpenDomainTransferForm({
          title: newDomain + newDomainExt + texts._domainTransfer,
          domainName: newDomain + newDomainExt,
          extList,
        })
      } else {
        handleOpenNotification({
          title: newDomain + newDomainExt + texts._domainRegistration,
          content: texts.invalidDomainName,
        })
      }

      setBackdropOpen(false)
    } else {
      handleOpenNotification({
        title: texts.domainRegistration,
        content: texts.invalidDomainName,
      })
    }
  }

  useEffect(() => {
    const fecthExtList = async () => {
      const responseDomains = await request(settings.domain + settings.api + apiDomains.getDomains, 'GET')
      const responseSpecialDomains = await request(settings.domain + settings.api + apiDomains.getSpecialDomains, 'GET')
      
      if (responseDomains && responseSpecialDomains && responseDomains.length > 0 && responseSpecialDomains.length > 0) {
        let extListArray = []

        responseDomains.forEach(item => {
          if (item.type === 'en') {
            extListArray.push({ value: item.domain_name, title: item.domain_name })
          }
        })

        responseSpecialDomains.forEach(item => {
          if (isCompany && item.spec_domain_name === '.pvt.ge') {
            return
          }

          if (!isCompany && (item.spec_domain_name === '.edu.ge' || item.spec_domain_name === '.net.ge' || item.spec_domain_name === '.org.ge' || item.spec_domain_name === '.school.ge')) {
            return
          }

          extListArray.push({ value: item.spec_domain_name, title: item.spec_domain_name, info_text: item.info_text })
        })

        responseDomains.forEach(item => {
          if (item.type === 'ka') {
            extListArray.push({ value: item.domain_name, title: item.domain_name })
          }
        })

        setExtList(extListArray)
      } else {
        setExtList([])
      }
    }

    fecthExtList()
  }, [isCompany])

  useEffect(() => {
    if (!newDomainExt && extList.length > 0) {
      setNewDomainExt(extList[0].value)
    }
  }, [extList, newDomainExt])

  useEffect(() => {
    setSearchDomain(filterKeyword)
  }, [filterKeyword])

  const getNewDomainName = () => {
    return (
      <>
        <Box>
          <FormTextField
            type='text'
            label=''
            placeholder={texts.registerNewDomain}
            value={newDomain}
            required={false}
            error={false}
            lines='1'
            size={ width >= 900 ? 'medium' : 'small' }
            handleChange={handleChangeNewDomain}
            fieldWidth={ width >= 900 ? '300px' : `${width/1.6 - 60}px` }
            customStyles={sxNewDomainTextField}
          />
        </Box>
        <Box>
          <FormSelectField
            label=''
            value={newDomainExt}
            required={false}
            error={false}
            size={ width >= 900 ? 'medium' : 'small' }
            selectItems={extList}
            handleChange={handleChangeNewDomainExt}
            fieldWidth={ width >= 900 ? '150px' : `${width/2.9 - 60}px` }
            customContainerStyles={sxNewDomainExtField}
            customDropdownStyles={sxNewDomainExtDropdown}
          />
        </Box>
      </>
    )
  }

  const getNewDomainActions = () => {
    return (
      <>
        <Box sx={{ marginLeft: width >= 900 ? '20px' : '0px', marginRight: width >= 900 ? '0px' : '10px' }}>
          <CircleIcon type='add' size={ width >= 900 ? '48' : '32' } bg='weldonBlue' action={handleRegisterDomain} />
        </Box>
        <Box
          sx={{
            color: theme.palette.chineseSilver.main,
            fontSize: width >= 900 ? '52px' : '36px',
            lineHeight: width >= 900 ? '52px' : '36px',
            marginLeft: width >= 900 ? '15px' : '0px',
          }}
        >/</Box>
        <Box sx={{ marginLeft: width >= 900 ? '20px' : '10px' }}>
          <OutlinedFixedSecondaryButton variant='outlined' disableRipple={true} sx={buttonStyle} onClick={handleTransferDomain}>{texts.transferDomain}</OutlinedFixedSecondaryButton>
        </Box>
      </>
    )
  }
  
  return (
    <>
      {
        isAdmin &&
        <>
          {
            width >= 900 ?
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '50px' }}>
              {getNewDomainName()}
              {getNewDomainActions()}
            </Box> :
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
                {getNewDomainName()}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '50px',
                  width: `${width/2.9 - 60 + width/1.6 - 60}px`,
                }}
              >
                {getNewDomainActions()}
              </Box>
            </>
          }
        </>        
      }      

      <Box
        sx={{
          display: 'flex',
          flexDirection: width >= 1200 ? 'row' : 'column',
          alignItems: width >= 1200 ? 'center' : 'flex-start',
          justifyContent: width >= 1200 ? 'space-between' : 'flex-start',
          marginBottom: '50px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Box sx={{ marginBottom: width >= 1200 ? '0px' : '20px' }}>
            <FormTextField
              type='text'
              label={texts.searchByDomainName}
              placeholder={texts.searchByDomainName}
              value={searchDomain}
              required={false}
              error={false}
              lines='1'
              size={ width >= 1200 ? 'medium' : 'small' }
              handleChange={handleChangeSearchDomain}
              endAdornment={ width >= 900 ? searchAdornment : undefined }
              fieldWidth={ width >= 1200 ? '450px' : `${width/2.9 - 60 + width/1.6 - 60}px` }
            />
          </Box>
          {
            searchDomain && (
              <Box sx={{ marginLeft: '10px' }}>
                <IconSecondaryBox onClick={handleResetSearch}>
                  <CloseIcon sx={{ fontSize: '36px' }} />
                </IconSecondaryBox>
              </Box>
            )
          }
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: width >= 900 ? 'row' : 'column',
            alignItems: width >= 900 ? 'center' : 'flex-start',
            justifyContent: width >= 900 ? 'flex-end' : 'flex-start',
            paddingTop: width >= 1200 ? '0px' : '20px',
          }}
        >
          {
            statusList.map((item, index) => (
              <DomainsFilterStatus
                key={index}
                {...item}
                domains={domains}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
              />
            ))
          }
        </Box>
      </Box>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth={modalWidth}
        onModalClose={handleCloseModal}
      />
    </>
  )
}

export default DomainsSubheader