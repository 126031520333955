import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'
import { requestDomains } from '../../network/request'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import DomainNameserversForm from './DomainNameserversForm'
import BackdropElement from '../common/BackdropElement'

const DomainNameserversAdd = ({ modalTitle, setModalOpen, setUpdate, handleOpenNotification, domainUid, domainName, domainType }) => {
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width < 1000 ? `${width*0.9}px` : '1000px'

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const nameServersInitial = { name: '', ip: '', showIp: false, }

  const [nameservers, setNameservers] = useState([{ ...nameServersInitial }])
  const [backdropOpen, setBackdropOpen] = useState(false)

  const openErrorModal = (response) => {
    const text = (response && response.data && response.data.message) ? `${texts.errorOccuredWithoutExclamationMark}: ${response.data.message}` : texts.errorOccured

    handleOpenNotification({
      title: modalTitle,
      text,
    })
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)

    const postData = {
      domainType,
      domainUid,
      nameservers: nameservers.filter(item => item.name !== ''),
    }

    const response = await requestDomains(settings.fetchDomains + `/?action=client-nameservers-add&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
    
    if (response && response.status && response.status === 100) {
      if (response.data && response.data.result && response.data.result === 1) {
        setModalOpen(false)
        setUpdate(prev => prev + 1)
      } else {
        openErrorModal(response)
      }
    } else {
      openErrorModal(null)
    }

    setBackdropOpen(false)
  }

  return (
    <Box sx={{ minWidth: formWidth }}>

      <DomainNameserversForm
        domainName={domainName}
        nameServersInitial={nameServersInitial}
        nameservers={nameservers}
        setNameservers={setNameservers}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.save}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default DomainNameserversAdd