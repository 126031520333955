import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store/configureStore'
import App from './App'
import './fonts/fonts.css'

const jsx = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(jsx)