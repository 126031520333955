import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import FormFileField from '../common/formFields/FormFileField'

const DomainFileForm = ({ proveDocument, setProveDocument, errors, setErrors }) => {
  const theme = useTheme()

  const texts = useSelector(state => state.settings.langTexts)

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.error.main,
    fontSize: '14px',
  }

  const handleChangeProveDocument = (value) => {
    setProveDocument(value)

    if (!value) {
      setErrors((prev) => {
        return { ...prev, proveDocument: texts.pleaseUploadFile }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, proveDocument: '' }
      })
    }
  }

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <FormFileField
        label={texts.uploadFileMax5Mb}
        placeholder=''
        value={proveDocument}
        required={true}
        error={errors.proveDocument ? true : false}
        helpertext={errors.proveDocument && <Box sx={errorHelperStyles} component='span'>{errors.proveDocument}</Box>}
        handleChange={handleChangeProveDocument}
      />
    </Box>
  )
}

export default DomainFileForm