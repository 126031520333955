import React from 'react'
import { useSelector } from 'react-redux'
import { PopperItemBox } from '../../themes/styles'

const DomainRules = ({ files }) => {
  const lang = useSelector(state => state.settings.currentLang)
  const texts = useSelector(state => state.settings.langTexts)

  const handleClick = (url) => {
    window.open(url, '_blank')
  }
  
  return (
    <>
      {
        (files.eng && files.eng[lang]) && (
          <PopperItemBox onClick={() => handleClick(files.eng[lang])} onMouseDown={() => handleClick(files.eng[lang])}>{texts.domainEngRulesTitle}</PopperItemBox>
        )      
      }

      {
        files.geo && (
          <PopperItemBox sx={{ borderBottom: 'none' }} onClick={() => handleClick(files.geo)} onMouseDown={() => handleClick(files.geo)}>{texts.domainGeoRulesTitle}</PopperItemBox>
        )      
      }
      
    </>
  )
}

export default DomainRules