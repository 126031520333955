import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { PageLabelBox, SuccessButton, ErrorButton, KeyNameBox, ValueNameBox } from '../../themes/styles'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { getAccountInfo, getSuperadminAccountInfo, getIsSuperadmin } from '../../helpers/users'
import AccountStatusToggle from './AccountStatusToggle'
import AccountUsers from './AccountUsers'
import AccountUserAddForm from './AccountUserAddForm'
import AccountUserDelete from './AccountUserDelete'
import ModalWindow from '../common/ModalWindow'

const AccountSettingsContent = () => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const lineStyles = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fontSize: theme.typography.large.fontSize,
    marginBottom: '20px',
  }

  const keyStyles = {
    minWidth: '300px',
    paddingRight: '10px',
    textAlign: 'right',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const userData = useSelector(state => state.userData)

  const isSuperadmin = getIsSuperadmin(userData)
  
  const [account, setAccount] = useState('')
  const [superadminAccounts, setSuperadminAccounts] = useState([])
  const [updateUsers, setUpdateUsers] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('lg')
  
  const handleOpenAccountStatusToggleModal = (activate) => {
    setModalOpen(true)
    setModalWidth('sm')
    setModalTitle(activate ? texts.activateAccount : texts.deactivateAccount)
    setModalContent(<AccountStatusToggle
      setModalOpen={setModalOpen}
      activate={activate}
      accountId={account.id}
      accountName={ account.account_type === 'company' ? account.company_name : account.name + ' ' + account.lastname }
    />)
  }

  const handleOpenAccountUserAddModal = () => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(texts.addNewUser)
    setModalContent(<AccountUserAddForm setModalOpen={setModalOpen} setUpdateUsers={setUpdateUsers} accountId={account.id} />)
  }

  const handleOpenAccountUserEditModal = (editRecord) => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(texts.user + ' ' + editRecord.email)
    setModalContent(<AccountUserAddForm setModalOpen={setModalOpen} setUpdateUsers={setUpdateUsers} accountId={account.id} editRecord={editRecord} />)
  }

  const handleOpenAccountUserDeleteModal = (deleteRecord) => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(texts.deleteUser)
    setModalContent(<AccountUserDelete setModalOpen={setModalOpen} setUpdateUsers={setUpdateUsers} accountId={account.id} deleteRecord={deleteRecord} />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchSuperadminAccounts = async () => {
      const requestUrl = settings.domain + settings.clientsUi + apiUsers.getAccounts

      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.length > 0) {
        setSuperadminAccounts(response)
      } else {
        setSuperadminAccounts([])
      }
    }

    if (isSuperadmin) {
      fetchSuperadminAccounts()
    }    
  }, [userData, isSuperadmin, dispatch, navigate])

  useEffect(() => {
    setAccount(isSuperadmin ? getSuperadminAccountInfo(currentAccount, superadminAccounts) : getAccountInfo(currentAccount, userData))
  }, [currentAccount, userData, superadminAccounts, isSuperadmin])
  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ marginBottom: '30px' }}>
            <PageLabelBox>{texts.accountSettings}</PageLabelBox>
          </Box>

          {
            account && (
              <>
                {
                  account.account_type === 'company' ? (
                    <>
                      <Box sx={lineStyles}>
                        <KeyNameBox sx={keyStyles}>{texts.companyName}:</KeyNameBox>
                        <ValueNameBox>{account.company_name}</ValueNameBox>
                      </Box>

                      <Box sx={lineStyles}>
                        <KeyNameBox sx={keyStyles}>{texts.companyId}:</KeyNameBox>
                        <ValueNameBox>{account.company_id}</ValueNameBox>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box sx={lineStyles}>
                        <KeyNameBox sx={keyStyles}>{texts.name}:</KeyNameBox>
                        <ValueNameBox>{account.name}</ValueNameBox>
                      </Box>

                      <Box sx={lineStyles}>
                        <KeyNameBox sx={keyStyles}>{texts.lastname}:</KeyNameBox>
                        <ValueNameBox>{account.lastname}</ValueNameBox>
                      </Box>

                      <Box sx={lineStyles}>
                        <KeyNameBox sx={keyStyles}>{texts.personalId}:</KeyNameBox>
                        <ValueNameBox>{account.personal_id}</ValueNameBox>
                      </Box>
                    </>
                  )
                }

                <Box sx={lineStyles}>
                  <KeyNameBox sx={keyStyles}>{texts.status}:</KeyNameBox>
                  <ValueNameBox sx={{ color: account.status === 'active' ? theme.palette.greenCrayola.main : theme.palette.vividRed.main }}>
                    { account.status === 'active' ? texts.active : texts.deactivated }
                  </ValueNameBox>
                </Box>
              </>
            )
          }
        </Box>      

        {
          account && (
            <>
              {
                account.status === 'active' ? (
                  <ErrorButton variant='contained' disableRipple={true} onClick={() => handleOpenAccountStatusToggleModal(false)}>{texts.deactivate}</ErrorButton>
                ) : (
                  <SuccessButton variant='contained' disableRipple={true} onClick={() => handleOpenAccountStatusToggleModal(true)}>{texts.activate}</SuccessButton>
                )
              }
            </>
          )
        }

        <ModalWindow
          modalOpen={modalOpen}
          modalTitle={modalTitle}
          modalContent={modalContent}
          modalWidth={modalWidth}
          onModalClose={handleCloseModal}
        />
      </Box>
      
      <AccountUsers
        account={account}
        updateUsers={updateUsers}
        handleOpenAccountUserAddModal={handleOpenAccountUserAddModal}
        handleOpenAccountUserEditModal={handleOpenAccountUserEditModal}
        handleOpenAccountUserDeleteModal={handleOpenAccountUserDeleteModal}
      />
    </>
  )
}

export default AccountSettingsContent