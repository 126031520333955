import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

const DrawerListItem = ({ title, icon, url, drawerOpened }) => {
  const theme = useTheme()
  
  const navigate = useNavigate()
  const location = useLocation()
  
  const texts = useSelector(state => state.settings.langTexts)
  
  const sxListItem = {
    display: 'block',
    background: location.pathname.includes(url) ? 'rgba(0, 0, 0, 0.04)' : 'none'
  }

  const sxButton = {
    minHeight: 48,
    justifyContent: drawerOpened ? 'initial' : 'center',
    px: 2.5,
  }

  const sxTitle = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.primary.main,
  }

  const sxIcon = {
    minWidth: 0,
    mr: drawerOpened ? 3 : 'auto',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  }

  const primaryTitle = <Typography sx={sxTitle}>{texts && texts[title]}</Typography>

  const handleNavigate = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(url, '_blank')
    } else if (e.type === 'click') {
      navigate(url)
    }
  }

  return (
    <ListItem disablePadding sx={sxListItem} onClick={handleNavigate} onMouseDown={handleNavigate}>
      <ListItemButton sx={sxButton}>
        <ListItemIcon sx={sxIcon}>{icon}</ListItemIcon>
        <ListItemText primary={primaryTitle} sx={{ opacity: drawerOpened ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}

export default DrawerListItem