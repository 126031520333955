import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import CircleIcon from '../common/icons/CircleIcon'
import useWindowDimensions from '../../helpers/windowDimensions'
import internetVisualImage from '../../themes/images/internet_visual.png'
import InternetGraph from './InternetGraph'

const InternetAccessPointItem = ({
  id,
  title,
  address,
  status,
  local_bandwidth,
  global_bandwidth,
  ip_ranges,
  graph_list,
  setSelectedItems,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)

  const statusColor = status === 'active' ? theme.palette.greenCrayola.main : theme.palette.vividRed.main
  const statusName = status === 'active' ? texts.on : texts.off

  const wrapperStyle = {
    border: '2px solid',
    borderColor: alpha(theme.palette.darkCerulean.main, 0.5),
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    padding: width >= 900 ? '20px 20px 20px 44px' : '10px 10px 10px 22px',
    margin: width >= 900 ? '24px 0px 48px 24px' : '12px 0px 24px 12px',
    position: 'relative',
  }

  const closeIconWrapperStyle = {
    position: 'absolute',
    top: '-24px',
    left: '-24px',
  }

  const labelStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    color: theme.palette.blueSapphire.main,
    marginBottom: width >= 900 ? '0px' : '20px',
  }

  const statusStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    height: width >= 900 ? '42px' : '30px',
    padding: width >= 900 ? '0px 30px' : '0px 15px',
    background: alpha(statusColor, 0.2),
    borderRadius: '10px',
    color: statusColor,
    textTransform: 'capitalize',
  }

  const visualWrapperStyle = {
    width: '186px',
    height: '87px',
    background: `url(${internetVisualImage}) no-repeat`,
    position: 'relative',
    margin: '0px 0px 20px 115px',
  }

  const globalValueStyle = {
    position: 'absolute',
    width: '176px',
    top: '7px',
    left: '0px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const localValueStyle = {
    position: 'absolute',
    width: '176px',
    top: '57px',
    left: '0px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const localLabelStyle = {
    position: 'absolute',
    height: '29px',
    top: '0px',
    left: '186px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.weldonBlue.main,
    borderBottom: `${theme.palette.weldonBlue.main} 1px solid`,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  }

  const globalLabelStyle = {
    position: 'absolute',
    height: '29px',
    top: '-12px',
    right: '185px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.royalOrange.main,
    borderBottom: `${theme.palette.royalOrange.main} 1px solid`,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  }

  const locationHeadStyle = {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.weldonBlue.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    color: 'white',
    borderRadius: '8px 8px 0px 0px',
  }

  const locationBodyStyle = {
    height: '180px',
    background: theme.palette.platinum.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.regular.fontSize,
    color: theme.palette.darkCerulean.main,
    borderRadius: '0px 0px 8px 8px',
    padding: '20px',
    overflowY: 'auto',
  }

  const ipRangeItemStyle = {
    padding: '10px 0px',
    borderTop: `${theme.palette.chineseSilver.main} 1px solid`,
    color: theme.palette.blueSapphire.main,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.large.fontSize : theme.typography.small.fontSize,
  }

  const handleClickClose = () => {
    setSelectedItems(prev => prev.filter(item => item !== id))
  }

  return (
    <Box sx={wrapperStyle}>
      <Box sx={closeIconWrapperStyle}>
        <CircleIcon type='close' size={ width >= 900 ? '48' : '32' } bg='weldonBlue' action={handleClickClose} />
      </Box>

      <Stack
        direction='row'
        flexDirection={ width >= 900 ? 'row' : 'column' }
        alignItems={ width >= 900 ? 'center' : 'flex-start' }
        justifyContent={ width >= 900 ? 'space-between' : 'flex-start' }
        sx={{ marginBottom: width >= 900 ? '40px' : '20px' }}
      >
        <Box sx={labelStyle}>{title}</Box>
        <Box>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={statusStyle}
          >
            <Box>{statusName}</Box>
          </Stack>
        </Box>
      </Stack>

      {
        width >= 900 ?
        <Box sx={visualWrapperStyle}>
          <Box sx={globalValueStyle}>{global_bandwidth} {texts.mbps}</Box>
          <Box sx={localValueStyle}>{local_bandwidth} {texts.mbps}</Box>
          <Box sx={localLabelStyle}>{texts.local}</Box>
          <Box sx={globalLabelStyle}>{texts.global}</Box>
        </Box> :
        <Box sx={{ marginBottom: '20px' }}>
          <Box>{texts.local} {local_bandwidth} {texts.mbps}</Box>
          <Box>{texts.global} {global_bandwidth} {texts.mbps}</Box>
        </Box>
      }      

      <Grid container spacing={{ xs: 4, lg: 6, xl: 8 }}>
        <Grid xs={12} lg={5}>
          <Box sx={locationHeadStyle}>{texts.location}</Box>
          <Box sx={locationBodyStyle}>
            <Box sx={{ marginBottom: '20px' }}>{address}</Box>
            {
              ip_ranges.map((item, index) => (
                <Box key={index} sx={ipRangeItemStyle}>{item.first_address} - {item.last_address}/{item.prefix_length}</Box>
              ))
            }
          </Box>
        </Grid>
        <Grid xs={12} lg={7}>
          {
            graph_list.map((item, index) => (
              <InternetGraph key={index} graph_id={item} />
            ))
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default InternetAccessPointItem