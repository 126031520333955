import React from 'react'
import { useSelector } from 'react-redux'
import { HomeWrapperBox, HomeCardBox } from '../../themes/styles'
import settings from '../../config/settings'
import { apiServices } from '../../network/urls'
import HomeCard from './HomeCard'

const HomeContent = (props) => {
  const { hasInternet } = props
  
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const virtualMachinesRequestUrl = settings.domain + settings.clientsUi + apiServices.getVirtualMachines + '/' + currentAccount
  const hostingDomainsRequestUrl = settings.domain + settings.clientsUi + apiServices.getHostingDomains + '/' + currentAccount
  const netLocationsRequestUrl = settings.domain + settings.clientsUi + apiServices.getNetLocations + '/' + currentAccount
  const domainsRequestUrl = settings.fetchDomains + `/?action=client-domains&account_id=${currentAccount}`
  
  return (
    <>
      <HomeWrapperBox>
        <HomeCard service='vps' requestUrl={virtualMachinesRequestUrl} />
        <HomeCard service='hosting' requestUrl={hostingDomainsRequestUrl} />
      </HomeWrapperBox>
      <HomeWrapperBox>
        <HomeCard service='domains' requestUrl={domainsRequestUrl} />
        {
          hasInternet ?
          <HomeCard service='internet' requestUrl={netLocationsRequestUrl} /> :
          <HomeCardBox sx={{ border: 'none' }}></HomeCardBox>
        }
      </HomeWrapperBox>
    </>
  )
}

export default HomeContent