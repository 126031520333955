import React from 'react'
import DrawerElement from '../common/DrawerElement'
import HomeContent from './HomeContent'

const HomePage = () => {
  return (
    <DrawerElement content={(props) => <HomeContent {...props} />} />
  )
}

export default HomePage