import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import { SecondaryButton } from '../../themes/styles'
import settings from '../../config/settings'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestDomains } from '../../network/request'
import FormTextField from '../common/formFields/FormTextField'
import FormSelectField from '../common/formFields/FormSelectField'
import BackdropElement from '../common/BackdropElement'

const DomainsLinkOldAccount = ({ setModalOpen }) => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width > settings.desktopBreakpoint ? '600px' : ''

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: theme.palette.vividRed.main,
  }

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.vividRed.main,
  }

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [domainType, setDomainType] = useState('eng')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [backdropOpen, setBackdropOpen] = useState(false)

  const domainTypeList = [
    { value: 'eng', title: 'domain.grena.ge' },
    { value: 'geo', title: 'დომენი.გრენა.გე' },
  ]

  const handleChangeDomainType = (value) => {
    setDomainType(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, domainType: '' }
      })
    }    
  }

  const handleChangeUsername = (value) => {
    setUsername(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, username: '' }
      })
    }
  }

  const handleChangePassword = (value) => {
    setPassword(value)

    if (value) {
      setErrors((prev) => {
        return { ...prev, password: '' }
      })
    }
  }

  const sendRequest = async (postData) => {
    const response = await requestDomains(settings.fetchDomains + '/?action=client-link-account', 'POST', postData, userData, dispatch, navigate)
    
    if (response && response.status && response.status === 100) {
      setModalOpen(false)
      navigate('/domains', { state: { updateDomains: true } })
    } else if (response && response.status && response.status === 411) {
      setErrors((prev) => {
        return { ...prev, general: domainType === 'eng' ? texts.usernameAndPasswordError : texts.loginError }
      })
    } else if (response && response.status && response.status === 412) {
      setErrors((prev) => {
        return { ...prev, general: texts.accountAlreadyLinked }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, general: texts.errorOccured }
      })
    }

    setBackdropOpen(false)
  }

  const handleSubmit = () => {
    setBackdropOpen(true)
    let errorsObject = {}

    if (!domainType) {
      errorsObject.domainType = texts.pleaseFillOut
    }

    if (!username) {
      errorsObject.username = texts.pleaseFillOut
    }

    if (!password) {
      errorsObject.password = texts.pleaseFillOut
    }

    if (Object.keys(errorsObject).length === 0) {
      const postData = {
        accountId: currentAccount,
        domainType,
        username,
        password,
      }

      sendRequest(postData)
    } else {
      setBackdropOpen(false)
    }

    setErrors(errorsObject)
  }

  return (
    <Box sx={{ minWidth: formWidth }}>

      {
        errors.general && (
          <Box sx={{ marginBottom: '20px' }}>
            <Alert severity='error' sx={alertStyles}>{errors.general}</Alert>
          </Box>
        )
      }

      <Box sx={{ marginBottom: '20px' }}>
        <FormSelectField
          label={texts.website}
          value={domainType}
          required={true}
          error={errors.domainType ? true : false}
          helpertext={errors.domainType && <Box sx={errorHelperStyles} component='span'>{errors.domainType}</Box>}
          selectItems={domainTypeList}
          handleChange={handleChangeDomainType}
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          type='text'
          label={ domainType === 'geo' ? texts.email : texts.username }
          value={username}
          required={true}
          error={errors.username ? true : false}
          helpertext={errors.username && <Box sx={errorHelperStyles} component='span'>{errors.username}</Box>}
          lines='1'
          handleChange={handleChangeUsername}
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          type='password'
          label={texts.password}
          value={password}
          required={true}
          error={errors.password ? true : false}
          helpertext={errors.password && <Box sx={errorHelperStyles} component='span'>{errors.password}</Box>}
          lines='1'
          handleChange={handleChangePassword}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SecondaryButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.confirm}</SecondaryButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default DomainsLinkOldAccount