import React from 'react'
import { useSelector } from 'react-redux'
import { DomainTransferClickableBox, DomainTransferIconBox, DomainTransferTitleBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import parse from 'html-react-parser'

const DomainDetailsTransfer = ({ domainName, domainUid, eppCode, handleOpenEppCodeRequest, handleOpenNotification }) => {
  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)
  
  const handleClickTransfer = () => {
    if (eppCode) {
      handleOpenNotification({
        title: domainName + ' ' + texts.transferDomain,
        text: parse(`${texts.domainEppCodeIs.replace('###DOMAIN###', domainName)} <b>${eppCode}</b>`),
      })
    } else {
      handleOpenEppCodeRequest({
        domainName,
        domainUid,
      })
    }
  }
  
  return (
    <DomainTransferClickableBox
      sx={{
        minHeight: '310px',
        margin: width >= 1200 ? '0px 0px 0px 10px' : '0px 0px 20px 0px',
      }}
      onClick={handleClickTransfer}
    >
      <DomainTransferIconBox></DomainTransferIconBox>
      <DomainTransferTitleBox>{texts.transfer}</DomainTransferTitleBox>
    </DomainTransferClickableBox>
  )
}

export default DomainDetailsTransfer