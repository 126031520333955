import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'
import FormTextField from '../common/formFields/FormTextField'
import FormSelectField from '../common/formFields/FormSelectField'

const DomainDetailsDnsAdd = ({
  domainUid,
  domainName,
  domainType,
  editRecord,
  dnsManageRequest,
  handleToggleAddForm,
}) => {
  const texts = useSelector(state => state.settings.langTexts)

  const typeList = [
    {
      value: 'A',
      title: 'A',
    },
    {
      value: 'AAAA',
      title: 'AAAA',
    },
    {
      value: 'CNAME',
      title: 'CNAME',
    },
    {
      value: 'MX',
      title: 'MX',
    },
    {
      value: 'NS',
      title: 'NS',
    },
    {
      value: 'SRV',
      title: 'SRV',
    },
    {
      value: 'TXT',
      title: 'TXT',
    },
  ]

  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [ttl, setTtl] = useState('')
  const [address, setAddress] = useState('')
  const [cname, setCname] = useState('')
  const [priority, setPriority] = useState('')
  const [destination, setDestination] = useState('')
  const [hostname, setHostname] = useState('')
  const [weight, setWeight] = useState('')
  const [port, setPort] = useState('')
  const [textInfo, setTextInfo] = useState('')
  const [soaHostname1, setSoaHostname1] = useState('')
  const [soaHostname2, setSoaHostname2] = useState('')
  const [soaSerial, setSoaSerial] = useState('')
  const [soaRefresh, setSoaRefresh] = useState('')
  const [soaRetry, setSoaRetry] = useState('')
  const [soaExpire, setSoaExpire] = useState('')
  const [soaMinimumTtl, setSoaMinimumTtl] = useState('')

  const [errorType, setErrorType] = useState(false)

  const handleChangeType = (value) => {
    setType(value)
  }

  const handleChangeName = (value) => {
    setName(value)
  }

  const handleChangeTtl = (value) => {
    setTtl(value)
  }

  const handleChangeAddress = (value) => {
    setAddress(value)
  }

  const handleChangeCname = (value) => {
    setCname(value)
  }

  const handleChangePriority = (value) => {
    setPriority(value)
  }

  const handleChangeDestination = (value) => {
    setDestination(value)
  }

  const handleChangeHostname = (value) => {
    setHostname(value)
  }

  const handleChangeWeight = (value) => {
    setWeight(value)
  }

  const handleChangePort = (value) => {
    setPort(value)
  }

  const handleChangeTextInfo = (value) => {
    setTextInfo(value)
  }

  const handleChangeSoaHostname1 = (value) => {
    setSoaHostname1(value)
  }

  const handleChangeSoaHostname2 = (value) => {
    setSoaHostname2(value)
  }

  const handleChangeSoaSerial = (value) => {
    setSoaSerial(value)
  }

  const handleChangeSoaRefresh = (value) => {
    setSoaRefresh(value)
  }

  const handleChangeSoaRetry = (value) => {
    setSoaRetry(value)
  }

  const handleChangeSoaExpire = (value) => {
    setSoaExpire(value)
  }

  const handleChangeSoaMinimumTtl = (value) => {
    setSoaMinimumTtl(value)
  }

  const getContent = () => {
    if (type === 'A' || type === 'AAAA') {
      return address
    } else if (type === 'CNAME') {
      return cname
    } else if (type === 'MX') {
      return destination
    } else if (type === 'NS') {
      return hostname
    } else if (type === 'SRV') {
      return weight + ' ' + port + ' ' + hostname
    } else if (type === 'TXT') {
      return textInfo
    }
  }

  const handleSubmit = async () => {
    if (type) {
      setErrorType(false)

      let postData = {
        domainType,
        domainUid,
        action: editRecord ? 'edit' : 'add',
        recordId: editRecord ? editRecord.id : 0,
        name,
        type,
        content: getContent(),
      }

      if (editRecord) {
        postData.ttl = ttl
      }

      if (type === 'MX' || type === 'SRV') {
        postData.prio = priority
      }

      const response = await dnsManageRequest(postData)

      if (response) {
        handleToggleAddForm(false)
      }
    } else {
      setErrorType(true)
    }
  }

  useEffect(() => {
    if (editRecord && editRecord.type) {
      setType(editRecord.type)

      if (editRecord.name) {
        setName(editRecord.name)
      }

      if (editRecord.ttl) {
        setTtl(editRecord.ttl)
      }

      if (editRecord.prio) {
        setPriority(editRecord.prio)
      }

      if (editRecord.type === 'A' || editRecord.type === 'AAAA') {
        setAddress(editRecord.content)
      }

      if (editRecord.type === 'CNAME') {
        setCname(editRecord.content)
      }

      if (editRecord.type === 'MX') {
        setDestination(editRecord.content)
      }

      if (editRecord.type === 'NS') {
        setHostname(editRecord.content)
      }

      if (editRecord.type === 'SRV') {
        const contentArray = editRecord.content.split(' ')
        setWeight(contentArray.length > 0 ? contentArray[0] : '')
        setPort(contentArray.length > 1 ? contentArray[1] : '')
        setHostname(contentArray.length > 2 ? contentArray[2] : '')
      }

      if (editRecord.type === 'TXT') {
        setTextInfo(editRecord.content)
      }

      if (editRecord.type === 'SOA') {
        const contentArray = editRecord.content.split(' ')
        setSoaHostname1(contentArray.length > 0 ? contentArray[0] : '')
        setSoaHostname2(contentArray.length > 1 ? contentArray[1] : '')
        setSoaSerial(contentArray.length > 2 ? contentArray[2] : '')
        setSoaRefresh(contentArray.length > 3 ? contentArray[3] : '')
        setSoaRetry(contentArray.length > 4 ? contentArray[4] : '')
        setSoaExpire(contentArray.length > 5 ? contentArray[5] : '')
        setSoaMinimumTtl(contentArray.length > 6 ? contentArray[6] : '')
      }
    } else {
      setType('')
      setName('')
      setTtl('')
      setPriority('')
      setAddress('')
      setCname('')
      setDestination('')
      setHostname('')
      setWeight('')
      setPort('')
      setTextInfo('')
      setSoaHostname1('')
      setSoaHostname2('')
      setSoaSerial('')
      setSoaRefresh('')
      setSoaRetry('')
      setSoaExpire('')
      setSoaMinimumTtl('')
    }
  }, [editRecord])

  return (
    <>
      {
        editRecord ? (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label={texts.recordType}
              placeholder=''
              value={type}
              required={false}
              error={false}
              disabled={true}
              lines='1'
              handleChange={handleChangeType}
            />
          </Box>
        ) : (
          <Box sx={{ marginBottom: '20px' }}>
            <FormSelectField
              label={texts.recordType}
              value={type}
              required={true}
              disabled={editRecord ? true : false}
              error={errorType}
              selectItems={typeList}
              handleChange={handleChangeType}
            />
          </Box>
        )
      }

      {
        type && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='Name'
              placeholder={'example.' + domainName + '.'}
              value={name}
              required={false}
              error={false}
              lines='1'
              handleChange={handleChangeName}
            />
          </Box>
        )
      }

      {
        editRecord && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='TTL'
              placeholder='TTL'
              value={ttl}
              required={false}
              error={false}
              lines='1'
              handleChange={handleChangeTtl}
            />
          </Box>
        )
      }

      {
        (type === 'A' || type === 'AAAA') && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='Address'
              placeholder={ type === 'A' ? '203.0.113.11' : 'IPv6 Address' }
              value={address}
              required={false}
              error={false}
              lines='1'
              handleChange={handleChangeAddress}
            />
          </Box>
        )
      }

      {
        type === 'CNAME' && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='CNAME'
              placeholder='example.com'
              value={cname}
              required={false}
              error={false}
              lines='1'
              handleChange={handleChangeCname}
            />
          </Box>
        )
      }

      {
        (type === 'MX' || type === 'SRV') && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='Priority'
              placeholder='Integer'
              value={priority}
              required={false}
              error={false}
              lines='1'
              handleChange={handleChangePriority}
            />
          </Box>
        )
      }

      {
        type === 'MX' && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='Destination'
              placeholder='Fully qualified domain name'
              value={destination}
              required={false}
              error={false}
              lines='1'
              handleChange={handleChangeDestination}
            />
          </Box>
        )
      }

      {
        type === 'SRV' && (
          <>
            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label='Weight'
                placeholder='Weight'
                value={weight}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangeWeight}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label='Port'
                placeholder='Port'
                value={port}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangePort}
              />
            </Box>
          </>
        )
      }

      {
        (type === 'NS' || type === 'SRV') && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='Hostname'
              placeholder='Hostname'
              value={hostname}
              required={false}
              error={false}
              lines='1'
              handleChange={handleChangeHostname}
            />
          </Box>
        )
      }

      {
        type === 'TXT' && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              type='text'
              label='Text Information'
              placeholder='Text Information'
              value={textInfo}
              required={false}
              error={false}
              lines='3'
              handleChange={handleChangeTextInfo}
            />
          </Box>
        )
      }

      {
        type === 'SOA' && (
          <>
            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label=''
                placeholder=''
                value={soaHostname1}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangeSoaHostname1}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label=''
                placeholder=''
                value={soaHostname2}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangeSoaHostname2}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label='Serial Number'
                placeholder='Serial Number'
                value={soaSerial}
                required={false}
                disabled={true}
                error={false}
                lines='1'
                handleChange={handleChangeSoaSerial}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label='Refresh'
                placeholder='Refresh'
                value={soaRefresh}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangeSoaRefresh}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label='Retry'
                placeholder='Retry'
                value={soaRetry}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangeSoaRetry}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label='Expire'
                placeholder='Expire'
                value={soaExpire}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangeSoaExpire}
              />
            </Box>

            <Box sx={{ marginBottom: '20px' }}>
              <FormTextField
                type='text'
                label='Minimum TTL'
                placeholder='Minimum TTL'
                value={soaMinimumTtl}
                required={false}
                error={false}
                lines='1'
                handleChange={handleChangeSoaMinimumTtl}
              />
            </Box>
          </>
        )
      }

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.save}</SuccessButton>
      </Box>
    </>
  )
}

export default DomainDetailsDnsAdd