import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'

const CellElement = ({ type, keyValue, record, content, valueType, customHeadStyle, customBodyStyle, action }) => {
  const theme = useTheme()
  
  const headCellStyleInitial = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.secondary.main,
  }

  const bodyCellStyleInitial = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.primary.main,
    cursor: action ? 'pointer' : 'auto',
  }

  const headCellStyle = customHeadStyle ? { ...headCellStyleInitial, ...customHeadStyle, } : headCellStyleInitial
  const bodyCellStyle = customBodyStyle ? { ...bodyCellStyleInitial, ...customBodyStyle, } : bodyCellStyleInitial

  const cellStyle = type === 'head' ? headCellStyle : bodyCellStyle

  const handleClick = () => {
    if (action && record) {
      action(record)
    }
  }
  
  return (
    <TableCell sx={cellStyle} onClick={handleClick}>
      { (type && type === 'head' && content) && content }

      { (!type && record && !valueType) && record[keyValue] }

      {
        (!type && record && valueType === 'list' && record[keyValue] && record[keyValue].length > 0) && (
          record[keyValue].map((item, index) => (
            <Box key={index}>{item}</Box>
          ))
        )
      }
    </TableCell>
  )
}

export default CellElement