import React from 'react'
import { useTheme, alpha } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'

const FormSelectField = ({
  label,
  value,
  required,
  disabled,
  error,
  helpertext,
  props,
  selectItems,
  hasEmptyOption,
  fieldWidth,
  customContainerStyles,
  customDropdownStyles,
  size,
  multiple,
  showScroll,
  handleChange,
}) => {
  const theme = useTheme()  

  const sxContainer = {
    ...customContainerStyles,
    fontFamily: theme.typography.regular.fontFamily,

    '& fieldset': {
      border: 'none'
    },

    '&:hover fieldset': {
      border: 'none',
    },

    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: 'none',
    },
  }

  const sxMenuProps = {
    disableScrollLock: showScroll ? true : false,
    PaperProps: {
      sx: customDropdownStyles ? customDropdownStyles : {},
    },
  }

  const selectStylesInitial = {
    fontFamily: theme.typography.regular.fontFamily,
    borderRadius: '8px',
    background: disabled ? alpha(theme.palette.chineseSilver.main, 0.2) : 'none',
  }

  const selectStyles = customContainerStyles ? sxContainer : selectStylesInitial

  return (
    <FormControl sx={{ width: fieldWidth ? fieldWidth : '100%' }} size={ size ? size : 'medium' }>
      <InputLabel sx={{ fontFamily: theme.typography.regular.fontFamily }}>{ required ? `${label} *` : label}</InputLabel>
      <Select
        label={ required ? `${label} *` : label}
        error={error}
        disabled={disabled ? true : false}
        value={value}
        onChange={e => handleChange(e.target.value, props)}
        MenuProps={sxMenuProps}
        sx={selectStyles}
        multiple={multiple ? true : false}
      >
        { hasEmptyOption && <MenuItem value=''>&nbsp;</MenuItem> }
        {
          selectItems.map((item, index) => (
            <MenuItem key={index} value={item.value} sx={{ fontFamily: theme.typography.regular.fontFamily }}>{item.title}</MenuItem>
          ))
        }
      </Select>
      { helpertext && <FormHelperText>{helpertext}</FormHelperText> }
    </FormControl>
  )
}

export default FormSelectField