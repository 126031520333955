import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'
import { requestDomains } from '../../network/request'
import settings from '../../config/settings'
import parse from 'html-react-parser'
import BackdropElement from '../common/BackdropElement'

const DomainEppCodeRequest = ({ domainName, domainUid, handleOpenNotification }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const lang = useSelector(state => state.settings.currentLang)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [backdropOpen, setBackdropOpen] = useState(false)

  const openSuccessModal = () => {
    handleOpenNotification({
      title: domainName + ' ' + texts.transferDomain,
      text: parse(texts.domainEppCodeRequestSuccess),
    })
  }

  const openErrorModal = (response) => {
    const text = (response && response.data && response.data.message) ? `${texts.errorOccuredWithoutExclamationMark}: ${response.data.message}` : texts.errorOccured

    handleOpenNotification({
      title: domainName + ' ' + texts.transferDomain,
      text,
    })
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)

    const eppCodeGenerateUrl = new URL(window.location.href).origin + '/domain-epp-code-generate/###UID###'

    const postData = {
      domainUid,
      lang,
      eppCodeGenerateUrl,
    }

    const response = await requestDomains(settings.fetchDomains + `/?action=client-epp-code-request&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
    
    if (response && response.status && response.status === 100) {
      openSuccessModal()
    } else {
      openErrorModal(null)
    }

    setBackdropOpen(false)
  }

  return (
    <>
      <Box>{texts.domainEppCodeGenerateWarning.replace('###DOMAIN###', domainName)}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '30px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.confirm}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainEppCodeRequest