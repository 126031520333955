import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import headerBackground from './images/header_background.png'
import invoiceBackground from './images/invoice_background.png'
import payBackground from './images/pay_background.png'
import transferIcon from './images/transfer_icon.png'
import homeVisualVps from './images/home_visual_vps.png'
import homeVisualHosting from './images/home_visual_hosting.png'
import homeVisualDomainsEng from './images/home_visual_domains_eng.png'
import homeVisualDomainsGeo from './images/home_visual_domains_geo.png'
import homeVisualInternet from './images/home_visual_internet.png'

export const HeaderBox = styled(Box)({
  height: '64px',
  padding: '0px 24px',
  background: `#001834 url(${headerBackground}) no-repeat right center`,
  color: 'white',
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
})

export const LogoBox = styled(Box)({
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
})

export const LanguageSwitchBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.english.fontFamily,
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.secondary.main,
  }
}))

export const LoginWrapperBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px 0px',
})

export const PrimaryButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.large.fontSize,
  textTransform: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  padding: '8px 20px',
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: '8px',

  '&:hover': {
    boxShadow: 'none',
    background: theme.palette.primary.main,
    opacity: 0.8,
  }
}))

export const SecondaryButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.large.fontSize,
  textTransform: 'none',
  background: theme.palette.secondary.main,
  color: 'white',
  padding: '8px 20px',
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: '8px',

  '&:hover': {
    boxShadow: 'none',
    background: theme.palette.secondary.main,
    opacity: 0.8,
  }
}))

export const IconWhiteBox = styled(Box)(({ theme }) => ({
  color: 'white',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.secondary.main,
  }
}))

export const IconPrimaryBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.secondary.main,
  }
}))

export const PageLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.xlarge.fontSize,
  color: theme.palette.primary.main,
}))

export const PageMenuItemBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.large.fontSize,
  color: theme.palette.secondary.main,
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },

  '@media (max-width: 899px)': {
    fontSize: theme.typography.small.fontSize,
  },
}))

export const PageMenuItemActiveBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.large.fontSize,
  color: theme.palette.primary.main,
  textDecoration: 'underline',

  '@media (max-width: 899px)': {
    marginLeft: '0px',
    fontSize: theme.typography.small.fontSize,
  },
}))

export const PopperBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.georgian.fontFamily,
  border: `${theme.palette.secondary.main} 1px solid`,
  background: 'white',
  borderRadius: '8px',
  marginTop: '10px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
}))

export const PopperItemBox = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid',
  borderColor: alpha(theme.palette.secondary.main, 0.5),
  color: theme.palette.primary.main,
  padding: '10px 20px',
  cursor: 'pointer',

  '&:hover': {
    color: alpha(theme.palette.secondary.main, 0.8),
  }
}))

export const OutlinedFixedSecondaryButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.large.fontSize,
  color: theme.palette.secondary.main,
  border: `${theme.palette.secondary.main} 1px solid`,
  height: '56px',
  padding: '0px 20px',
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: '8px',

  '&:hover': {
    boxShadow: 'none',
    background: theme.palette.secondary.main,
    border: `${theme.palette.secondary.main} 1px solid`,
    color: 'white',
  }
}))

export const DomainFilterStatusContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontSize: theme.typography.small.fontSize,
  marginLeft: '20px',

  '&:hover': {
    paddingBottom: '3px',
    borderBottom: '1px solid',
  },

  '@media (max-width: 899px)': {
    marginBottom: '10px'
  },
}))

export const DomainFilterStatusNumberBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '16px',
  marginLeft: '5px',
  fontSize: theme.typography.small.fontSize,
  color: 'white',
}))

export const IconSecondaryBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',

  '&:hover': {
    color: alpha(theme.palette.secondary.main, 0.7),
  }
}))

export const NoResultBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.xlarge.fontSize,
  color: theme.palette.primary.main,
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '8px',
  borderStyle: 'dotted',
  margin: '30px 0px',
  padding: '30px 0px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const CardWrapperBox = styled(Box)({
  position: 'fixed',
  top: '65px',
  transition: 'all 0.7s',
  zIndex: '1190',
})

export const CardContentBox = styled(Box)(({ theme }) => ({
  background: 'white',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.3)',
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '8px 0px 0px 8px',
  borderRight: 'none',
  padding: '20px',
}))

export const CardCloseBox = styled(Box)(({ theme }) => ({
  width: '60px',
  height: '60px',
  background: 'white',
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '30px 0px 0px 30px',
  borderRight: 'none',
  position: 'absolute',
  left: '1px',
  top: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const InnerScrollBox = styled(Box)({
  overflowY: 'auto',
  padding: '0px 8px 0px 0px',
  margin: '2px',
})

export const CardHeadBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `${theme.palette.chineseSilver.main} 1px solid`,
  paddingBottom: '20px',
}))

export const CardLabelBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.xlarge.fontSize,
}))

export const CardStatusRoundedBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.large.fontSize,
  height: '48px',
  borderRadius: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '0px 40px',
}))


export const BorderedBlockBox = styled(Box)(({ theme }) => ({
  padding: '20px',
  border: `${theme.palette.lightSilver.main} 1px solid`,
  borderRadius: '8px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
}))

export const UnderlinedSpaceBetweenSmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `${theme.palette.chineseSilver.main} 1px solid`,
  paddingBottom: '10px',
  marginBottom: '10px',
  fontSize: theme.typography.regular.fontSize,
}))

export const KeyNameBox = styled(Box)(({ theme }) => ({
  color: theme.palette.weldonBlue.main,
}))

export const ValueNameBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  wordWrap: 'break-word',
}))

export const DomainPriceLabelBox = styled(Box)(({ theme }) => ({
  width: '130px',
  minHeight: '50px',
  background: alpha(theme.palette.lavenderIndigo.main, 0.7),
  fontSize: theme.typography.regular.fontSize,
  color: 'white',
  padding: '15px 0px',
  textAlign: 'center',
}))

export const DomainPriceValueBox = styled(Box)(({ theme }) => ({
  width: '130px',
  minHeight: '100px',
  background: theme.palette.lavender.main,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.lavenderIndigo.main,
  padding: '20px 0px',
  textAlign: 'center',
}))

export const DomainInvoiceLinkBox = styled(Box)(({ theme }) => ({
  width: '113px',
  height: '113px',
  background: `url(${invoiceBackground}) no-repeat center`,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.royalOrange.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.7,
  }
}))

export const DomainPayLinkBox = styled(Box)(({ theme }) => ({
  width: '113px',
  height: '113px',
  background: `url(${payBackground}) no-repeat center`,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.greenCrayola.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.7,
  }
}))

export const SubtitleBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.large.fontSize,
  color: theme.palette.blueSapphire.main,
}))

export const IconWarningBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.royalOrange.main,
  cursor: 'pointer',

  '&:hover': {
    color: alpha(theme.palette.royalOrange.main, 0.7),
  }
}))

export const IconErrorBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.vividRed.main,
  cursor: 'pointer',

  '&:hover': {
    color: alpha(theme.palette.vividRed.main, 0.7),
  }
}))

export const IconSilverBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.chineseSilver.main,
  cursor: 'pointer',

  '&:hover': {
    color: alpha(theme.palette.chineseSilver.main, 0.7),
  }
}))

export const DomainTransferClickableBox = styled(Box)(({ theme }) => ({
  minHeight: '250px',
  border: `${theme.palette.secondary.main} 1px solid`,
  borderRadius: '8px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.7,
  }
}))

export const DomainTransferIconBox = styled(Box)({
  width: '80px',
  height: '68px',
  background: `url(${transferIcon}) no-repeat center / contain`,
  marginBottom: '10px',
})

export const DomainTransferTitleBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.large.fontSize,
  color: theme.palette.secondary.main,
}))

export const SuccessButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.large.fontSize,
  textTransform: 'none',
  background: theme.palette.greenCrayola.main,
  color: 'white',
  padding: '8px 20px',
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: '8px',

  '&:hover': {
    boxShadow: 'none',
    background: theme.palette.greenCrayola.main,
    opacity: 0.8,
  }
}))

export const ErrorButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.large.fontSize,
  textTransform: 'none',
  background: theme.palette.vividRed.main,
  color: 'white',
  padding: '8px 20px',
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: '8px',

  '&:hover': {
    boxShadow: 'none',
    background: theme.palette.vividRed.main,
    opacity: 0.8,
  }
}))

export const DnsErrorNotificationBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.large.fontSize,
  color: theme.palette.vividRed.main,
}))

export const SimpleLinkBox = styled(Box)({
  textDecoration: 'underline',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'none',
  }
})

export const HomeWrapperBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginBottom: '50px',

  '@media (max-width: 1199px)': {
    flexDirection: 'column',
    marginBottom: '0px',
  }
})

export const HomeCardBox = styled(Box)(({ theme }) => ({
  minWidth: '500px',
  border: '1px solid',
  borderColor: alpha(theme.palette.darkCerulean.main, 0.5),
  borderRadius: '6px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',

  '@media (max-width: 1199px)': {
    marginBottom: '30px',
  },

  '@media (max-width: 899px)': {
    minWidth: '100%',
  },
}))

export const HomeCardLabelBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.xlarge.fontSize,
  padding: '20px',
  borderBottom: '1px solid',
  borderColor: alpha(theme.palette.darkCerulean.main, 0.5),

  '@media (max-width: 899px)': {
    cursor: 'pointer',
    borderBottom: 'none',
  },
}))

export const HomeCardButtonWrapperBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '250px',
})

export const HomeCardButtonBox = styled(Box)(({ theme }) => ({
  width: '317px',
  height: '81px',
  cursor: 'pointer',
  fontSize: theme.typography.large.fontSize,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '10%',

  '&:hover': {
    opacity: 0.8,
  }
}))

export const HomeVisualVpsBox = styled(Box)({
  position: 'relative',
  minWidth: '315px',
  height: '199px',
  background: `url(${homeVisualVps}) no-repeat left center`,
  marginRight: '50px',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  }
})

export const HomeVisualVpsTitleBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '-10px',
  top: '20px',
  color: theme.palette.secondary.main,
  fontSize: theme.typography.large.fontSize,
}))

export const HomeVisualVpsTitleCpuBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '-55px',
  top: '5px',
  color: theme.palette.lavenderIndigo.main,
  fontSize: theme.typography.large.fontSize,
}))

export const HomeVisualVpsTitleRamBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '25px',
  top: '72px',
  color: theme.palette.greenCrayola.main,
  fontSize: theme.typography.large.fontSize,
}))

export const HomeVisualVpsTitleHddBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '-48px',
  top: '142px',
  color: theme.palette.royalOrange.main,
  fontSize: theme.typography.large.fontSize,
}))

export const HomeVisualVpsValueTotalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '60px',
  left: '12px',
  top: '80px',
  color: theme.palette.secondary.main,
  fontSize: theme.typography.xxlarge.fontSize,
  textAlign: 'center',
}))

export const HomeVisualVpsValueCpuBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '40px',
  right: '10px',
  top: '18px',
  color: theme.palette.lavenderIndigo.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualVpsValueRamBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '40px',
  right: '86px',
  top: '86px',
  color: theme.palette.greenCrayola.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualVpsValueHddBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '40px',
  right: '11px',
  top: '156px',
  color: theme.palette.royalOrange.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualHostingBox = styled(Box)({
  position: 'relative',
  minWidth: '390px',
  height: '175px',
  background: `url(${homeVisualHosting}) no-repeat left center`,
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  }
})

export const HomeVisualHostingTitleEmailsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '118px',
  left: '2px',
  top: '72px',
  color: theme.palette.lavenderIndigo.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualHostingTitleDomainBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '118px',
  right: '2px',
  top: '30px',
  color: theme.palette.greenCrayola.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualHostingTitleStorageBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '118px',
  right: '2px',
  top: '102px',
  color: theme.palette.royalOrange.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualHostingValueEmailsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '68px',
  left: '121px',
  top: '72px',
  color: theme.palette.lavenderIndigo.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualHostingValueDomainBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '68px',
  right: '124px',
  top: '30px',
  color: theme.palette.greenCrayola.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualHostingValueStorageBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '68px',
  right: '124px',
  top: '118px',
  color: theme.palette.royalOrange.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualDomainsEngBox = styled(Box)({
  position: 'relative',
  minWidth: '281px',
  height: '66px',
  background: `url(${homeVisualDomainsEng}) no-repeat left center`,
  marginBottom: '30px',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  }
})

export const HomeVisualDomainsTitleBox = styled(Box)({
  position: 'absolute',
  width: '140px',
  left: '78px',
  top: '20px',
  color: 'white',
  textAlign: 'center',
})

export const HomeVisualDomainsExtensionBox = styled(Box)({
  position: 'absolute',
  width: '30px',
  right: '10px',
  top: '20px',
  color: 'white',
  textAlign: 'center',
})

export const HomeVisualDomainsEngValueBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '50px',
  left: '7px',
  top: '20px',
  color: theme.palette.lavenderIndigo.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualDomainsGeoBox = styled(Box)({
  position: 'relative',
  minWidth: '281px',
  height: '66px',
  background: `url(${homeVisualDomainsGeo}) no-repeat left center`,
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  }
})

export const HomeVisualDomainsGeoValueBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '50px',
  left: '7px',
  top: '20px',
  color: theme.palette.secondary.main,
  fontSize: theme.typography.large.fontSize,
  textAlign: 'center',
}))

export const HomeVisualInternetBox = styled(Box)({
  position: 'relative',
  minWidth: '392px',
  height: '186px',
  background: `url(${homeVisualInternet}) no-repeat left center`,
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  }
})

export const HomeVisualInternetTitleLocalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '110px',
  right: '87px',
  top: '26px',
  color: theme.palette.royalOrange.main,
  textAlign: 'center',
}))

export const HomeVisualInternetTitleGlobalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '110px',
  right: '87px',
  top: '135px',
  color: theme.palette.secondary.main,
  textAlign: 'center',
}))

export const HomeVisualInternetValueLocalBox = styled(Box)({
  position: 'absolute',
  width: '85px',
  right: '1px',
  top: '26px',
  color: 'white',
  textAlign: 'center',
})

export const HomeVisualInternetValueGlobalBox = styled(Box)({
  position: 'absolute',
  width: '85px',
  right: '1px',
  top: '135px',
  color: 'white',
  textAlign: 'center',
})

export const HeaderUserMenuBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  border: `${theme.palette.secondary.main} 1px solid`,
  borderRadius: '8px',
  background: 'white',
  margin: '20px -50px 0px 0px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent : 'flex-start',
  minWidth: '200px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
}))

export const HeaderUserMenuLabelBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.large.fontSize,
  color: theme.palette.lavenderIndigo.main,
  marginBottom: '10px',
  textAlign: 'center',

  '@media (max-width: 899px)': {
    textAlign: 'left',
  },
}))

export const HeaderUserMenuItemButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.primary.main,
  borderBottom: '1px solid',
  borderColor: alpha(theme.palette.secondary.main, 0.5),
  borderRadius: '0px',
  width: '100%',
  justifyContent: 'left',
  padding: '10px 10px',

  '&:hover': {
    background: 'none',
    opacity: 0.8,
  },
}))

export const CircleWarningBox = styled(Box)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.royalOrange.main,
  color: 'white',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.8,
  }
}))

export const SecondaryLinkBox = styled(Box)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'none',
  },
}))