import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
// import Button from '@mui/material/Button'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import hostingList from '../../data/hostingList'
import CircleIcon from '../common/icons/CircleIcon'
import ContentForm from '../common/forms/ContentForm'
import VpsMenu from '../vps/VpsMenu'
import ModalNotification from '../common/ModalNotification'
import ModalWindow from '../common/ModalWindow'

const HostingHead = (props) => {
  const { pageName, setPageName } = props
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-hosting-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('lg')

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleOpenNotification = ({ title, content, size }) => {
    setModalOpen(true)
    setModalWidth(size ? size : 'sm')
    setModalTitle(title)
    setModalContent(<ModalNotification setModalOpen={setModalOpen} notificationText={content} />)
  }

  const handleOpenAddForm = () => {
    setModalOpen(true)
    setModalTitle(texts.order)
    setModalWidth('lg')
    setModalContent(
      <ContentForm
        formName='contact_hosting'
        handleCloseModal={handleCloseModal}
        handleOpenNotification={handleOpenNotification}
      />
    )
  }

  const wrapperStyle = {
    borderBottom: pageName === 'tariffs' ? `${theme.palette.chineseSilver.main} 1px solid` : 'none',
    marginBottom: '30px',
    paddingBottom: '20px',
  }

  const labelStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width >= 900 ? theme.typography.xlarge.fontSize : theme.typography.large.fontSize,
    color: theme.palette.primary.main,
  }

  // const helpButtonStyle = {
  //   fontFamily: theme.typography.regular.fontFamily,
  //   fontSize: theme.typography.large.fontSize,
  //   textTransform: 'none',
  //   background: theme.palette.darkCerulean.main,
  //   color: 'white',
  //   height: '50px',
  //   padding: '0px 20px',
  //   cursor: 'pointer',
  //   boxShadow: 'none',
  //   borderRadius: '10px',

  //   '&:hover': {
  //     boxShadow: 'none',
  //     background: theme.palette.darkCerulean.main,
  //     opacity: 0.8,
  //   }
  // }

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={wrapperStyle}
      >
        <Box>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            spacing={2}
          >
            <Box sx={labelStyle}>{texts.hostingService}</Box>
            { isAdmin && <CircleIcon type='add' size={ width >= 900 ? '48' : '32' } bg='weldonBlue' action={handleOpenAddForm} /> }
          </Stack>
        </Box>
        <VpsMenu menu={hostingList} pageName={pageName} setPageName={setPageName} />
        {/* <Button
          variant='contained'
          disableRipple
          disableElevation
          sx={helpButtonStyle}
        >
          {texts.technicalAssistance}
        </Button> */}
      </Stack>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth={modalWidth}
        onModalClose={handleCloseModal}
      />
    </>
  )
}

export default HostingHead