import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import CompactCellElement from './CompactCellElement'

const CompactRowElement = ({ record, tableFields }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        borderBottom: `${theme.palette.chineseSilver.main} 1px solid`,
        padding: '5px 0px',
      }}
    >
      {
        tableFields.map((item, index) => (
          <CompactCellElement key={index} {...item} record={record} />
        ))
      }
    </Box>
  )
}

export default CompactRowElement