export const apiContentForms = {
  getForms: 'content-forms',
  getFormFields: 'content-form-fields',
  getFormFieldTypes: 'content-form-field-types',
  getTextFields: 'content-text-fields',
  getIntegerFileds: 'content-integer-fields',
  getFloatFields: 'content-float-fields',
  getSelectFields: 'content-select-fields',
  getBooleanFields: 'content-boolean-fields',
  getDateFields: 'content-date-fields',
  getSubmitForm: 'content-submit-form',
}

export const apiDomains = {
  getSpecialDomains: 'special-domains',
  getPrice: 'domains-price',
  getDomains: 'domains',
  getRules: 'domains-rules',
  getPriceList: 'domains-price-list',
}

export const apiHosting = {
  getVpsPackages: 'virtualization-packages',
  getServices: 'virtualization-services',
  getProcess: 'virtualization-processes',
  getAdvantages: 'company-advantages',
  getHostingPackages: 'hosting-packages',
}

export const apiServices = {
  getVirtualMachines: 'virtual-machines',
  getVirtualMachinesCredentials: 'virtual-machines/credentials',
  getVmCredentialsClientsUi: 'vm-credentials/clients-ui',
  getVmCredentialsClientsUiEmailRequest: 'vm-credentials/clients-ui/email-request',
  getNetLocations: 'net-locations',
  getHostingDomains: 'hosting-domains',
  getVirtualMachinesClientsUi: 'virtual-machines/clients-ui',
  getClientNetLocationsClientsUi: 'client-net-locations/clients-ui',
  getClientServicesClientsUi: 'client-services/clients-ui',
  getGraphs: 'graphs',
}

export const apiUsers = {
  getSignUp: 'sign-up',
  getProfile: 'profile',
  getUsers: 'users',
  getUsersValidationEmailResend: 'users/validation-email-resend',
  getUsersPasswordReset: 'users/password-reset',
  getUsersPasswordResetConfirm: 'users/password-reset-confirm',
  getUsersPasswordUpdate: 'users/password-update',
  getUsersEmailConfirm: 'users/email-confirm',
  getAccounts: 'accounts',
}