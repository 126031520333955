import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  HomeVisualInternetBox,
  HomeVisualInternetTitleLocalBox,
  HomeVisualInternetTitleGlobalBox,
  HomeVisualInternetValueLocalBox,
  HomeVisualInternetValueGlobalBox,
} from '../../themes/styles'

const HomeVisualInternet = ({ netLocations }) => {
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)

  const [localBandthidthCount, setLocalBandwidthCount] = useState(0)
  const [globalBandwidthCount, setGlobalBandwidthCount] = useState(0)

  const handleNavigate = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/internet', '_blank')
    } else if (e.type === 'click') {
      navigate('/internet')
    }
  }

  useEffect(() => {
    let local_bandwidth_count = 0
    let global_bandwidth_count = 0

    netLocations.forEach(loc => {
      local_bandwidth_count += parseFloat(loc.local_bandwidth)
      global_bandwidth_count += parseFloat(loc.global_bandwidth)
    })

    setLocalBandwidthCount(local_bandwidth_count)
    setGlobalBandwidthCount(global_bandwidth_count)
  }, [netLocations])

  return (
    <HomeVisualInternetBox onClick={handleNavigate} onMouseDown={handleNavigate}>
      <HomeVisualInternetTitleLocalBox>{texts.local}</HomeVisualInternetTitleLocalBox>
      <HomeVisualInternetTitleGlobalBox>{texts.global}</HomeVisualInternetTitleGlobalBox>

      <HomeVisualInternetValueLocalBox>{localBandthidthCount} {texts.mbps}</HomeVisualInternetValueLocalBox>
      <HomeVisualInternetValueGlobalBox>{globalBandwidthCount} {texts.mbps}</HomeVisualInternetValueGlobalBox>
    </HomeVisualInternetBox>
  )
}

export default HomeVisualInternet