import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/windowDimensions'
import { setCurrentAccount, setCurrentAccountName } from '../../store/reducers/settings'
import { getIsAccountOwner, getIsSuperadmin } from '../../helpers/users'
import { emptyUserData } from '../../store/reducers/userData'
import CircleIcon from './icons/CircleIcon'
import FormSelectField from './formFields/FormSelectField'
import AccountAdd from '../users/AccountAdd'
import ModalWindow from './ModalWindow'

const HeaderAccounts = () => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()

  const containerStyles = {
    background: 'white',
  }

  const dropdownStyles = {
    background: 'white',
  }

  const superadminAccountNameStyle = {
    minHeight: '40px',
    background: 'white',
    borderRadius: '4px',
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: width >= 900 ? '0px 10px' : '0px',
  }

  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const currentAccountName = useSelector(state => state.settings.currentAccountName)
  const texts = useSelector(state => state.settings.langTexts)
  
  const isSuperadmin = getIsSuperadmin(userData)

  const [accountList, setAccountList] = useState([])
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  
  const handleChangeAccount = (value) => {
    dispatch(setCurrentAccount({ currentAccount: value }))
  }

  const handleOpenNotificationModal = (props) => {
    setModalOpen(true)
    setModalTitle(props.title)
    setModalContent(props.content)
  }

  const handleOpenAccountAddModal = () => {
    setModalOpen(true)
    setModalTitle(texts.createNewAccount)
    setModalContent(<AccountAdd setModalOpen={setModalOpen} handleOpenNotificationModal={handleOpenNotificationModal} />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const isAccountInList = useCallback((id, list) => {
    if (!id || list.length === 0) {
      return false
    }

    const findObject = list.find(item => item.value === id)
    return findObject ? true : false
  }, [])

  useEffect(() => {
    if (userData && userData.profile && userData.profile.accounts && userData.profile.accounts.length > 0) {
      let toSelect = []

      userData.profile.accounts.forEach(item => (item.status === 'active' || getIsAccountOwner(item.id, userData)) && toSelect.push({
        value: item.id,
        title: item.account_type === 'company' ? item.company_name : item.name + ' ' + item.lastname,
      }))

      if (toSelect.length === 0) {
        dispatch(emptyUserData())
      }

      setAccountList(toSelect)
    } else {
      setAccountList([])
    }
  }, [userData, dispatch])

  useEffect(() => {
    if (isSuperadmin && !currentAccount && !currentAccountName && accountList.length > 0 ) {
      dispatch(setCurrentAccount({ currentAccount: accountList[0].value }))
      dispatch(setCurrentAccountName({ currentAccountName: accountList[0].title }))
    } else {
      if (accountList.length > 0 && !currentAccount) {
        dispatch(setCurrentAccount({ currentAccount: accountList[0].value }))
      } else if (accountList.length > 0 && !isAccountInList(currentAccount, accountList) && !isSuperadmin) {
        dispatch(setCurrentAccount({ currentAccount: accountList[0].value }))
      }
    }
  }, [dispatch, accountList, currentAccount, currentAccountName, isAccountInList, isSuperadmin])
  
  return (
    <>
      {
        width >= 1200 &&
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: width >= 900 ? '30px' : '0px',
          }}
        >
          {
            isSuperadmin ?
            <Box sx={superadminAccountNameStyle}>{currentAccountName}</Box> :
            <>
              <Box>
                <FormSelectField
                  label=''
                  value={ (accountList.length > 0 && currentAccount && isAccountInList(currentAccount, accountList)) ? currentAccount : '' }
                  required={false}
                  error={false}
                  selectItems={accountList}
                  handleChange={handleChangeAccount}
                  size='small'
                  fieldWidth={ width >= 900 ? '400px' : `${width / 1.7}px` }
                  customContainerStyles={ width >= 900 ? containerStyles : undefined }
                  customDropdownStyles={ width >= 900 ? dropdownStyles : undefined }
                />
              </Box>

              <Box sx={{ marginLeft: '10px' }}>
                <CircleIcon type='add' size='32' bg='customWhite' color='greenCrayola' action={handleOpenAccountAddModal} />
              </Box>
            </>
          }      

          <ModalWindow
            modalOpen={modalOpen}
            modalTitle={modalTitle}
            modalContent={modalContent}
            modalButtons=''
            modalWidth='lg'
            onClose={handleCloseModal}
          />
        </Box>
      }
    </>
  )
}

export default HeaderAccounts