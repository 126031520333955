import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import useWindowDimensions from '../../../helpers/windowDimensions'
import RowElement from './RowElement'
import CellElement from './CellElement'
import PaginationElement from '../PaginationElement'

const TableElement = ({
  tableData,
  tableFields,
  tableWidth,
  tableSize,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const drawerOpened = useSelector(state => state.settings.drawerOpened)

  const [tableStyle, setTableStyle] = useState({ width: tableWidth ? tableWidth : '100%' })

  useEffect(() => {
    if (!tableWidth) {
      const drawerWidth = drawerOpened ? 360 : 125
      
      setTableStyle({
        width: `${width - drawerWidth}px`,
        overflow: 'auto',
        padding: '0px 10px 10px 0px',

        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          borderRadius: '4px',
        },
        
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.secondary.main,
          outline: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '4px',
        }
      })
    }
  }, [width, tableWidth, drawerOpened, theme])
  
  return (
    <>
      <TableContainer sx={tableStyle}>
        <Table size={ tableSize ? tableSize : 'small' }>
          <TableHead>
            <TableRow>
              {
                tableFields.map((item, index) => (
                  <CellElement key={index} {...item} type='head' />
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tableData.map((item, index) => (
                <RowElement
                  key={index}
                  record={item}
                  tableFields={tableFields}
                  rowStyle=''
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>

      { (totalPages && totalPages > 1) && <PaginationElement currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} /> }
    </>
    
  )
}

export default TableElement