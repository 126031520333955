import React from 'react'
import CompactRowElement from './CompactRowElement'
import PaginationElement from '../PaginationElement'

const CompactTableElement = ({
  tableData,
  tableFields,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <>
      {
        tableData.map((item, index) => (
          <CompactRowElement key={index} record={item} tableFields={tableFields} />
        ))
      }

      { (totalPages && totalPages > 1) && <PaginationElement currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} /> }
    </>
  )
}

export default CompactTableElement