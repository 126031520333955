import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import {
  HomeVisualVpsBox,
  HomeVisualVpsTitleBox,
  HomeVisualVpsTitleCpuBox,
  HomeVisualVpsTitleRamBox,
  HomeVisualVpsTitleHddBox,
  HomeVisualVpsValueTotalBox,
  HomeVisualVpsValueCpuBox,
  HomeVisualVpsValueRamBox,
  HomeVisualVpsValueHddBox,
} from '../../themes/styles'

const HomeVisualVps = ({ virtualMachines }) => {
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)

  const [cpuCount, setCpuCount] = useState(0)
  const [ramCount, setRamCount] = useState(0)
  const [diskCount, setDiskCount] = useState(0)

  const handleNavigate = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/vps', '_blank')
    } else if (e.type === 'click') {
      navigate('/vps')
    }
  }

  useEffect(() => {
    let cpu_count = 0
    let ram_count = 0
    let disk_count = 0

    virtualMachines.forEach(vm => {
      cpu_count += parseFloat(vm.cpu)
      ram_count += parseFloat(vm.ram)
      disk_count += parseFloat(vm.disk)
    })

    setCpuCount(cpu_count)
    setRamCount(ram_count)
    setDiskCount(disk_count)
  }, [virtualMachines])

  return (
    <HomeVisualVpsBox onClick={handleNavigate} onMouseDown={handleNavigate}>
      <HomeVisualVpsTitleBox>{texts.myServers}</HomeVisualVpsTitleBox>

      <HomeVisualVpsTitleCpuBox>
        <Box>{texts.cpu}</Box>
        <Box>{texts.cores}</Box>
      </HomeVisualVpsTitleCpuBox>

      <HomeVisualVpsTitleRamBox>
        <Box>{texts.ram}</Box>
        <Box>({texts.gb})</Box>
      </HomeVisualVpsTitleRamBox>

      <HomeVisualVpsTitleHddBox>
        <Box>{texts.hdd}</Box>
        <Box>({texts.gb})</Box>
      </HomeVisualVpsTitleHddBox>

      <HomeVisualVpsValueTotalBox>{virtualMachines.length}</HomeVisualVpsValueTotalBox>      
      <HomeVisualVpsValueCpuBox>{cpuCount}</HomeVisualVpsValueCpuBox>      
      <HomeVisualVpsValueRamBox>{ramCount}</HomeVisualVpsValueRamBox>      
      <HomeVisualVpsValueHddBox>{diskCount}</HomeVisualVpsValueHddBox>
    </HomeVisualVpsBox>
  )
}

export default HomeVisualVps