import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { convertLatinToGeorgian, convertGeorgianToLatin, isValidEmail, isValidPhone, isValidPersonalNumber, isValidCompanyCode } from '../../helpers/string'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getIsSuperadmin } from '../../helpers/users'
import FormTextField from '../common/formFields/FormTextField'

const DomainContactForm = ({
  contactType,
  isCompany,
  isEdit,
  name,
  setName,
  nameEng,
  setNameEng,
  lastname,
  setLastname,
  lastnameEng,
  setLastnameEng,
  org,
  setOrg,
  orgEng,
  setOrgEng,
  address,
  setAddress,
  addressEng,
  setAddressEng,
  email,
  setEmail,
  phone,
  setPhone,
  personal,
  setPersonal,
  errors,
  setErrors,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.error.main,
    fontSize: '14px',
  }

  const leftBlockMargin = width >= 900 ? '0px 10px 20px 0px' : '0px 0px 20px 0px'
  const rightBlockMargin = width >= 900 ? '0px 0px 20px 10px' : '0px 0px 20px 0px'

  const lang = useSelector(state => state.settings.currentLang)
  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)

  const isSuperadmin = getIsSuperadmin(userData)

  const geoText = lang === 'en' ? ' (Geo)' : ''
  const engText = lang === 'ka' ? ' (Eng)' : ''

  const showNames = !isCompany || contactType !== 'registrant'
  const showOrg = isCompany || contactType !== 'registrant'
  const isOrgRequired = isCompany && contactType === 'registrant'
  const isCompanyId = isCompany && contactType === 'registrant'
  const isDisabled = !isSuperadmin && isEdit && contactType === 'registrant' 

  const handleChangeName = (value) => {
    setName(convertLatinToGeorgian(value))

    if (!value && showNames) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Name`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Name`]: '' }
      })
    }
  }

  const handleChangeNameEng = (value) => {
    setNameEng(convertGeorgianToLatin(value))

    if (!value && showNames) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}NameEng`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}NameEng`]: '' }
      })
    }
  }

  const handleChangeLastname = (value) => {
    setLastname(convertLatinToGeorgian(value))

    if (!value && showNames) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Lastname`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Lastname`]: '' }
      })
    }
  }

  const handleChangeLastnameEng = (value) => {
    setLastnameEng(convertGeorgianToLatin(value))

    if (!value && showNames) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}LastnameEng`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}LastnameEng`]: '' }
      })
    }
  }

  const handleChangeOrg = (value) => {
    setOrg(convertLatinToGeorgian(value))

    if (!value && isOrgRequired) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Org`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Org`]: '' }
      })
    }
  }

  const handleChangeOrgEng = (value) => {
    setOrgEng(convertGeorgianToLatin(value))

    if (!value && isOrgRequired) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}OrgEng`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}OrgEng`]: '' }
      })
    }
  }

  const handleChangeAddress = (value) => {
    setAddress(convertLatinToGeorgian(value))

    if (!value) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Address`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Address`]: '' }
      })
    }
  }

  const handleChangeAddressEng = (value) => {
    setAddressEng(convertGeorgianToLatin(value))

    if (!value) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}AddressEng`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}AddressEng`]: '' }
      })
    }
  }

  const handleChangeEmail = (value) => {
    setEmail(value)

    if (!isValidEmail(value)) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Email`]: value ? texts.registerErrorEmail : texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Email`]: '' }
      })
    }
  }

  const handleChangePhone = (value) => {
    setPhone(value)

    if (!isValidPhone(value)) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Phone`]: value ? texts.errorPhone : texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Phone`]: '' }
      })
    }
  }

  const handleChangePersonal = (value) => {
    const valueMaxLength = isCompanyId ? 9 : 11
    const croppedValue = value.length <= valueMaxLength ? value : personal

    setPersonal(croppedValue)

    const validatePersonal = isCompanyId ? isValidCompanyCode(croppedValue) : isValidPersonalNumber(croppedValue)
    const validationError = isCompanyId ? texts.errorCompanyId : texts.errorPersonalId

    if (!validatePersonal) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Personal`]: value ? validationError : texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Personal`]: '' }
      })
    }
  }

  return (
    <>
      {
        showNames && (
          <>
            <Grid container>
              <Grid xs={12} lg={6}>
                <Box sx={{ margin: leftBlockMargin }}>
                  <FormTextField
                    type='text'
                    label={texts.name + geoText}
                    placeholder={texts.name + geoText}
                    value={name}
                    required={true}
                    error={errors[`${contactType}Name`] ? true : false}
                    helpertext={errors[`${contactType}Name`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Name`]}</Box>}
                    disabled={isDisabled}
                    lines='1'
                    handleChange={handleChangeName}
                  />
                </Box>
              </Grid>
              <Grid xs={12} lg={6}>
                <Box sx={{ margin: rightBlockMargin }}>
                  <FormTextField
                    type='text'
                    label={texts.name + engText}
                    placeholder={texts.name + engText}
                    value={nameEng}
                    required={true}
                    error={errors[`${contactType}NameEng`] ? true : false}
                    helpertext={errors[`${contactType}NameEng`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}NameEng`]}</Box>}
                    disabled={isDisabled}
                    lines='1'
                    handleChange={handleChangeNameEng}
                  />
                </Box> 
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs={12} lg={6}>
                <Box sx={{ margin: leftBlockMargin }}>
                  <FormTextField
                    type='text'
                    label={texts.lastname + geoText}
                    placeholder={texts.lastname + geoText}
                    value={lastname}
                    required={true}
                    error={errors[`${contactType}Lastname`] ? true : false}
                    helpertext={errors[`${contactType}Lastname`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Lastname`]}</Box>}
                    disabled={isDisabled}
                    lines='1'
                    handleChange={handleChangeLastname}
                  />
                </Box>
              </Grid>
              <Grid xs={12} lg={6}>
                <Box sx={{ margin: rightBlockMargin }}>
                  <FormTextField
                    type='text'
                    label={texts.lastname + engText}
                    placeholder={texts.lastname + engText}
                    value={lastnameEng}
                    required={true}
                    error={errors[`${contactType}LastnameEng`] ? true : false}
                    helpertext={errors[`${contactType}LastnameEng`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}LastnameEng`]}</Box>}
                    disabled={isDisabled}
                    lines='1'
                    handleChange={handleChangeLastnameEng}
                  />
                </Box> 
              </Grid>
            </Grid>
          </>
        )
      }

      {
        showOrg && (
          <Grid container>
            <Grid xs={12} lg={6}>
              <Box sx={{ margin: leftBlockMargin }}>
                <FormTextField
                  type='text'
                  label={texts.organizationName + geoText}
                  placeholder={texts.organizationName + geoText}
                  value={org}
                  required={isOrgRequired}
                  error={errors[`${contactType}Org`] ? true : false}
                  helpertext={errors[`${contactType}Org`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Org`]}</Box>}
                  disabled={isDisabled}
                  lines='1'
                  handleChange={handleChangeOrg}
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={6}>
              <Box sx={{ margin: rightBlockMargin }}>
                <FormTextField
                  type='text'
                  label={texts.organizationName + engText}
                  placeholder={texts.organizationName + engText}
                  value={orgEng}
                  required={isOrgRequired}
                  error={errors[`${contactType}OrgEng`] ? true : false}
                  helpertext={errors[`${contactType}OrgEng`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}OrgEng`]}</Box>}
                  disabled={isDisabled}
                  lines='1'
                  handleChange={handleChangeOrgEng}
                />
              </Box> 
            </Grid>
          </Grid>
        )
      }      

      <Grid container>
        <Grid xs={12} lg={6}>
          <Box sx={{ margin: leftBlockMargin }}>
            <FormTextField
              type='text'
              label={texts.address + geoText}
              placeholder={texts.address + geoText}
              value={address}
              required={true}    
              error={errors[`${contactType}Address`] ? true : false}
              helpertext={errors[`${contactType}Address`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Address`]}</Box>}
              lines='1'
              handleChange={handleChangeAddress}
            />
          </Box>
        </Grid>
        <Grid xs={12} lg={6}>
          <Box sx={{ margin: rightBlockMargin }}>
            <FormTextField
              type='text'
              label={texts.address + engText}
              placeholder={texts.address + engText}
              value={addressEng}
              required={true}
              error={errors[`${contactType}AddressEng`] ? true : false}
              helpertext={errors[`${contactType}AddressEng`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}AddressEng`]}</Box>}
              lines='1'
              handleChange={handleChangeAddressEng}
            />
          </Box> 
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={12} lg={6}>
          <Box sx={{ margin: leftBlockMargin }}>
            <FormTextField
              type='text'
              label={texts.email}
              placeholder={texts.email}
              value={email}
              required={true}
              error={errors[`${contactType}Email`] ? true : false}
              helpertext={errors[`${contactType}Email`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Email`]}</Box>}
              lines='1'
              handleChange={handleChangeEmail}
            />
          </Box>
        </Grid>
        <Grid xs={12} lg={6}>
          <Box sx={{ margin: rightBlockMargin }}>
            <FormTextField
              type='text'
              label={texts.phoneNumber}
              placeholder={texts.phoneNumber}
              value={phone}
              required={true}
              error={errors[`${contactType}Phone`] ? true : false}
              helpertext={errors[`${contactType}Phone`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Phone`]}</Box>}
              lines='1'
              handleChange={handleChangePhone}
            />
          </Box> 
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={12} lg={6}>
          <Box sx={{ margin: leftBlockMargin }}>
            <FormTextField
              type='text'
              label={ isCompanyId ? texts.organizationCode : texts.personalNumber }
              placeholder={ isCompanyId ? texts.organizationCode : texts.personalNumber }
              value={personal}
              required={true}
              error={errors[`${contactType}Personal`] ? true : false}
              helpertext={errors[`${contactType}Personal`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Personal`]}</Box>}
              disabled={isDisabled}
              lines='1'
              handleChange={handleChangePersonal}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default DomainContactForm