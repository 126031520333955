import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LockIcon from '@mui/icons-material/Lock'
import { LoginWrapperBox, PageLabelBox, SecondaryButton, SecondaryLinkBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import urlClick from '../../helpers/navigation'
import kaTexts from '../../lang/kaTexts'
import enTexts from '../../lang/enTexts'
import { requestAuthByUsernameAndPassword, requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { setUser, emptyUserData } from '../../store/reducers/userData'
import { setLangTexts } from '../../store/reducers/settings'
import { getIsAccountOwner } from '../../helpers/users'
import FormTextField from '../common/formFields/FormTextField'
import FormGoogleReCaptcha from '../common/formFields/FormGoogleReCaptcha'
import BackdropElement from '../common/BackdropElement'
import TemporaryNotification from '../common/TemporaryNotification'

const LoginForm = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width > settings.desktopBreakpoint ? '400px' : `${width * 0.9}px`

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: '16px',
  }

  const additionalLinksStyles = {
    marginTop: '40px',
    fontFamily: theme.typography.regular.fontFamily,
  }

  const usernameStartAdornment = (
    <InputAdornment position='start'>
      <AccountCircle />
    </InputAdornment>
  )

  const passwordStartAdornment = (
    <InputAdornment position='start'>
      <LockIcon />
    </InputAdornment>
  )

  const texts = useSelector(state => state.settings.langTexts)
  const currentLang = useSelector(state => state.settings.currentLang)

  const captchaRef = useRef('')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [captchaValidated, setCaptchaValidated] = useState(false)
  const [captchaValidationError, setCaptchaValidationError] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)

  const handleChangeEmail = (value) => {
    setEmail(value)
  }

  const handleChangePassword = (value) => {
    setPassword(value)
  }

  const handleClickRegister = (e) => {
    urlClick(e, navigate, '/sign-up')
  }

  const handleClickPasswordReset = (e) => {
    urlClick(e, navigate, '/password-reset')
  }

  const handleClickValidationEmailResend = (e) => {
    urlClick(e, navigate, '/validation-email-resend')
  }

  const googleCaptchaValidation = async (token) => {
    const response = await fetch(
      `/recaptcha/api/siteverify?secret=${settings.googleReCaptchaSecretKey}&response=${token}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', }
      }
    )
    
    if (!response.ok) {
      const resErrData = await response.json()
      console.log(resErrData)
    } else {
      const resData = await response.json()
      return resData.success
    }   
  }

  const sendRequest = async () => {
    const responseAuth = await requestAuthByUsernameAndPassword(email, password)
    
    if (responseAuth && responseAuth.access_token) {
      const tokens = {
        accessToken: responseAuth.access_token,
        refreshToken: responseAuth.refresh_token,
      } 
      
      const requestUrl = settings.domain + settings.clientsUi + apiUsers.getProfile

      const responseProfile = await requestClients(requestUrl, 'GET', '', { tokens }, dispatch, navigate)
      
      if (responseProfile && responseProfile.username) {
        const findActiveAccount = responseProfile.accounts.find(item => item.status === 'active' || getIsAccountOwner(item.id, { profile: responseProfile }))
        
        if (findActiveAccount) {
          dispatch(setUser({ tokens, profile: responseProfile }))
          dispatch(setLangTexts({ langTexts: currentLang === 'ka' ? kaTexts : enTexts }))
        } else {
          dispatch(emptyUserData())
          setError(texts.youHaveNotActiveAccount)
        }
      } else {
        setError(texts.loginError)
      }      
    } else {
      setError(texts.loginError)
    }

    setBackdropOpen(false)
  }
  
  const handleSubmit = async () => {
    setBackdropOpen(true)
    setError('')
    setCaptchaValidationError(false)

    const reCaptchaResponse = captchaValidated ? true : await googleCaptchaValidation(captchaRef.current.getValue())
    setCaptchaValidated(reCaptchaResponse ? true : false)

    if (email && password && reCaptchaResponse) {
      sendRequest()      
    } else if (!email || !password) {
      setError(texts.loginError)
      setBackdropOpen(false)
    } else {
      setCaptchaValidationError(true)
      setBackdropOpen(false)
    }
  }
  
  return (
    <LoginWrapperBox>
      <TemporaryNotification />

      <Box sx={{ marginBottom: '30px' }}>
        <PageLabelBox>{texts.logInToProfile}</PageLabelBox>
      </Box>

      {
        error && (
          <Box sx={{ width: formWidth, marginBottom: '20px' }}>
            <Alert severity='error' sx={alertStyles}>{error}</Alert>
          </Box>
        )
      }

      <Box sx={{ width: formWidth, marginBottom: '20px' }}>
        <FormTextField
          type='text'
          label={texts.email}
          value={email}
          required={true}
          error={error ? true : false}
          lines='1'
          handleChange={handleChangeEmail}
          startAdornment={usernameStartAdornment}
        />
      </Box>

      <Box sx={{ width: formWidth, marginBottom: '20px' }}>
        <FormTextField
          type='password'
          label={texts.password}
          value={password}
          required={true}
          error={error ? true : false}
          lines='1'
          handleChange={handleChangePassword}
          startAdornment={passwordStartAdornment}
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormGoogleReCaptcha
          key={currentLang}
          captchaRef={captchaRef}
          lang={currentLang}
          error={captchaValidationError}
        />
      </Box>

      <SecondaryButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.login}</SecondaryButton>

      <Box sx={additionalLinksStyles}>
        <Box sx={{ marginBottom: '10px' }}>{texts.notRegistered} <SecondaryLinkBox onClick={handleClickRegister} onMouseDown={handleClickRegister}>{texts.signUp}</SecondaryLinkBox></Box>

        <Box sx={{ marginBottom: '10px' }}>{texts.forgotPassword} <SecondaryLinkBox onClick={handleClickPasswordReset} onMouseDown={handleClickPasswordReset}>{texts.restore}</SecondaryLinkBox></Box>
        
        <Box sx={{ marginBottom: '10px' }}>{texts.haveNotReceivedEmailConfirmationLink} <SecondaryLinkBox onClick={handleClickValidationEmailResend} onMouseDown={handleClickValidationEmailResend}>{texts.resend}</SecondaryLinkBox></Box>        
      </Box>

      <BackdropElement open={backdropOpen} />
    </LoginWrapperBox>
  )
}

export default LoginForm