import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { SuccessButton, SubtitleBox } from '../../themes/styles'
import settings from '../../config/settings'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { isValidEmail } from '../../helpers/string'
import FormTextField from '../common/formFields/FormTextField'
import FormCheckboxField from '../common/formFields/FormCheckboxField'
import FormSelectField from '../common/formFields/FormSelectField'
import BackdropElement from '../common/BackdropElement'

const AccountUserAddForm = ({ setModalOpen, setUpdateUsers, accountId, editRecord }) => {
  const { width } = useWindowDimensions()
  const theme = useTheme()  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width > settings.desktopBreakpoint ? '600px' : ''

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.error.main,
    fontSize: '14px',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)

  const [email, setEmail] = useState('')
  const [groupVps, setGroupVps] = useState(false)
  const [groupHosting, setGroupHosting] = useState(false)
  const [groupDomains, setGroupDomains] = useState(false)
  const [groupInternet, setGroupInternet] = useState(false)
  const [roleVps, setRoleVps] = useState('admin')
  const [roleHosting, setRoleHosting] = useState('admin')
  const [roleDomains, setRoleDomains] = useState('admin')
  const [roleInternet, setRoleInternet] = useState('admin')
  const [errors, setErrors] = useState({})
  const [backdropOpen, setBackdropOpen] = useState(false)

  const roleList = [
    { value: 'admin', title: texts.admin, },
    { value: 'readonly', title: texts.onlyView, },
  ]

  const scrollToId = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleChangeEmail = (value) => {
    setEmail(value)

    if (isValidEmail(value)) {
      setErrors((prev) => {
        return { ...prev, email: '' }
      })
    }
  }

  const handleChangeGroupVps = (value) => {
    setGroupVps(value)
    setRoleVps('admin')
  }

  const handleChangeGroupHosting = (value) => {
    setGroupHosting(value)
    setRoleHosting('admin')
  }

  const handleChangeGroupDomains = (value) => {
    setGroupDomains(value)
    setRoleDomains('admin')
  }

  const handleChangeGroupInternet = (value) => {
    setGroupInternet(value)
    setRoleInternet('admin')
  }

  const handleChangeRoleVps = (value) => {
    setRoleVps(value)
  }

  const handleChangeRoleHosting = (value) => {
    setRoleHosting(value)
  }

  const handleChangeRoleDomains = (value) => {
    setRoleDomains(value)
  }

  const handleChangeRoleInternet = (value) => {
    setRoleInternet(value)
  }

  const generateGroupArray = () => {
    let result = []

    if (groupVps) {
      result.push('vps')
    }

    if (groupHosting) {
      result.push('hosting')
    }

    if (groupDomains) {
      result.push('domains')
    }

    if (groupInternet) {
      result.push('internet')
    }

    return result
  }

  const generateRoleArray = () => {
    let result = []

    if (groupVps && roleVps === 'admin') {
      result.push('client-vps-admin')
    }

    if (groupHosting && roleHosting === 'admin') {
      result.push('client-hosting-admin')
    }

    if (groupDomains && roleDomains === 'admin') {
      result.push('client-domains-admin')
    }

    if (groupInternet && roleInternet === 'admin') {
      result.push('client-internet-admin')
    }

    return result
  }

  const sendRequest = async (postData) => {
    const requestParams = editRecord ? '/' + editRecord.id : ''
    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getUsers + '/' + accountId + requestParams
    const requestMethod = editRecord ? 'PUT' : 'POST'

    const response = await requestClients(requestUrl, requestMethod, postData, userData, dispatch, navigate)
    
    setBackdropOpen(false)
    
    if ( (!editRecord && response && response.status && (response.status === 'created' || response.status === 'updated')) || (editRecord && response && response.id) ) {
      setModalOpen(false)
      setUpdateUsers((prev) => prev + 1)
    }
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)
    let errorsObject = {}

    if (!editRecord && !email) {
      errorsObject.email = texts.pleaseFillOut
    }

    if (!editRecord && email && !isValidEmail(email)) {
      errorsObject.email = texts.registerErrorEmail
    }
    
    if (Object.keys(errorsObject).length === 0) {
      const postData = editRecord ? {
        groups: generateGroupArray(),
        roles: generateRoleArray(),
      } : {
        email,
        groups: generateGroupArray(),
        roles: generateRoleArray(),
      }
      
      sendRequest(postData)
    } else {
      setBackdropOpen(false)
    }

    if (Object.keys(errorsObject).length > 0) {
      scrollToId('formStart')
    }

    setErrors(errorsObject)
  }

  useEffect(() => {
    if (editRecord) {
      if (editRecord.groups && editRecord.groups[accountId] && editRecord.groups[accountId].length > 0) {
        if (editRecord.groups[accountId].includes('vps')) {
          setGroupVps(true)

          if (editRecord.roles && editRecord.roles[accountId] && editRecord.roles[accountId].length > 0 && editRecord.roles[accountId].includes('client-vps-admin')) {
            setRoleVps('admin')
          } else {
            setRoleVps('readonly')
          }
        }

        if (editRecord.groups[accountId].includes('hosting')) {
          setGroupHosting(true)

          if (editRecord.roles && editRecord.roles[accountId] && editRecord.roles[accountId].length > 0 && editRecord.roles[accountId].includes('client-hosting-admin')) {
            setRoleHosting('admin')
          } else {
            setRoleHosting('readonly')
          }
        }

        if (editRecord.groups[accountId].includes('domains')) {
          setGroupDomains(true)

          if (editRecord.roles && editRecord.roles[accountId] && editRecord.roles[accountId].length > 0 && editRecord.roles[accountId].includes('client-domains-admin')) {
            setRoleDomains('admin')
          } else {
            setRoleDomains('readonly')
          }
        }

        if (editRecord.groups[accountId].includes('internet')) {
          setGroupInternet(true)

          if (editRecord.roles && editRecord.roles[accountId] && editRecord.roles[accountId].length > 0 && editRecord.roles[accountId].includes('client-internet-admin')) {
            setRoleInternet('admin')
          } else {
            setRoleInternet('readonly')
          }
        }
      }
    }
  }, [editRecord, accountId])

  return (
    <Box sx={{ minWidth: formWidth }}>
      
      {
        !editRecord && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormTextField
              label={texts.email}
              placeholder={texts.email}
              value={email}
              required={true}
              error={errors.email ? true : false}
              helpertext={errors.email && <Box sx={errorHelperStyles} component='span'>{errors.email}</Box>}
              lineNumber='1'
              handleChange={handleChangeEmail}
            />
          </Box>
        )
      }      

      <Box sx={{ margin: '20px 0px', textAlign: 'center' }}>
        <SubtitleBox>{texts.groups + ' ' + texts.and + ' ' + texts.roles}</SubtitleBox>
      </Box>

      <Box sx={{ margin: '0px 0px 20px 12px' }}>
        <Grid container columnSpacing={2}>
          <Grid lg={6}>
            <FormCheckboxField
              label={texts.virtualServers}
              checked={groupVps}
              required={false}
              error={false}
              handleChange={handleChangeGroupVps}
            />
          </Grid>
          <Grid lg={6}>
            {
              groupVps && (
                <FormSelectField
                  label={texts.role}
                  value={roleVps}
                  required={true}
                  error={false}
                  selectItems={[...roleList]}
                  size='small'
                  handleChange={handleChangeRoleVps}
                />
              )
            }            
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ margin: '0px 0px 20px 12px' }}>
        <Grid container columnSpacing={2}>
          <Grid lg={6}>
            <FormCheckboxField
              label={texts.hosting}
              checked={groupHosting}
              required={false}
              error={false}
              handleChange={handleChangeGroupHosting}
            />
          </Grid>
          <Grid lg={6}>
            {
              groupHosting && (
                <FormSelectField
                  label={texts.role}
                  value={roleHosting}
                  required={true}
                  error={false}
                  selectItems={[...roleList]}
                  size='small'
                  handleChange={handleChangeRoleHosting}
                />
              )
            }
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ margin: '0px 0px 20px 12px' }}>
        <Grid container columnSpacing={2}>
          <Grid lg={6}>
            <FormCheckboxField
              label={texts.domains}
              checked={groupDomains}
              required={false}
              error={false}
              handleChange={handleChangeGroupDomains}
            />
          </Grid>
          <Grid lg={6}>
            {
              groupDomains && (
                <FormSelectField
                  label={texts.role}
                  value={roleDomains}
                  required={true}
                  error={false}
                  selectItems={[...roleList]}
                  size='small'
                  handleChange={handleChangeRoleDomains}
                />
              )
            }            
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ margin: '0px 0px 20px 12px' }}>
        <Grid container columnSpacing={2}>
          <Grid lg={6}>
            <FormCheckboxField
              label={texts.internet}
              checked={groupInternet}
              required={false}
              error={false}
              handleChange={handleChangeGroupInternet}
            />
          </Grid>
          <Grid lg={6}>
            {
              groupInternet && (
                <FormSelectField
                  label={texts.role}
                  value={roleInternet}
                  required={true}
                  error={false}
                  selectItems={[...roleList]}
                  size='small'
                  handleChange={handleChangeRoleInternet}
                />
              )
            }
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.save}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default AccountUserAddForm