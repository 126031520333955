import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const PaginationElement = ({ currentPage, setCurrentPage, totalPages }) => {
  const theme = useTheme()

  let pagesArray = []
  for (let index = 1; index <= totalPages; index ++) {
    pagesArray.push(index)
  }

  let k1 = false
  let k2 = false
  let t1 = true
  let t2 = true

  const sxContainer = {
    fontFamily: theme.typography.english.fontFamily,
    fontSize: theme.typography.large.fontSize,
    margin: '50px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const sxArrow = {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    margin: '7px 10px 0px 10px',

    '&:hover': {
      color: theme.palette.primary.main,
    }
  }

  const sxArrowActive = {
    color: theme.palette.primary.main,
    margin: '7px 10px 0px 10px',
  }

  const sxNumber = {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    margin: '0px 10px',

    '&:hover': {
      color: theme.palette.primary.main,
    }
  }

  const sxNumberActive = {
    cursor: 'default',
    color: theme.palette.primary.main,
    margin: '0px 10px',
  }

  const sxDots = {
    cursor: 'default',
    color: theme.palette.secondary.main,
    margin: '0px 10px',
  }

  const handlePrev = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <Box sx={sxContainer}>
      <Box sx={ currentPage === 1 ? sxArrowActive : sxArrow } onClick={handlePrev}>
        <ArrowBackIcon fontSize='large' />
      </Box>

      {
        pagesArray.map(item => {
          if ((item + 5) < currentPage) k1 = true
			    if ((item - 5) >= currentPage) k2 = true

          if ( item === 1 || item === totalPages || ((item + 5) > currentPage && (item - 5) < currentPage)) {
            return (
              <Box key={item} sx={ currentPage === item ? sxNumberActive : sxNumber } onClick={() => setCurrentPage(item)}>{item}</Box>
            )
          } else if (t1 && k1) {
            t1 = false
            return (
              <Box key={item} sx={sxDots}>...</Box>
            )
          } else if (t2 && k2) {
            t2 = false
            return (
              <Box key={item} sx={sxDots}>...</Box>
            )
          }

          return ''
        })
      }

      <Box sx={ currentPage === totalPages ? sxArrowActive : sxArrow } onClick={handleNext}>
        <ArrowForwardIcon fontSize='large' />
      </Box>
    </Box>
  )
}

export default PaginationElement