import React from 'react'
import Container from '@mui/material/Container'
import Header from '../common/Header'
import PasswordResetConfirmForm from './PasswordResetConfirmForm'

const PasswordResetConfirmPage = () => {
  return (
    <>
      <Header />
      <Container maxWidth='xl'>
        <PasswordResetConfirmForm />
      </Container>
    </>
  )
}

export default PasswordResetConfirmPage