import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import LockIcon from '@mui/icons-material/Lock'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { IconWhiteBox, IconPrimaryBox, HeaderUserMenuBox, HeaderUserMenuLabelBox, HeaderUserMenuItemButton } from '../../themes/styles'
import { getIsAccountOwner, getIsSuperadmin } from '../../helpers/users'
import useWindowDimensions from '../../helpers/windowDimensions'
import PasswordUpdateForm from '../users/PasswordUpdateForm'
import ModalWindow from './ModalWindow'

const HeaderUserMenu = () => {
  const { width } = useWindowDimensions()

  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const texts = useSelector(state => state.settings.langTexts)

  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const isSuperadmin = getIsSuperadmin(userData)

  const [profileEmail, setProfileEmail] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleOpenChangePasswordModal = () => {
    setModalOpen(true)
    setModalTitle(texts.changePassword)
    setModalContent(<PasswordUpdateForm />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleClickAccountSettings = () => {
    navigate('/account-settings')
  }

  useEffect(() => {
    setProfileEmail((userData && userData.profile && userData.profile.email) ? userData.profile.email : '')
  }, [userData])

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseMenu}>
        {
          width >= 900 ?
          <IconWhiteBox onClick={handleOpenMenu}>
            <PersonIcon fontSize='large' />
          </IconWhiteBox> :
          <IconPrimaryBox onClick={handleOpenMenu}>
            <PersonIcon fontSize='large' />
          </IconPrimaryBox>
        }        
      </ClickAwayListener>
      <Popper
        anchorEl={anchorEl}
        open={open}
        transition
        disablePortal
        placement='bottom-end'
        style={{ zIndex: 10000 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <HeaderUserMenuBox>

              <HeaderUserMenuLabelBox>{profileEmail}</HeaderUserMenuLabelBox>

              {
                (isSuperadmin || isAccountOwner) && (
                  <HeaderUserMenuItemButton variant='text' disableRipple={true} startIcon={<SettingsIcon />} onClick={handleClickAccountSettings}>
                    {texts.accountSettings}
                  </HeaderUserMenuItemButton>
                )
              }

              <HeaderUserMenuItemButton variant='text' disableRipple={true} startIcon={<LockIcon />} sx={{ borderBottom: 'none' }} onClick={handleOpenChangePasswordModal}>
                {texts.changePassword}
              </HeaderUserMenuItemButton>
            
            </HeaderUserMenuBox>
          </Fade>
        )}
      </Popper>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalButtons=''
        modalWidth='lg'
        onClose={handleCloseModal}
      />
    </>
  )
}

export default HeaderUserMenu