import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { DomainFilterStatusContainerBox, DomainFilterStatusNumberBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'

const DomainsFilterStatus = ({ value, title, color, domains, filterStatus, setFilterStatus }) => {
  const { width } = useWindowDimensions()

  const [number, setNumber] = useState(0)

  const styleContainer = {
    width: width >= 900 ? undefined : '100%',
    justifyContent: width >= 900 ? 'flex-start' : 'space-between',
    color,
    paddingBottom: value === filterStatus ? '3px' : '4px',
    cursor: value === filterStatus ? 'default' : 'pointer',
    borderBottom: value === filterStatus ? '1px solid' : 'none',
    marginLeft: width >= 900 ? '20px' : '0px',

    '&:hover': {
      borderColor: color,
    }
  }

  const styleNumber = {
    background: color,
  }

  const handleClick = () => {
    if (value !== filterStatus) {
      setFilterStatus(value)
    }
  }

  useEffect(() => {
    if (value === 'all') {
      setNumber(domains.length)
    } else {
      const tempArray = domains.filter(item => item.status === value)
      if (tempArray.length > 0) {
        setNumber(tempArray.length)
      } else {
        setNumber(0)
      }
    }
  }, [value, domains])

  return (
    <DomainFilterStatusContainerBox sx={styleContainer} onClick={handleClick}>
      <Box>{title}</Box>
      <DomainFilterStatusNumberBox sx={styleNumber}>{number}</DomainFilterStatusNumberBox>      
    </DomainFilterStatusContainerBox>
  )
}

export default DomainsFilterStatus