import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NoResultBox } from '../../themes/styles'
import settings from '../../config/settings'
import { requestDomains } from '../../network/request'
import { getTextedDate, getFormattedDate } from '../../helpers/date'
import DomainsHead from './DomainsHead'
import DomainPaymentsSubheader from './DomainPaymentsSubheader'
import TableElement from '../common/table/TableElement'
import BackdropElement from '../common/BackdropElement'

const DomainPaymentsContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [payments, setPayments] = useState([])
  const [filteredPayments, setFilteredPayments] = useState([])
  const [tableFields, setTableFields] = useState([])
  const [tableData, setTableData] = useState([])
  const [filterKeyword, setFilterKeyword] = useState('')
  const [filterStartDate, setFilterStartDate] = useState('')
  const [filterEndDate, setFilterEndDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [backdropOpen, setBackdropOpen] = useState(true)

  useEffect(() => {
    setCurrentPage(1)
  }, [filterKeyword, filterStartDate, filterEndDate])

  useEffect(() => {
    const fetchPayments = async () => {
      setBackdropOpen(true)

      const response = await requestDomains(settings.fetchDomains + `/?action=client-domain-payments&account_id=${currentAccount}`, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.status && response.status === 100 && response.data && response.data.length > 0) {
        let tempArray = []

        response.data.forEach(item => tempArray.push({ ...item, status: texts.successfull }))

        setPayments(tempArray)
      } else {
        setPayments([])
        setBackdropOpen(false)
      }
    }

    fetchPayments()
  }, [userData, dispatch, navigate, texts, currentAccount])

  useEffect(() => {
    setTableFields([
      {
        keyValue: 'domain_name',
        content: texts.domain,
      },
      {
        keyValue: 'status',
        content: texts.status,
        customHeadStyle: {
          textAlign: 'center',
        },
        customBodyStyle: {
          textAlign: 'center',
        },
      },
      {
        keyValue: 'amount',
        content: texts.sapasuri,
        customHeadStyle: {
          textAlign: 'center',
        },
        customBodyStyle: {
          textAlign: 'center',
        },
      },
      {
        keyValue: 'pay_date',
        content: texts.paymentDate,
        customHeadStyle: {
          textAlign: 'right',
        },
        customBodyStyle: {
          textAlign: 'right',
        },
      },
    ])
  }, [texts])

  useEffect(() => {
    if (payments.length > 0) {
      let tempArray = payments
      const hasExtension = filterKeyword.includes('.ge') || filterKeyword.includes('.გე')

      if (filterKeyword && hasExtension) {
        tempArray = tempArray.filter(item => item.domain_name === filterKeyword)
      } else if (filterKeyword && !filterKeyword.includes('.')) {
        tempArray = tempArray.filter(item => item.domain_name.includes(filterKeyword))
      }

      if (filterStartDate && filterEndDate) {
        tempArray = tempArray.filter(item => getFormattedDate(item.pay_date) >= getFormattedDate(filterStartDate) && getFormattedDate(item.pay_date) <= getFormattedDate(filterEndDate))
      } else if (filterStartDate) {
        tempArray = tempArray.filter(item => getFormattedDate(item.pay_date) >= getFormattedDate(filterStartDate))
      } else if (filterEndDate) {
        tempArray = tempArray.filter(item => getFormattedDate(item.pay_date) <= getFormattedDate(filterEndDate))
      }

      setTotalPages(Math.ceil(tempArray.length / settings.domainsCount))
      
      tempArray = tempArray.filter((item, index) => index >= settings.domainsCount * (currentPage - 1) && index < settings.domainsCount * currentPage)

      setFilteredPayments(tempArray)
    } else {
      setFilteredPayments([])
      setTotalPages(1)
    }
  }, [payments, filterKeyword, filterStartDate, filterEndDate, currentPage])

  useEffect(() => {
    if (filteredPayments.length > 0 && tableFields.length > 0) {
      let tempArray = []
      
      filteredPayments.forEach(item => {
        let tempObject = {}

        tableFields.forEach(item2 => {
          if (item[item2.keyValue] && item2.keyValue === 'amount') {
            tempObject[item2.keyValue] = item[item2.keyValue] + ' ' + texts.gel
          } else if (item[item2.keyValue] && item2.keyValue === 'pay_date') {
            tempObject[item2.keyValue] = getTextedDate(item[item2.keyValue])
          } else if (item[item2.keyValue]) {
            tempObject[item2.keyValue] = item[item2.keyValue]
          } else {
            tempObject[item2.keyValue] = ''
          }
        })

        tempArray.push(tempObject)
      })

      setTableData(tempArray)
      setBackdropOpen(false)
    } else {
      setTableData([])
    }
  }, [filteredPayments, tableFields, texts])

  return (
    <>
      <DomainsHead />

      <DomainPaymentsSubheader
        filterKeyword={filterKeyword}
        setFilterKeyword={setFilterKeyword}
        filterStartDate={filterStartDate}
        setFilterStartDate={setFilterStartDate}
        filterEndDate={filterEndDate}
        setFilterEndDate={setFilterEndDate}
      />

      <TableElement
        tableData={tableData}
        tableFields={tableFields}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />

      { (tableData.length === 0 && !backdropOpen) && <NoResultBox>{texts.nothingHasFound}</NoResultBox> }

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainPaymentsContent