import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { SecondaryButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { setUserProfile } from '../../store/reducers/userData'
import BackdropElement from '../common/BackdropElement'

const AccountStatusToggle = ({ setModalOpen, activate, accountId, accountName }) => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const notificationStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    textAlign: 'center',
    minWidth: width > settings.desktopBreakpoint ? '400px' : 'auto',
    marginBottom: '20px',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)

  const [backdropOpen, setBackdropOpen] = useState(false)

  const notificationText = activate ? texts.activateAccountWarning.replace('###ACCOUNT###', accountName) : texts.deactivateAccountWarning.replace('###ACCOUNT###', accountName)

  const handleSubmit = async () => {
    setBackdropOpen(true)

    const newStatus = activate ? 'activate' : 'deactivate'
    const responseStatus = activate ? 'activated' : 'deactivated'

    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getAccounts + '/' + newStatus + '/' + accountId
    
    const response = await requestClients(requestUrl, 'PUT', '', userData, dispatch, navigate)
    
    if (response && response.status && response.status === responseStatus) {
      const requestProfileUrl = settings.domain + settings.clientsUi + apiUsers.getProfile

      const responseProfile = await requestClients(requestProfileUrl, 'GET', '', userData, dispatch, navigate)

      if (responseProfile && responseProfile.username) {
        dispatch(setUserProfile({ profile: responseProfile }))
      }
    }

    setModalOpen(false)
    setBackdropOpen(false)    
  }

  return (
    <>
      <Box sx={notificationStyles}>{notificationText}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SecondaryButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.confirm}</SecondaryButton>
      </Box>
      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default AccountStatusToggle