import React from 'react'
import { alpha } from '@mui/material'
import { CardHeadBox, CardLabelBox, CardStatusRoundedBox } from '../../themes/styles'

const DomainDetailsHead = ({ name, statusName, statusColor }) => {
  const statusStyles = {
    color: statusColor,
    border: `${statusColor} 1px solid`,
    background: alpha(statusColor, 0.2),
  }

  return (
    <CardHeadBox>
      <CardLabelBox>{name}</CardLabelBox>
      <CardStatusRoundedBox sx={statusStyles}>{statusName}</CardStatusRoundedBox>
    </CardHeadBox>
  )
}

export default DomainDetailsHead