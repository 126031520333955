import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import FormTextField from '../common/formFields/FormTextField'
import FormCheckboxField from '../common/formFields/FormCheckboxField'
import CircleIcon from '../common/icons/CircleIcon'

const DomainNameserversForm = ({ allowToggleGrenaNs, domainName, nameServersInitial, nameservers, setNameservers }) => {
  const texts = useSelector(state => state.settings.langTexts)

  const [useGrnenaNs, setUseGrenaNs] = useState(false)

  const handleChangeUseGrenaNs = (value) => {
    setUseGrenaNs(value)

    if (value) {
      setNameservers([
        { name: 'ns4.grena.ge', ip: '', showIp: false, },
        { name: 'ns5.grena.ge', ip: '', showIp: false, },
      ])
    } else {
      setNameservers([
        { name: '', ip: '', showIp: false, },
      ])
    }
  }

  const handleChangeNameservers = (value, props) => {
    const thisIndex = props.index ? props.index : '0'
    const thisKeyValue = props.keyValue ? props.keyValue : ''
    
    if (thisIndex && thisKeyValue) {
      let currentNameservers = [...nameservers]
      currentNameservers[thisIndex][thisKeyValue] = value
      setNameservers(currentNameservers)
    }
  }

  const handleAddNameserver = () => {
    setNameservers([...nameservers, { ...nameServersInitial }])
  }

  const handleRemoveNameserver = (index) => {
    let currentNameservers = [...nameservers]
    currentNameservers.splice(index, 1)
    setNameservers(currentNameservers)
  }

  return (
    <>
      {
        allowToggleGrenaNs && (
          <Box sx={{ marginBottom: '20px' }}>
            <FormCheckboxField
              label={texts.useGrenaDomainServersAndFreeDnsService}
              checked={useGrnenaNs}
              required={false}
              error={false}
              handleChange={handleChangeUseGrenaNs}
            />
          </Box>
        )
      }

      {
        nameservers.map((item, index) => (
          <Box key={index} sx={{ marginBottom: '20px' }}>
            <Grid container>
              <Grid lg={6}>
                <Box sx={{ marginRight: '10px' }}>
                  <FormTextField
                    type='text'
                    label={texts.nsRecord}
                    placeholder={texts.nsRecord}
                    value={item.name}
                    required={false}
                    error={false}
                    disabled={useGrnenaNs}
                    lines='1'
                    props={{ index, keyValue: 'name' }}
                    handleChange={handleChangeNameservers}
                  />
                </Box>
                {
                  item.name.endsWith(domainName) && (
                    <Box sx={{ marginTop: '5px' }}>
                      <FormCheckboxField
                        label={texts.setIpAddress}
                        checked={item.showIp}
                        required={false}
                        error={false}
                        props={{ index, keyValue: 'showIp' }}
                        handleChange={handleChangeNameservers}
                      />
                    </Box>
                  )
                }
              </Grid>

              {
                (item.name.endsWith(domainName) && item.showIp) && (
                  <Grid lg={5}>
                    <Box sx={{ marginLeft: '10px' }}>
                      <FormTextField
                        type='text'
                        label={texts.ipAddress}
                        placeholder={texts.ipAddress}
                        value={item.ip}
                        required={false}
                        error={false}
                        lines='1'
                        props={{ index, keyValue: 'ip' }}
                        handleChange={handleChangeNameservers}
                      />
                    </Box> 
                  </Grid>
                )
              }

              {
                !useGrnenaNs && (
                  <Grid lg={1}>
                    <Box sx={{ marginLeft: '10px' }}>
                      {
                        index > 0 ? (
                          <CircleIcon type='close' size='48' bg='royalOrange' action={() => handleRemoveNameserver(index)} />
                        ) : (
                          <CircleIcon type='add' size='48' bg='greenCrayola' action={handleAddNameserver} />
                        )
                      }
                    </Box>
                  </Grid>
                )
              }              
            </Grid>
          </Box>
        ))
      }
    </>
  )
}

export default DomainNameserversForm