import React from 'react'
import { useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'

const FormDatePickerField = ({
  label,
  value,
  required,
  error,
  props,
  fieldWidth,
  handleChange,
}) => {
  const theme = useTheme()
  
  const fieldStyles = {
    width: fieldWidth ? fieldWidth : '100%',
  }

  const labelStyles = {
    fontFamily: theme.typography.regular.fontFamily,
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>  
      <DatePicker
        label={label && <Box sx={labelStyles}>{label}</Box>}
        value={dayjs(value)}
        onChange={e => handleChange(e, props)}
        format='DD/MM/YYYY'
        sx={fieldStyles}
        slotProps={{
          textField: {
            required,
            error,
            InputProps: { sx: { borderRadius: '8px' }, }
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default FormDatePickerField