import React, { useState, useEffect } from 'react'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import CloseIcon from '@mui/icons-material/Close'

const ModalWindow = ({ modalOpen, modalTitle, modalContent, modalWidth, onModalClose }) => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [width, setWidth] = useState('')
  
  const modalContainerStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    position: 'absolute',
    maxHeight: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    background: 'white',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '10px 10px 30px rgba(0, 0, 0, 0.25)',
    padding: '20px 0px 20px 20px',
    display: 'flex',
    flexDirection: 'column',    

    '@media (max-width: 1199px)': {
      width: '90%',
    }
  }

  const modalHeadStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  }

  const modalTitleStyles = {
    fontSize: theme.typography.xlarge.fontSize,
    color: theme.palette.secondary.main,
    marginRight: '50px',
  }

  const modalCloseStyles = {
    marginRight: '20px',
    color: theme.palette.chineseSilver.main,
    cursor: 'pointer',

    '&:hover': {
      color: alpha(theme.palette.chineseSilver.main, 0.7),
    }
  }

  const modalBodyStyles = {
    overflowY: 'auto',
    padding: '10px 30px 0px 0px',
  }

  const handleClose = () => {
    setOpen(false)

    setTimeout(() => {
      setTitle('')
      setContent('')
      setWidth('')
    }, 500)

    if (onModalClose) {
      onModalClose()
    }
  }

  useEffect(() => {
    setOpen(modalOpen)
    setTitle(modalTitle)
    setContent(modalContent)
    setWidth(modalWidth)
  }, [modalOpen, modalTitle, modalContent, modalWidth])
  
  return (
    <Modal open={open} onClose={handleClose} disableScrollLock>
      <Fade in={open} timeout={500}>
        <Box sx={modalContainerStyles} maxWidth={width}>          
            <Box sx={modalHeadStyles}>
              <Box sx={modalTitleStyles}>{title}</Box>
              <Box sx={modalCloseStyles} onClick={handleClose}>
                <CloseIcon fontSize='large' />
              </Box>
            </Box>
            <Box sx={modalBodyStyles}>{content}</Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ModalWindow