import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { SecondaryButton } from '../../themes/styles'
import parse from 'html-react-parser'

const ModalNotification = ({ setModalOpen, notificationText }) => {
  const texts = useSelector(state => state.settings.langTexts)

  const handleSubmit = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Box>{parse(notificationText)}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '30px' }}>
        <SecondaryButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.close}</SecondaryButton>
      </Box>
    </>
  )
}

export default ModalNotification