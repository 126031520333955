import settings from '../config/settings'
import { setUserTokens, emptyUserData } from '../store/reducers/userData'
import { setWebpageTokens } from '../store/reducers/webpageData'

export const requestAuthByUsernameAndPassword = async (username, password) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=password&client_id=${settings.authUserID}&client_secret=${settings.authUserSecret}&username=${username}&password=${encodeURIComponent(password)}`,
  }
  
  const response = await fetch(settings.authURL, options)
  
  if (response.ok) {
    return await response.json()
  } else {
    return { error: true }
  }
}

export const requestAuthByRefreshToken = async (refreshToken) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=refresh_token&client_id=${settings.authUserID}&client_secret=${settings.authUserSecret}&refresh_token=${refreshToken}`,
  }
  
  const response = await fetch(settings.authURL, options)
  
  if (response.ok) {
    return await response.json()
  } else {
    return { error: true }
  }
}

export const requestAuthByClientCredentials = async () => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `grant_type=client_credentials&client_id=${settings.authClientID}&client_secret=${settings.authClientSecret}`,
  }
  
  const response = await fetch(settings.authURL, options)
  
  if (response.ok) {
    return await response.json()
  } else {
    return { error: true }
  }
}

export const request = async (url, method, data) => {
  const options = {
    method,
    headers: { 'Content-Type': 'application/json' },
  }

  if (method === 'POST') {
    options.body = JSON.stringify(data)
  }

  const response = await fetch(url, options)
  
  if (response.ok) {
    return await response.json()
  } else {
    return { error: true }
  }
}

export const requestClients = async (url, method, data, userData, dispatch, navigate) => {
  let options = {
    method,
    headers: { 'Content-Type': 'application/json' },
  }

  if (userData && userData.tokens && userData.tokens.accessToken) {
    options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userData.tokens.accessToken}` }
  }

  if (method === 'POST' || method === 'PUT') {
    options.body = JSON.stringify(data)
  }
  
  const response = await fetch(url, options)
  
  if (response.status === 401) {
    const auth = await requestAuthByRefreshToken(userData.tokens.refreshToken)

    if (auth.access_token) {
      const responseObject = {
        accessToken: auth.access_token,
        refreshToken: auth.refresh_token,
      }

      dispatch(setUserTokens({ tokens: responseObject }))
      
      options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.access_token}` }

      const responseRepeat = await fetch(url, options)

      if (responseRepeat.ok) {
        if (method === 'DELETE') {
          return true
        } else {
          return await responseRepeat.json()
        }
      } else {
        return await responseRepeat.json()
      }
    } else {
      dispatch(emptyUserData())
      navigate('/')
    }
  } else if (response.ok) {
    if (method === 'DELETE') {
      return true
    } else {
      return await response.json()
    }
  } else {
    return await response.json()
  }
}

export const requestWebpage = async (url, method, data, webpageData, dispatch, navigate) => {
  let options = {
    method,
    headers: { 'Content-Type': 'application/json' },
  }

  if (webpageData && webpageData.tokens && webpageData.tokens.accessToken) {
    options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${webpageData.tokens.accessToken}` }
  }

  if (method === 'POST' || method === 'PUT') {
    options.body = JSON.stringify(data)
  }
  
  const response = await fetch(url, options)
  
  if (response.status === 401) {
    const auth = await requestAuthByClientCredentials()

    if (auth.access_token) {
      const responseObject = {
        accessToken: auth.access_token,
      }

      dispatch(setWebpageTokens({ tokens: responseObject }))
      
      options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.access_token}` }

      const responseRepeat = await fetch(url, options)

      if (responseRepeat.ok) {
        if (method === 'DELETE') {
          return true
        } else {
          return await responseRepeat.json()
        }
      } else {
        return await responseRepeat.json()
      }
    } else {
      navigate('/')
    }
  } else if (response.ok) {
    if (method === 'DELETE') {
      return true
    } else {
      return await response.json()
    }
  } else {
    return await response.json()
  }
}

export const requestDomains = async (url, method, data, userData, dispatch, navigate) => {
  let options = {
    method,
    headers: { 'Content-Type': 'application/json' },
  }

  if (userData && userData.tokens && userData.tokens.accessToken) {
    options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userData.tokens.accessToken}` }
  }

  if (method === 'POST' || method === 'PUT') {
    options.body = JSON.stringify(data)
  }
  
  const response = await fetch(url, options)
  
  const responseJson = await response.json()
  
  if (responseJson && responseJson.status && responseJson.status === 401) {
    const auth = await requestAuthByRefreshToken(userData.tokens.refreshToken)

    if (auth.access_token) {
      const responseObject = {
        accessToken: auth.access_token,
        refreshToken: auth.refresh_token,
      }

      dispatch(setUserTokens({ tokens: responseObject }))
      
      options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.access_token}` }

      const responseRepeat = await fetch(url, options)

      const responseRepeatJson = await responseRepeat.json()

      if (responseRepeatJson && responseRepeatJson.status && responseRepeatJson.status === 401) {
        return false
      } else {
        return responseRepeatJson
      }
    } else {
      dispatch(emptyUserData())
      navigate('/')
    }
  } else {
    return responseJson
  }
}

export const requestDomainsUpload = async (url, method, data, userData, dispatch, navigate) => {
  let options = {
    method,
    headers: { 'Authorization': `Bearer ${userData.accessToken}` },
    body: data
  }
  
  const response = await fetch(url, options)
  
  const responseJson = await response.json()
  
  if (responseJson && responseJson.status && responseJson.status === 401) {
    const auth = await requestAuthByRefreshToken(userData.tokens.refreshToken)

    if (auth.access_token) {
      const responseObject = {
        accessToken: auth.access_token,
        refreshToken: auth.refresh_token,
      }

      dispatch(setUserTokens({ tokens: responseObject }))
      
      options.headers = { 'Authorization': `Bearer ${auth.access_token}` }

      const responseRepeat = await fetch(url, options)

      const responseRepeatJson = await responseRepeat.json()

      if (responseRepeatJson && responseRepeatJson.status && responseRepeatJson.status === 401) {
        return false
      } else {
        return responseRepeatJson
      }
    } else {
      dispatch(emptyUserData())
      navigate('/')
    }
  } else {
    return responseJson
  }
}