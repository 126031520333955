import React from 'react'
import DrawerElement from '../common/DrawerElement'
import VpsContent from './VpsContent'

const Vps = () => {
  return (
    <DrawerElement content={<VpsContent />} />
  )
}

export default Vps