import React from 'react'
import { useTheme } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { MuiFileInput } from 'mui-file-input'
import FormHelperText from '@mui/material/FormHelperText'

const FormFileField = ({
  label,
  placeholder,
  value,
  required,
  error,
  helpertext,
  props,
  fieldWidth,
  handleChange
}) => {
  const theme = useTheme()

  const labelStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    marginBottom: '3px',
  }

  const inputStyles = fieldWidth ? { width: fieldWidth } : {}

  return (
    <>
      <InputLabel error={error} sx={labelStyles}>{label} {required && '*'}</InputLabel>
      <MuiFileInput
        placeholder={placeholder}
        value={value}
        onChange={e => handleChange(e, props)}
        sx={inputStyles}
      />
      { helpertext && <FormHelperText>{helpertext}</FormHelperText> }
    </>
  )
}

export default FormFileField