import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/windowDimensions'
import VpsMenuItem from './VpsMenuItem'

const VpsMenu = (props) => {
  const { menu, pageName, setPageName } = props

  const theme = useTheme()
  const { width } = useWindowDimensions()

  const dividerStyle = {
    color: theme.palette.chineseSilver.main,
    margin: '0px 10px',
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: width >= 900 ? 'row' : 'column',
        alignItems: width >= 900 ? 'center' : 'flex-start',
        justifyContent: width >= 900 ? 'flex-end' : 'flex-start',
        margin: width >= 900 ? '10px' : '20px 0px',
      }}
    >
      {
        menu.map((item, index) => (
          <React.Fragment key={index}>
            <VpsMenuItem {...item} pageName={pageName} setPageName={setPageName} />
            {
              (index !== menu.length - 1 && width >= 900) && <Box sx={dividerStyle}>|</Box>
            }
          </React.Fragment>          
        ))
      }
    </Box>
  )
}

export default VpsMenu