const hostingList = [
  {
    title: 'myHosting',
    url: 'my',
  },
  {
    title: 'tariffs',
    url: 'tariffs',
  },
]

export default hostingList