import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'
import { LoginWrapperBox, PageLabelBox, CircleWarningBox, SecondaryButton } from '../../themes/styles'
import settings from '../../config/settings'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestWebpage } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { isValidPassword } from '../../helpers/string'
import FormTextField from '../common/formFields/FormTextField'
import FormGoogleReCaptcha from '../common/formFields/FormGoogleReCaptcha'
import BackdropElement from '../common/BackdropElement'

const PasswordResetConfirmForm = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userId, validationString } = useParams()

  const formWidth = width > settings.desktopBreakpoint ? '400px' : ''

  const submitButtonStyles = {
    width: width > settings.padBreakpoint ? 'auto' : '100%',
    marginTop: width > settings.padBreakpoint ? '0px' : '20px',
  }

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.error.main,
    fontSize: '14px',
  }

  const tooltipStyles = {
    fontFamily: theme.typography.regular.fontFamily,
  }

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: '16px',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const currentLang = useSelector(state => state.settings.currentLang)
  const webpageData = useSelector(state => state.webpageData)

  const captchaRef = useRef('')

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [captchaValidated, setCaptchaValidated] = useState(false)
  const [captchaValidationError, setCaptchaValidationError] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)

  const handleChangePassword = (value) => {
    setPassword(value)

    if (isValidPassword(value)) {
      setErrors((prev) => {
        return { ...prev, password: '' }
      })
    }
  }

  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value)

    if (value && value === password) {
      setErrors((prev) => {
        return { ...prev, confirmPassword: '' }
      })
    }
  }

  const googleCaptchaValidation = async (token) => {
    const response = await fetch(
      `/recaptcha/api/siteverify?secret=${settings.googleReCaptchaSecretKey}&response=${token}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', }
      }
    )
    
    if (!response.ok) {
      const resErrData = await response.json()
      console.log(resErrData)
    } else {
      const resData = await response.json()
      return resData.success
    }   
  }

  const sendRequest = async (postData) => {
    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getUsersPasswordResetConfirm
    
    const response = await requestWebpage(requestUrl, 'PUT', postData, webpageData, dispatch, navigate)
    
    setBackdropOpen(false)
    
    if (response && response.status && response.status === 'reset_completed') {
      navigate('/', { state: { PasswordResetConfirmOk: true } })
    } else if (response && response.detail && response.detail === 'User does not exist.') {
      setErrors((prev) => {
        return { ...prev, topNotification: texts.userNotExists }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, topNotification: texts.errorOccured }
      })
    }
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)
    let errorsObject = {}
    setCaptchaValidationError(false)

    if (!password) {
      errorsObject.password = texts.pleaseFillOut
    }

    if (password && !isValidPassword(password)) {
      errorsObject.password = texts.passwordRequirements
    }

    if (!confirmPassword) {
      errorsObject.confirmPassword = texts.pleaseFillOut
    }

    if (isValidPassword(password) && confirmPassword && password !== confirmPassword) {
      errorsObject.confirmPassword = texts.registerErrorConfirmPassword
    }

    const reCaptchaResponse = captchaValidated ? true : await googleCaptchaValidation(captchaRef.current.getValue())
    setCaptchaValidated(reCaptchaResponse ? true : false)
    
    if (Object.keys(errorsObject).length === 0 && reCaptchaResponse) {
      const postData = {
      user_id: userId,
      validation_string: validationString,
      password,
      confirm_password: confirmPassword,
      }

      sendRequest(postData)
    } else {
      setBackdropOpen(false)
    }
    
    if (!reCaptchaResponse) {
      setCaptchaValidationError(true)
    }

    setErrors(errorsObject)
  }

  return (
    <LoginWrapperBox>
      <Box sx={{ marginBottom: '30px' }}>
        <PageLabelBox>{texts.enterNewPassword}</PageLabelBox>
      </Box>
      
      {
        errors.topNotification && (
          <Box sx={{ width: formWidth, marginBottom: '20px' }}>
            <Alert severity='error' sx={alertStyles}>{errors.topNotification}</Alert>
          </Box>
        )
      }

      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between',  }}>
        <Box sx={{ position: 'relative', marginBottom: '20px' }}>
          <FormTextField
            type='password'
            label={texts.password}
            placeholder={texts.password}
            value={password}
            required={true}
            error={errors.password ? true : false}
            helpertext={errors.password && <Box sx={errorHelperStyles} component='span'>{errors.password}</Box>}
            lineNumber='1'
            handleChange={handleChangePassword}
            fieldWidth={formWidth}
          />
          <Box sx={{ position: 'absolute', right: '-30px', top: '16px' }}>
            <Tooltip placement='left' title={<Box sx={tooltipStyles} component='span'>{texts.passwordRequirements}</Box>}>
              <CircleWarningBox>i</CircleWarningBox>
            </Tooltip>
          </Box>
        </Box>        
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          type='password'
          label={texts.confirmPassword}
          placeholder={texts.confirmPassword}
          value={confirmPassword}
          required={true}
          error={errors.confirmPassword ? true : false}
          helpertext={errors.confirmPassword && <Box sx={errorHelperStyles} component='span'>{errors.confirmPassword}</Box>}
          lineNumber='1'
          handleChange={handleChangeConfirmPassword}
          fieldWidth={formWidth}
        />
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormGoogleReCaptcha
          captchaRef={captchaRef}
          lang={currentLang}
          error={captchaValidationError}
        />
      </Box>

      <SecondaryButton variant='contained' disableRipple={true} sx={submitButtonStyles} onClick={handleSubmit}>{texts.save}</SecondaryButton>

      <BackdropElement open={backdropOpen} />
    </LoginWrapperBox>
  )
}

export default PasswordResetConfirmForm