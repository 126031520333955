import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import settings from '../../config/settings'
import { apiServices } from '../../network/urls'

const InternetGraph = ({ graph_id }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [graphImageUrl, setGraphImageUrl] = useState('')

  useEffect(() => {
    const fetchGraphImage = async () => {
      const requestUrl = settings.domain + settings.clientsUi + apiServices.getGraphs + '/' + currentAccount + '/' + graph_id

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userData.tokens.accessToken}` },
      }
      
      const response = await fetch(requestUrl, requestOptions)
      
      if (response && response.blob) {
        const blob = await response.blob()
        const imageUrl = URL.createObjectURL(blob)
        setGraphImageUrl(imageUrl)
      } else {
        setGraphImageUrl('')
      }
    }

    fetchGraphImage()
  }, [userData, currentAccount, graph_id, dispatch, navigate])
  
  return (
    <Box sx={{ marginBottom: '20px' }}>
      { graphImageUrl && <img src={graphImageUrl} alt="" style={{ width: '90%' }} /> }
    </Box>
  )
}

export default InternetGraph