import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../../themes/styles'
import useWindowDimensions from '../../../helpers/windowDimensions'
import settings from '../../../config/settings'
import { request, requestClients } from '../../../network/request'
import { apiContentForms, apiHosting, apiUsers } from '../../../network/urls'
import { sortByPriority } from '../../../helpers/array'
import { isValidEmail } from '../../../helpers/string'
import { getAccountInfo, getSuperadminAccountInfo, getIsSuperadmin } from '../../../helpers/users'
import enTexts from '../../../lang/enTexts'
import kaTexts from '../../../lang/kaTexts'
import ContentFormItem from './ContentFormItem'
import BackdropElement from '../BackdropElement'

const ContentForm = ({ formName, default_package_name, handleCloseModal, handleOpenNotification }) => {
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width < 600 ? `${width - 110}px` : '600px'

  const texts = useSelector(state => state.settings.langTexts)
  const lang = useSelector(state => state.settings.currentLang)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const userData = useSelector(state => state.userData)

  const isSuperadmin = getIsSuperadmin(userData)
  
  const [formFields, setFormFields] = useState([])
  const [contentTypes, setContentTypes] = useState([])
  const [filled, setFilled] = useState([])
  const [errors, setErrors] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [superadminAccounts, setSuperadminAccounts] = useState([])
  const [account, setAccount] = useState('')

  const updateFilled = ({ field_id, priority, type, required, value }) => {
    let old = filled

    const oldObject = old.find(item => item.field_id === field_id)
    const tempArray = old.filter(item => item.field_id !== field_id)

    if (oldObject) {
      const newObject = { ...oldObject, value }
      setFilled(sortByPriority([...tempArray, newObject]))
    } else {
      tempArray.push({ field_id, value, priority, type, required  })
      setFilled(sortByPriority(tempArray))
    }
  }

  const updateContentTypes = ({ field_id, contentType }) => {
    let tempArray = contentTypes
    tempArray.push({ field_id, contentType })
    setContentTypes(tempArray)
  }

  const getDefaultValue = useCallback((content_type) => {
    if (content_type === 'email' && userData && userData.profile && userData.profile.email) {
      return userData.profile.email
    }

    if (content_type === 'company_name' && account && account.company_name) {
      return account.company_name
    }

    if (content_type === 'name' && account && account.name && account.lastname) {
      return `${account.name} ${account.lastname}`
    }

    if (content_type === 'hidden' && default_package_name) {
      return default_package_name
    }

    return null
  }, [userData, account, default_package_name])

  const sendRequest = async () => {
    const postData = { form_name: formName, data: filled }
    
    await request(settings.domain + settings.api + apiContentForms.getSubmitForm, 'POST', postData)

    handleCloseModal()
    handleOpenNotification({ title: texts.order, content: texts.contentFormSendOk })
  }

  const handleSubmit = async () => {
    setErrors([])
    let errorsArray = []
    
    filled.forEach(item => {
      const isEmailObject = contentTypes.find(item2 => (item2.field_id === item.field_id && item2.contentType === 'email'))
      // const isPhoneObject = contentTypes.find(item2 => (item2.field_id === item.field_id && item2.contentType === 'phone'))
      const isIDNumberObject = contentTypes.find(item2 => (item2.field_id === item.field_id && item2.contentType === 'id_number'))

      if (isEmailObject && !isValidEmail(item.value)) errorsArray.push(item.field_id)
      // if (isPhoneObject && !isValidPhone(item.value)) errorsArray.push(item.field_id)
      if (isIDNumberObject && item.value.length !== 11) errorsArray.push(item.field_id)

      if (item.required && item.type !== 'select' && !item.value) errorsArray.push(item.field_id)
      if (item.required && item.type === 'select' && (item.value === null || item.value.length === 0)) errorsArray.push(item.field_id)
    })
    
    setErrors(errorsArray)    
    
    if (errorsArray.length === 0 && filled.length > 0) {
      await sendRequest(filled)
    }
  }

  useEffect(() => {
    const fetchSuperadminAccounts = async () => {
      const requestUrl = settings.domain + settings.clientsUi + apiUsers.getAccounts

      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.length > 0) {
        setSuperadminAccounts(response)
      } else {
        setSuperadminAccounts([])
      }
    }

    if (isSuperadmin) {
      fetchSuperadminAccounts()
    }    
  }, [userData, isSuperadmin, dispatch, navigate])

  useEffect(() => {
    setAccount(isSuperadmin ? getSuperadminAccountInfo(currentAccount, superadminAccounts) : getAccountInfo(currentAccount, userData))
  }, [currentAccount, userData, superadminAccounts, isSuperadmin])

  useEffect(() => {
    const fecthOptionChoicesList = async () => {
      let result = []
      let endpoint = ''

      if (formName === 'contact_virtualization') {
        endpoint = apiHosting.getVpsPackages
      }

      if (formName === 'contact_hosting') {
        endpoint = apiHosting.getHostingPackages
      }

      const response = await request(settings.domain + settings.api + endpoint, 'GET')

      if (response && response.length > 0) {
        result = sortByPriority(response)
      }

      return result
    }

    const modifyFormFields = (form_fields) => {
      let result = []

      form_fields.forEach(ff => {
        if (account && account.account_type && account.account_type === 'company' && ff.options && ff.options.label  && ff.options.label.en && ff.options.label.en.toLowerCase().includes('full name')) {
          const updated_options = {
            ...ff.options,
            label: { en: enTexts.organizationName, ka: kaTexts.organizationName },
            placeholder: { en: enTexts.writeYourOrganizationName, ka: kaTexts.writeYourOrganizationName },
            content_type: 'company_name',
          }

          result.push({ ...ff, options: updated_options })
        } else if (ff.options && ff.options.label  && ff.options.label.en && ff.options.label.en.toLowerCase().includes('full name')) {
          const updated_options = {
            ...ff.options,
            content_type: 'name',
          }

          result.push({ ...ff, options: updated_options })
        } else if (ff.options && ff.options.content_type && ff.options.content_type === 'phone') {
          result.push({ ...ff, required: false })
        } else {
          result.push(ff)
        }
      })

      return result
    }

    const fetchFormFields = async () => {      
      const response_url = settings.domain + settings.api + apiContentForms.getFormFields + '/?param_name=form_name&param_value=' + formName

      const response = await request(response_url, 'GET')
      
      if (response && response.length > 0) {
        let form_fields = sortByPriority(response)

        for (let index = 0; index < form_fields.length; index++) {
          const item = form_fields[index]
          const field_endpoint = 'content-' + item.type + '-fields'
          const field_response_url = settings.domain + settings.api + field_endpoint + '/' + item.field_id

          const field_response = await request(field_response_url, 'GET')

          if (field_response && field_response.id) {
            let choices = []

            if (field_response.content_type && field_response.content_type === 'hidden') {
              const choice_list = await fecthOptionChoicesList()
              choice_list.forEach(item => {
                choices.push({
                  priority: item.priority,
                  title: item.name,
                  value: item.name[lang],
                })
              })
            }

            form_fields[index].options = field_response
            form_fields[index].options.choices = choices
          }
        }
        
        setFormFields(modifyFormFields(form_fields))
      } else {
        setFormFields([])        
      }

      setBackdropOpen(false)
    }
    
    fetchFormFields()
  }, [formName, lang, account])

  useEffect(() => {
    let filledArray = []

    formFields.forEach(item => {
      let object = {
        field_id: item.field_id,
        value: getDefaultValue(item.options.content_type),
        priority: item.priority,
        type: item.type,
        required: item.required,
      }

      filledArray.push(object)  
    })

    setFilled(filledArray)
  }, [formFields, getDefaultValue])
  
  return (
    <Box sx={{ minWidth: formWidth }}>
      {
        formFields.map((item, index) => (
          <ContentFormItem
            key={index}
            {...item}
            filled={filled}
            updateFilled={updateFilled}
            updateContentTypes={updateContentTypes}
            errors={errors}
            formWidth={formWidth}
          />
        ))
      }

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.send}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />
    </Box>
  )
}

export default ContentForm