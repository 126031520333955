import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import { SubtitleBox, SuccessButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestDomains, requestDomainsUpload } from '../../network/request'
import {
  getIfDomianNeedsProve,
  isValidEmail,
  isValidPhone,
  isValidPersonalNumber,
  isValidCompanyCode,
  getNameFromFullName,
  getLastnameFromFullName,
} from '../../helpers/string'
import { getAccountInfo, getIsSuperadmin } from '../../helpers/users'
import DomainContactForm from './DomainContactForm'
import DomainGeoContactForm from './DomainGeoContactForm'
import DomainFileForm from './DomainFileForm'
import DomainNameserversForm from './DomainNameserversForm'
import DomainAgreementForm from './DomainAgreementForm'
import BackdropElement from '../common/BackdropElement'

const DomainRegistrationForm = ({
  setModalOpen,
  domainName,
  domainType,
  lastDomainUid,
  extList,
  eppCode,
  action,
  handleOpenNotification,
  setUpdate,
}) => {
  const theme = useTheme()
  
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width < 900 ? `${width - 130}px` : '900px'

  const userData = useSelector(state => state.userData)
  const lang = useSelector(state => state.settings.currentLang)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)
  const currentAccountType = useSelector(state => state.settings.currentAccountType)

  const isSuperadmin = getIsSuperadmin(userData)
  const accountInfo = getAccountInfo(currentAccount, userData)  
  const isCompany = ( (isSuperadmin && currentAccountType === 'company') || (!isSuperadmin && accountInfo.account_type && accountInfo.account_type === 'company') ) ? true : false

  const nameServersInitial = { name: '', ip: '', showIp: false, }

  const [specialDomainNotification, setSpecialDomainNotification] = useState('')
  const [specialDomainFileUpload, setSpecialDomainFileUpload] = useState(false)

  const [registrantName, setRegistrantName] = useState('')
  const [registrantNameEng, setRegistrantNameEng] = useState('')
  const [registrantLastname, setRegistrantLastname] = useState('')
  const [registrantLastnameEng, setRegistrantLastnameEng] = useState('')
  const [registrantOrg, setRegistrantOrg] = useState('')
  const [registrantOrgEng, setRegistrantOrgEng] = useState('')
  const [registrantAddress, setRegistrantAddress] = useState('')
  const [registrantAddressEng, setRegistrantAddressEng] = useState('')
  const [registrantEmail, setRegistrantEmail] = useState('')
  const [registrantPhone, setRegistrantPhone] = useState('')
  const [registrantPersonal, setRegistrantPersonal] = useState('')

  const [adminName, setAdminName] = useState('')
  const [adminNameEng, setAdminNameEng] = useState('')
  const [adminLastname, setAdminLastname] = useState('')
  const [adminLastnameEng, setAdminLastnameEng] = useState('')
  const [adminOrg, setAdminOrg] = useState('')
  const [adminOrgEng, setAdminOrgEng] = useState('')
  const [adminAddress, setAdminAddress] = useState('')
  const [adminAddressEng, setAdminAddressEng] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const [adminPhone, setAdminPhone] = useState('')
  const [adminPersonal, setAdminPersonal] = useState('')

  const [techName, setTechName] = useState('')
  const [techNameEng, setTechNameEng] = useState('')
  const [techLastname, setTechLastname] = useState('')
  const [techLastnameEng, setTechLastnameEng] = useState('')
  const [techOrg, setTechOrg] = useState('')
  const [techOrgEng, setTechOrgEng] = useState('')
  const [techAddress, setTechAddress] = useState('')
  const [techAddressEng, setTechAddressEng] = useState('')
  const [techEmail, setTechEmail] = useState('')
  const [techPhone, setTechPhone] = useState('')
  const [techPersonal, setTechPersonal] = useState('')

  const [proveDocument, setProveDocument] = useState('')
  const [nameservers, setNameservers] = useState([{ ...nameServersInitial }])
  const [agree, setAgree] = useState(false)
  const [errors, setErrors] = useState({})
  const [backdropOpen, setBackdropOpen] = useState(true)

  const infoIconStyles = {
    color: theme.palette.royalOrange.main,
    marginRight: '5px',
  }

  const scrollToId = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const sendRequest = async (postDataInitial) => {
    let fileUid = ''

    if (specialDomainFileUpload && proveDocument) {
      const formData = new FormData()
          
      formData.append('proveDocument', proveDocument)

      const responseUpload = await requestDomainsUpload(settings.fetchDomains + `/?action=client-upload-file&account_id=${currentAccount}`, 'POST', formData, userData, dispatch, navigate)
      
      if (responseUpload && responseUpload.status && responseUpload.status === 100 && responseUpload.data) {
        fileUid = responseUpload.data
      }
    }

    const postData = { ...postDataInitial, fileUid, }
    const requestAction = domainType === 'eng' ? 'client-domain-add' : 'client-domain-geo-add'

    const response = await requestDomains(settings.fetchDomains + `/?action=${requestAction}&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
    
    setBackdropOpen(false)

    const modalTitle = action === 'transfer' ? domainName + texts._domainTransfer : domainName + texts._domainRegistration
    
    if (response && response.status && response.status === 100) {
      setModalOpen(false)
      setUpdate(prev => prev + 1)

      let notificationText = domainType === 'eng' ? texts.domainAddOk : texts.domainGeoAddOk
      notificationText = notificationText.replaceAll('###DOMAIN###', domainName)
      notificationText = notificationText.replace('###EMAIL###', registrantEmail)
      const modalContent = notificationText

      handleOpenNotification({
        title: modalTitle,
        content: modalContent,
        size: 'lg',
      })
    } else {
      setModalOpen(false)

      handleOpenNotification({
        title: modalTitle,
        content: texts.errorOccured,
      })
    }
  }

  const handleSubmit = async () => {
    setBackdropOpen(true)
    let errorsObject = {}

    if (!isCompany && !registrantName) {
      errorsObject.registrantName = texts.pleaseFillOut
    }

    if (!isCompany && !registrantNameEng && domainType === 'eng') {
      errorsObject.registrantNameEng = texts.pleaseFillOut
    }

    if (!isCompany && !registrantLastname) {
      errorsObject.registrantLastname = texts.pleaseFillOut
    }

    if (!isCompany && !registrantLastnameEng && domainType === 'eng') {
      errorsObject.registrantLastnameEng = texts.pleaseFillOut
    }

    if (isCompany && !registrantOrg) {
      errorsObject.registrantOrg = texts.pleaseFillOut
    }

    if (isCompany && !registrantOrgEng && domainType === 'eng') {
      errorsObject.registrantOrgEng = texts.pleaseFillOut
    }

    if (!registrantAddress) {
      errorsObject.registrantAddress = texts.pleaseFillOut
    }

    if (!registrantAddressEng && domainType === 'eng') {
      errorsObject.registrantAddressEng = texts.pleaseFillOut
    }

    if (!registrantEmail) {
      errorsObject.registrantEmail = texts.pleaseFillOut
    }

    if (registrantEmail && !isValidEmail(registrantEmail)) {
      errorsObject.registrantEmail = texts.registerErrorEmail
    }

    if (!registrantPhone) {
      errorsObject.registrantPhone = texts.pleaseFillOut
    }

    if (registrantPhone && !isValidPhone(registrantPhone)) {
      errorsObject.registrantPhone = texts.errorPhone
    }

    if (!registrantPersonal) {
      errorsObject.registrantPersonal = texts.pleaseFillOut
    }

    if (!isCompany && registrantPersonal && !isValidPersonalNumber(registrantPersonal)) {
      errorsObject.registrantPersonal = texts.errorPersonalId
    }

    if (isCompany && registrantPersonal && !isValidCompanyCode(registrantPersonal)) {
      errorsObject.registrantPersonal = texts.errorCompanyId
    }

    if (isCompany && !adminName) {
      errorsObject.adminName = texts.pleaseFillOut
    }

    if (isCompany && !adminNameEng && domainType === 'eng') {
      errorsObject.adminNameEng = texts.pleaseFillOut
    }

    if (isCompany && !adminLastname) {
      errorsObject.adminLastname = texts.pleaseFillOut
    }

    if (isCompany && !adminLastnameEng && domainType === 'eng') {
      errorsObject.adminLastnameEng = texts.pleaseFillOut
    }

    if (isCompany && !adminAddress && domainType === 'eng') {
      errorsObject.adminAddress = texts.pleaseFillOut
    }

    if (isCompany && !adminAddressEng && domainType === 'eng') {
      errorsObject.adminAddressEng = texts.pleaseFillOut
    }

    if (isCompany && !adminEmail) {
      errorsObject.adminEmail = texts.pleaseFillOut
    }

    if (isCompany && adminEmail && !isValidEmail(adminEmail)) {
      errorsObject.adminEmail = texts.registerErrorEmail
    }

    if (isCompany && !adminPhone) {
      errorsObject.adminPhone = texts.pleaseFillOut
    }

    if (isCompany && adminPhone && !isValidPhone(adminPhone)) {
      errorsObject.adminPhone = texts.errorPhone
    }

    if (isCompany && !adminPersonal && domainType === 'eng') {
      errorsObject.adminPersonal = texts.pleaseFillOut
    }

    if (isCompany && adminPersonal && !isValidPersonalNumber(adminPersonal) && domainType === 'eng') {
      errorsObject.adminPersonal = texts.errorPersonalId
    }

    if (isCompany && !techName && domainType === 'eng') {
      errorsObject.techName = texts.pleaseFillOut
    }

    if (isCompany && !techNameEng && domainType === 'eng') {
      errorsObject.techNameEng = texts.pleaseFillOut
    }

    if (isCompany && !techLastname && domainType === 'eng') {
      errorsObject.techLastname = texts.pleaseFillOut
    }

    if (isCompany && !techLastnameEng && domainType === 'eng') {
      errorsObject.techLastnameEng = texts.pleaseFillOut
    }

    if (isCompany && !techAddress && domainType === 'eng') {
      errorsObject.techAddress = texts.pleaseFillOut
    }

    if (isCompany && !techAddressEng && domainType === 'eng') {
      errorsObject.techAddressEng = texts.pleaseFillOut
    }

    if (isCompany && !techEmail && domainType === 'eng') {
      errorsObject.techEmail = texts.pleaseFillOut
    }

    if (isCompany && techEmail && !isValidEmail(techEmail) && domainType === 'eng') {
      errorsObject.techEmail = texts.registerErrorEmail
    }

    if (isCompany && !techPhone && domainType === 'eng') {
      errorsObject.techPhone = texts.pleaseFillOut
    }

    if (isCompany && techPhone && !isValidPhone(techPhone) && domainType === 'eng') {
      errorsObject.techPhone = texts.errorPhone
    }

    if (isCompany && !techPersonal && domainType === 'eng') {
      errorsObject.techPersonal = texts.pleaseFillOut
    }

    if (isCompany && techPersonal && !isValidPersonalNumber(techPersonal) && domainType === 'eng') {
      errorsObject.techPersonal = texts.errorPersonalId
    }

    if (!agree) {
      errorsObject.agree = texts.pleaseCheck
    }

    if (specialDomainFileUpload && !proveDocument && domainType === 'eng') {
      errorsObject.proveDocument = texts.pleaseUploadFile
    }

    if (Object.keys(errorsObject).length === 0) {
      const postDataInitial = {
        domain: domainName,
        isCompany,
        registrantName,
        registrantNameEng,
        registrantLastname,
        registrantLastnameEng,
        registrantOrg,
        registrantOrgEng,
        registrantAddress,
        registrantAddressEng,
        registrantEmail,
        registrantPhone,
        registrantPersonal,
        adminName,
        adminNameEng,
        adminLastname,
        adminLastnameEng,
        adminOrg,
        adminOrgEng,
        adminAddress,
        adminAddressEng,
        adminEmail,
        adminPhone,
        adminPersonal,
        techName,
        techNameEng,
        techLastname,
        techLastnameEng,
        techOrg,
        techOrgEng,
        techAddress,
        techAddressEng,
        techEmail,
        techPhone,
        techPersonal,
        nameservers: nameservers.filter(item => item.name !== ''),
        eppCode: eppCode ? eppCode : '',
      }

      await sendRequest(postDataInitial)
    } else {
      setBackdropOpen(false)
    }
    
    setErrors(errorsObject)

    if (Object.keys(errorsObject).length > 0) {
      scrollToId('formStart')
    }    
  }

  useEffect(() => {
    const needsProve = getIfDomianNeedsProve(domainName)
    
    if (needsProve && extList.length > 0) {
      const extObject = extList.find(item => item.value === needsProve)

      if (extObject) {
        setSpecialDomainNotification(needsProve + ' ' + extObject.info_text[lang])

        if (needsProve === '.org.ge') {
          setSpecialDomainFileUpload(false)
        } else {
          setSpecialDomainFileUpload(true)
        }
      } else {
        setSpecialDomainNotification('')
        setSpecialDomainFileUpload(false)
      }
    } else {
      setSpecialDomainNotification('')
      setSpecialDomainFileUpload(false)
    }
  }, [domainName, extList, lang])

  useEffect(() => {
    const fetchDomainDetails = async () => {
      setBackdropOpen(true)

      const requestUrl = settings.fetchDomains + `/?action=client-domain-details&account_id=${currentAccount}&domain_uid=${lastDomainUid}&domain_type=${domainType}`

      const response = await requestDomains(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.status && response.status === 100) {
        if (response.data && response.data.registrant) {
          setRegistrantName(response.data.registrant.name ? response.data.registrant.name : '')
          setRegistrantNameEng(response.data.registrant.name_eng ? response.data.registrant.name_eng : '')
          setRegistrantLastname(response.data.registrant.lastname ? response.data.registrant.lastname : '')
          setRegistrantLastnameEng(response.data.registrant.lastname_eng ? response.data.registrant.lastname_eng : '')
          setRegistrantOrg(response.data.registrant.org ? response.data.registrant.org : '')
          setRegistrantOrgEng(response.data.registrant.org_eng ? response.data.registrant.org_eng : '')
          setRegistrantAddress(response.data.registrant.address)
          setRegistrantAddressEng(response.data.registrant.address_eng)
          setRegistrantEmail(response.data.registrant.email)
          setRegistrantPhone(response.data.registrant.phone)
          setRegistrantPersonal(response.data.registrant.personal)
        }

        if (response.data && response.data.admin && isCompany) {
          setAdminName(response.data.admin.name)
          setAdminNameEng(response.data.admin.name_eng)
          setAdminLastname(response.data.admin.lastname)
          setAdminLastnameEng(response.data.admin.lastname_eng)
          setAdminOrg(response.data.admin.org)
          setAdminOrgEng(response.data.admin.org_eng)
          setAdminAddress(response.data.admin.address)
          setAdminAddressEng(response.data.admin.address_eng)
          setAdminEmail(response.data.admin.email)
          setAdminPhone(response.data.admin.phone)
          setAdminPersonal(response.data.admin.personal)
        }

        if (response.data && response.data.tech && isCompany) {
          setTechName(response.data.tech.name)
          setTechNameEng(response.data.tech.name_eng)
          setTechLastname(response.data.tech.lastname)
          setTechLastnameEng(response.data.tech.lastname_eng)
          setTechOrg(response.data.tech.org)
          setTechOrgEng(response.data.tech.org_eng)
          setTechAddress(response.data.tech.address)
          setTechAddressEng(response.data.tech.address_eng)
          setTechEmail(response.data.tech.email)
          setTechPhone(response.data.tech.phone)
          setTechPersonal(response.data.tech.personal)
        }
      } else {
        setRegistrantName('')
        setRegistrantNameEng('')
        setRegistrantLastname('')
        setRegistrantLastnameEng('')
        setRegistrantOrg('')
        setRegistrantOrgEng('')
        setRegistrantAddress('')
        setRegistrantAddressEng('')
        setRegistrantEmail('')
        setRegistrantPhone('')
        setRegistrantPersonal('')

        setAdminName('')
        setAdminNameEng('')
        setAdminLastname('')
        setAdminLastnameEng('')
        setAdminOrg('')
        setAdminOrgEng('')
        setAdminAddress('')
        setAdminAddressEng('')
        setAdminEmail('')
        setAdminPhone('')
        setAdminPersonal('')

        setTechName('')
        setTechNameEng('')
        setTechLastname('')
        setTechLastnameEng('')
        setTechOrg('')
        setTechOrgEng('')
        setTechAddress('')
        setTechAddressEng('')
        setTechEmail('')
        setTechPhone('')
        setTechPersonal('')
      }

      setBackdropOpen(false)
    }

    const fetchDomainCaucasusInfo = async () => {
      setBackdropOpen(true)

      const requestUrl = settings.fetchDomains + `/?action=client-domain-caucasus-info&account_id=${currentAccount}`

      const postData = { domainName, eppCode }

      const response = await requestDomains(requestUrl, 'POST', postData, userData, dispatch, navigate)
      
      if (response && response.status && response.status === 100) {
        if (response.data && response.data.registrants) {
          setRegistrantName('')
          setRegistrantNameEng(response.data.registrants.name ? getNameFromFullName(response.data.registrants.name) : '')
          setRegistrantLastname('')
          setRegistrantLastnameEng(response.data.registrants.name ? getLastnameFromFullName(response.data.registrants.name) : '')
          setRegistrantOrg('')
          setRegistrantOrgEng(response.data.registrants.org ? response.data.registrants.org : '')
          setRegistrantAddress('')
          setRegistrantAddressEng(response.data.registrants.street)
          setRegistrantEmail(response.data.registrants.email)
          setRegistrantPhone(response.data.registrants.phone)
          setRegistrantPersonal(response.data.registrants.idnumber)
        }

        if (response.data && response.data.admins && isCompany) {
          setAdminName('')
          setAdminNameEng(response.data.admins.name ? getNameFromFullName(response.data.admins.name) : '')
          setAdminLastname('')
          setAdminLastnameEng(response.data.admins.name ? getLastnameFromFullName(response.data.admins.name) : '')
          setAdminOrg('')
          setAdminOrgEng(response.data.admins.org)
          setAdminAddress('')
          setAdminAddressEng(response.data.admins.street)
          setAdminEmail(response.data.admins.email)
          setAdminPhone(response.data.admins.phone)
          setAdminPersonal(response.data.admins.idnumber)
        }

        if (response.data && response.data.techs && isCompany) {
          setTechName('')
          setTechNameEng(response.data.techs.name ? getNameFromFullName(response.data.techs.name) : '')
          setTechLastname('')
          setTechLastnameEng(response.data.techs.name ? getLastnameFromFullName(response.data.techs.name) : '')
          setTechOrg('')
          setTechOrgEng(response.data.techs.org)
          setTechAddress('')
          setTechAddressEng(response.data.techs.street)
          setTechEmail(response.data.techs.email)
          setTechPhone(response.data.techs.phone)
          setTechPersonal(response.data.techs.idnumber)
        }

        if (response.data && response.data.hosts && response.data.hosts.length > 0) {
          let _nameservers = []

          response.data.hosts.forEach(host => {
            _nameservers.push({
              name: host.hostname,
              ip: host.hostip,
              showIp: host.hostip ? true : false,
            })
          })

          setNameservers(_nameservers)
        }
      } else {
        setRegistrantName('')
        setRegistrantNameEng('')
        setRegistrantLastname('')
        setRegistrantLastnameEng('')
        setRegistrantOrg('')
        setRegistrantOrgEng('')
        setRegistrantAddress('')
        setRegistrantAddressEng('')
        setRegistrantEmail('')
        setRegistrantPhone('')
        setRegistrantPersonal('')

        setAdminName('')
        setAdminNameEng('')
        setAdminLastname('')
        setAdminLastnameEng('')
        setAdminOrg('')
        setAdminOrgEng('')
        setAdminAddress('')
        setAdminAddressEng('')
        setAdminEmail('')
        setAdminPhone('')
        setAdminPersonal('')

        setTechName('')
        setTechNameEng('')
        setTechLastname('')
        setTechLastnameEng('')
        setTechOrg('')
        setTechOrgEng('')
        setTechAddress('')
        setTechAddressEng('')
        setTechEmail('')
        setTechPhone('')
        setTechPersonal('')
      }

      setBackdropOpen(false)
    }

    if (lastDomainUid) {
      fetchDomainDetails()
    } else if (eppCode) {
      fetchDomainCaucasusInfo()
    } else {
      setBackdropOpen(false)
    } 
  }, [userData, dispatch, navigate, lastDomainUid, eppCode, domainName, currentAccount, domainType, isCompany])
  
  return (
    <Box sx={{ minWidth: formWidth }} id='formStart'>

      {
        specialDomainNotification && (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: '40px' }}>
            <Box sx={infoIconStyles}>
              <InfoIcon />
            </Box>
            <Box>{specialDomainNotification}</Box>
          </Box>
        )
      }

      {
        domainType === 'eng' && (
          <>
            <DomainContactForm
              contactType='registrant'
              isCompany={isCompany}
              name={registrantName}
              setName={setRegistrantName}
              nameEng={registrantNameEng}
              setNameEng={setRegistrantNameEng}
              lastname={registrantLastname}
              setLastname={setRegistrantLastname}
              lastnameEng={registrantLastnameEng}
              setLastnameEng={setRegistrantLastnameEng}
              org={registrantOrg}
              setOrg={setRegistrantOrg}
              orgEng={registrantOrgEng}
              setOrgEng={setRegistrantOrgEng}
              address={registrantAddress}
              setAddress={setRegistrantAddress}
              addressEng={registrantAddressEng}
              setAddressEng={setRegistrantAddressEng}
              email={registrantEmail}
              setEmail={setRegistrantEmail}
              phone={registrantPhone}
              setPhone={setRegistrantPhone}
              personal={registrantPersonal}
              setPersonal={setRegistrantPersonal}
              errors={errors}
              setErrors={setErrors}
            />

            {
              isCompany && (
                <>
                  <Box sx={{ margin: '20px 0px', textAlign: 'center' }}>
                    <SubtitleBox>{texts.administrator}</SubtitleBox>
                  </Box>

                  <DomainContactForm
                    contactType='admin'
                    isCompany={isCompany}
                    name={adminName}
                    setName={setAdminName}
                    nameEng={adminNameEng}
                    setNameEng={setAdminNameEng}
                    lastname={adminLastname}
                    setLastname={setAdminLastname}
                    lastnameEng={adminLastnameEng}
                    setLastnameEng={setAdminLastnameEng}
                    org={adminOrg}
                    setOrg={setAdminOrg}
                    orgEng={adminOrgEng}
                    setOrgEng={setAdminOrgEng}
                    address={adminAddress}
                    setAddress={setAdminAddress}
                    addressEng={adminAddressEng}
                    setAddressEng={setAdminAddressEng}
                    email={adminEmail}
                    setEmail={setAdminEmail}
                    phone={adminPhone}
                    setPhone={setAdminPhone}
                    personal={adminPersonal}
                    setPersonal={setAdminPersonal}
                    errors={errors}
                    setErrors={setErrors}
                  />

                  <Box sx={{ margin: '20px 0px', textAlign: 'center' }}>
                    <SubtitleBox>{texts.technicalPerson}</SubtitleBox>
                  </Box>

                  <DomainContactForm
                    contactType='tech'
                    isCompany={isCompany}
                    name={techName}
                    setName={setTechName}
                    nameEng={techNameEng}
                    setNameEng={setTechNameEng}
                    lastname={techLastname}
                    setLastname={setTechLastname}
                    lastnameEng={techLastnameEng}
                    setLastnameEng={setTechLastnameEng}
                    org={techOrg}
                    setOrg={setTechOrg}
                    orgEng={techOrgEng}
                    setOrgEng={setTechOrgEng}
                    address={techAddress}
                    setAddress={setTechAddress}
                    addressEng={techAddressEng}
                    setAddressEng={setTechAddressEng}
                    email={techEmail}
                    setEmail={setTechEmail}
                    phone={techPhone}
                    setPhone={setTechPhone}
                    personal={techPersonal}
                    setPersonal={setTechPersonal}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </>
              )
            }  

            {
              specialDomainFileUpload && (
                <>
                  <Box sx={{ margin: '20px 0px', textAlign: 'center' }}>
                    <SubtitleBox>{texts.organizationTypeVerificationDocument}</SubtitleBox>
                  </Box>

                  <DomainFileForm
                    proveDocument={proveDocument}
                    setProveDocument={setProveDocument}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </>
              )
            }
          </>
        )
      }

      {
        domainType === 'geo' && (
          <>
            <DomainGeoContactForm
              contactType='registrant'
              isCompany={isCompany}
              name={registrantName}
              setName={setRegistrantName}
              lastname={registrantLastname}
              setLastname={setRegistrantLastname}
              org={registrantOrg}
              setOrg={setRegistrantOrg}
              address={registrantAddress}
              setAddress={setRegistrantAddress}
              email={registrantEmail}
              setEmail={setRegistrantEmail}
              phone={registrantPhone}
              setPhone={setRegistrantPhone}
              personal={registrantPersonal}
              setPersonal={setRegistrantPersonal}
              errors={errors}
              setErrors={setErrors}
            />

            {
              isCompany && (
                <>
                  <Box sx={{ margin: '20px 0px', textAlign: 'center' }}>
                    <SubtitleBox>{texts.administrator}</SubtitleBox>
                  </Box>

                  <DomainGeoContactForm
                    contactType='admin'
                    isCompany={isCompany}
                    name={adminName}
                    setName={setAdminName}
                    lastname={adminLastname}
                    setLastname={setAdminLastname}
                    org={adminOrg}
                    setOrg={setAdminOrg}
                    address={adminAddress}
                    setAddress={setAdminAddress}
                    email={adminEmail}
                    setEmail={setAdminEmail}
                    phone={adminPhone}
                    setPhone={setAdminPhone}
                    personal={adminPersonal}
                    setPersonal={setAdminPersonal}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </>
              )
            }
          </>
        )
      }

      {
        !eppCode &&
        <>
          <Box sx={{ margin: '20px 0px', textAlign: 'center' }}>
            <SubtitleBox>{texts.nsServers}</SubtitleBox>
          </Box>

          <DomainNameserversForm
            allowToggleGrenaNs={true}
            domainName={domainName}
            nameServersInitial={nameServersInitial}
            nameservers={nameservers}
            setNameservers={setNameservers}
          />
        </>
      }
      

      <DomainAgreementForm
        domainType={domainType}
        agree={agree}
        setAgree={setAgree}
        errors={errors}
        setErrors={setErrors}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{eppCode ? texts.transfer : texts.registration}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default DomainRegistrationForm