import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IconWhiteBox, IconPrimaryBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import LogoutIcon from '@mui/icons-material/Logout'
import { emptyUserData } from '../../store/reducers/userData'

const LogoutElement = () => {
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(emptyUserData())
    navigate('/')
  }

  return (
    <>
      {
        width >= 900 ?
        <IconWhiteBox onClick={handleLogout}>
          <LogoutIcon fontSize='large' />
        </IconWhiteBox> :
        <IconPrimaryBox onClick={handleLogout}>
          <LogoutIcon fontSize='large' />
        </IconPrimaryBox>
      }
    </>    
  )
}

export default LogoutElement