import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import useWindowDimensions from '../../../helpers/windowDimensions'
import settings from '../../../config/settings'
import ReCAPTCHA from 'react-google-recaptcha'

const FormGoogleReCaptcha = ({ captchaRef, lang, error }) => {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const helperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.regular.fontSize,
    color: theme.palette.error.main,    
  }

  const texts = useSelector(state => state.settings.langTexts)

  return (
    <>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={settings.googleReCaptchaSiteKey}
        hl={lang}
        size={ width > settings.padBreakpoint ? 'normal' : 'compact' }
      />
      { error && <FormHelperText style={helperStyles}>{texts.reCaptchaError}</FormHelperText> }
    </>
  )
}

export default FormGoogleReCaptcha