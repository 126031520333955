import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiServices } from '../../network/urls'
import VpsHead from './VpsHead'
import VpsMyContent from './VpsMyContent'
import VpsTariffsContent from './VpsTariffsContent'
import BackdropElement from '../common/BackdropElement'

const VpsContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [pageName, setPageName] = useState('')
  const [virtualMachines, setVirtualMachines] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)


  useEffect(() => {
    (async () => {
      const requestUrl = settings.domain + settings.clientsUi + apiServices.getVirtualMachines + '/' + currentAccount

      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.length > 0) {
        setVirtualMachines(response)
        setPageName('my')
      } else {
        setVirtualMachines([])
        setPageName('tariffs')
      }

      setBackdropOpen(false)
    })()

    setTimeout(() => setBackdropOpen(false), 10000)
  }, [userData, currentAccount, dispatch, navigate])
  
  return (
    <>
      <VpsHead pageName={pageName} setPageName={setPageName} />

      { pageName === 'my' && <VpsMyContent virtualMachines={virtualMachines} backdropOpen={backdropOpen} /> }
      { pageName === 'tariffs' && <VpsTariffsContent /> }

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default VpsContent