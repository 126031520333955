import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import useWindowDimensions from '../../helpers/windowDimensions'

const CardElement = ({ open, setOpen, content, setContent, maxWidth }) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const drawerStyles = {
    '&.MuiDrawer-root .MuiDrawer-paper': { marginTop: '60px', paddingBottom: '60px' },
  }

  const contentStyles = {
    fontFamily: theme.typography.georgian.fontFamily,
    padding: '20px',
    width: (maxWidth && maxWidth < width * 0.9) ? `${maxWidth}px` : `${width * 0.9}px`
  }

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      setContent('')
    }, 500)
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      sx={drawerStyles}
    >
      <Box sx={contentStyles}>{content}</Box>
    </Drawer>
  )
}

export default CardElement