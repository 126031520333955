import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiServices } from '../../network/urls'
import HostingHead from './HostingHead'
import HostingMyContent from './HostingMyContent'
import HostingTariffsContent from './HostingTariffsContent'
import BackdropElement from '../common/BackdropElement'

const HostingContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [pageName, setPageName] = useState('')
  const [hostingDomains, setHostingDomains] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)

  useEffect(() => {
    (async () => {
      const requestUrl = settings.domain + settings.clientsUi + apiServices.getHostingDomains + '/' + currentAccount

      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.length > 0) {
        setHostingDomains(response)
        setPageName('my')
      } else {
        setHostingDomains([])
        setPageName('tariffs')
      }

      setBackdropOpen(false)
    })()

    setTimeout(() => setBackdropOpen(false), 10000)
  }, [userData, currentAccount, dispatch, navigate])
  
  return (
    <>
      <HostingHead pageName={pageName} setPageName={setPageName} />

      { pageName === 'my' && <HostingMyContent hostingDomains={hostingDomains} backdropOpen={backdropOpen} /> }
      { pageName === 'tariffs' && <HostingTariffsContent /> }

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default HostingContent