import { FaServer } from 'react-icons/fa'
import { CgWebsite } from 'react-icons/cg'
import { TbWorldWww } from 'react-icons/tb'
import { BsGlobe } from 'react-icons/bs'

const drawerList = [
  {
    title: 'virtualServers',
    icon: <FaServer size={24} />,
    url: '/vps',
  },
  {
    title: 'hosting',
    icon: <CgWebsite size={24} />,
    url: '/hosting',
  },
  {
    title: 'domains',
    icon: <TbWorldWww size={24} />,
    url: '/domains',
  },
  {
    title: 'internet',
    icon: <BsGlobe size={24} />,
    url: '/internet',
  },
]

export default drawerList