import React, { useState } from 'react'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const FormTextField = ({
  type,
  label,
  placeholder,
  value,
  required,
  disabled,
  lines,
  error,
  size,
  helpertext,
  props,
  startAdornment,
  endAdornment,
  fieldWidth,
  customStyles,
  handleChange,
}) => {
  const theme = useTheme()

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword((show) => !show)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const passwordEndAdornment = (
    <InputAdornment position='end'>
      <IconButton
        onClick={handleTogglePassword}
        onMouseDown={handleMouseDownPassword}
        edge='end'
      >
        { showPassword ? <VisibilityOff /> : <Visibility /> }
      </IconButton>
    </InputAdornment>
  )

  const fieldStyles = {
    width: fieldWidth ? fieldWidth : '100%',

    '& .MuiInputBase-input': {
      fontFamily: theme.typography.regular.fontFamily,
    }
  }

  const labelStyles = {
    display: 'inline',
    fontFamily: theme.typography.regular.fontFamily,
  }

  const borderStyles = (customStyles && endAdornment) ? {
    '& fieldset': customStyles,
    '&:hover fieldset': customStyles,
    '&:focus-within fieldset, &:focus-visible fieldset': customStyles,
  } : {
    '& fieldset': { border: 'none' },
    '&:hover fieldset': { border: 'none' },
    '&:focus-within fieldset, &:focus-visible fieldset': { border: 'none' },
  }

  const inputBigProps = {
    sx: customStyles ? borderStyles : {
      borderRadius: '8px',
      background: disabled ? alpha(theme.palette.chineseSilver.main, 0.2) : 'none',
    },
    startAdornment,
    endAdornment: type === 'password' ? passwordEndAdornment : endAdornment
  }

  const inputSmallProps = {
    sx: customStyles ? {
      ...customStyles,
      borderRight: endAdornment && 'none',
      borderTopRightRadius: endAdornment && '0px',
      borderBottomRightRadius: endAdornment && '0px',
    } : {}
  }

  return (
    <TextField
      type={ (type === 'password' && !showPassword) ? 'password' : 'text' }
      label={label && <Box sx={labelStyles}>{label}</Box>}
      placeholder={placeholder}
      required={required}
      disabled={disabled ? true : false}
      error={error}
      size={ size ? size : 'medium' }
      helperText={helpertext ? helpertext : ''}
      value={value}
      multiline={ lines > 1 }
      rows={lines}
      onChange={e => handleChange(e.target.value, props)}
      variant='outlined'
      InputProps={inputBigProps}
      inputProps={inputSmallProps}
      sx={fieldStyles}
    />
  )
}

export default FormTextField