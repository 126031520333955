import React from 'react'
import DrawerElement from '../common/DrawerElement'
import InternetContent from './InternetContent'

const Internet = () => {
  return (
    <DrawerElement content={<InternetContent />} />
  )
}

export default Internet