import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { SimpleLinkBox } from '../../themes/styles'
import FormCheckboxField from '../common/formFields/FormCheckboxField'

const DomainAgreementForm = ({ domainType, agree, setAgree, errors, setErrors }) => {
  const theme = useTheme()
  
  const lang = useSelector(state => state.settings.currentLang)
  const texts = useSelector(state => state.settings.langTexts)

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.error.main,
    fontSize: '14px',
  }

  const handleClickAgreement = () => {
    window.open('https://domain.grena.ge/documents/Agreement.pdf', '_blank')
  }

  const handleClickRules = () => {
    const url = lang === 'ka' ? 'https://domain.grena.ge/documents/Regulation_geo.pdf' : 'https://domain.grena.ge/documents/Regulation_eng.pdf'
    window.open(url, '_blank')
  }

  const handleClickPersonalData = () => {
    const url = lang === 'ka' ? 'https://domain.grena.ge/documents/Privacy_geo.pdf' : 'https://domain.grena.ge/documents/Privacy_eng.pdf'
    window.open(url, '_blank')
  }

  const handleClickAgreementGeo = () => {
    window.open('https://s3.grena.ge/grena-website-prod/domains/files/Regulation_geo_%E1%83%92%E1%83%94.pdf', '_blank')
  }

  const agreeLabel = domainType === 'eng' ? (
    <>
      <Box component='span'>{texts.agreeWith} </Box>
      <SimpleLinkBox component='span' onClick={handleClickAgreement} onMouseDown={handleClickAgreement}>{texts.domainRegistrationAgreement}</SimpleLinkBox>
      <Box component='span'>, </Box>
      <SimpleLinkBox component='span' onClick={handleClickRules} onMouseDown={handleClickRules}>{texts.domainRegistrationRules}</SimpleLinkBox>
      <Box component='span'> {texts.and} </Box>
      <SimpleLinkBox component='span' onClick={handleClickPersonalData} onMouseDown={handleClickPersonalData}>{texts.personalDataProcessingPolicy}</SimpleLinkBox>
    </>
  ) : (
    <>
      <Box component='span'>{texts.agreeWith} </Box>
      <SimpleLinkBox component='span' onClick={handleClickAgreementGeo} onMouseDown={handleClickAgreementGeo}>{texts.domainRegistrationRulesGeo}</SimpleLinkBox>
    </>
  )

  const handleChangeAgree = (value) => {
    setAgree(value)

    if (!value) {
      setErrors((prev) => {
        return { ...prev, agree: texts.pleaseCheck }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, agree: '' }
      })
    }
  }

  return (
    <Box sx={{ margin: '40px 0px 40px 10px' }}>
      <FormCheckboxField
        label={agreeLabel}
        checked={agree}
        required={true}
        error={errors.agree ? true : false}
        helpertext={errors.agree && <Box sx={errorHelperStyles} component='span'>{errors.agree}</Box>}
        handleChange={handleChangeAgree}
      />
    </Box>
  )
}

export default DomainAgreementForm