import React from 'react'
import Box from '@mui/material/Box'
import { HeaderBox } from '../../themes/styles'
import Logo from './Logo'
import LanguageSwitch from './LanguageSwitch'

const Header = () => {
  return (
    <HeaderBox>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Logo />
        <LanguageSwitch />    
      </Box>
    </HeaderBox>
  )
}

export default Header