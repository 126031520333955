import React from 'react'
import DrawerElement from '../common/DrawerElement'
import DomainTariffsContent from './DomainTariffsContent'

const DomainTariffs = () => {
  return (
    <DrawerElement content={<DomainTariffsContent />} />
  )
}

export default DomainTariffs