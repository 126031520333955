import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { isValidEmail, isValidPhone, isValidPersonalNumber, isValidCompanyCode } from '../../helpers/string'
import { getIsSuperadmin } from '../../helpers/users'
import FormTextField from '../common/formFields/FormTextField'

const DomainGeoContactForm = ({
  contactType,
  isCompany,
  isEdit,
  name,
  setName,
  lastname,
  setLastname,
  org,
  setOrg,
  address,
  setAddress,
  email,
  setEmail,
  phone,
  setPhone,
  personal,
  setPersonal,
  errors,
  setErrors,
}) => {
  const theme = useTheme()

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.error.main,
    fontSize: '14px',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)

  const isSuperadmin = getIsSuperadmin(userData)

  const showNames = !isCompany || contactType !== 'registrant'
  const showOrg = isCompany && contactType === 'registrant'
  const isDisabled = !isSuperadmin && isEdit && contactType === 'registrant' 

  const handleChangeName = (value) => {
    setName(value)

    if (!value && showNames) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Name`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Name`]: '' }
      })
    }
  }

  const handleChangeLastname = (value) => {
    setLastname(value)

    if (!value && showNames) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Lastname`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Lastname`]: '' }
      })
    }
  }

  const handleChangeOrg = (value) => {
    setOrg(value)

    if (!value && showOrg) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Org`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Org`]: '' }
      })
    }
  }

  const handleChangeAddress = (value) => {
    setAddress(value)

    if (!value && showOrg) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Address`]: texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Address`]: '' }
      })
    }
  }

  const handleChangeEmail = (value) => {
    setEmail(value)

    if (!isValidEmail(value)) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Email`]: value ? texts.registerErrorEmail : texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Email`]: '' }
      })
    }
  }

  const handleChangePhone = (value) => {
    setPhone(value)

    if (!isValidPhone(value)) {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Phone`]: value ? texts.errorPhone : texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Phone`]: '' }
      })
    }
  }

  const handleChangePersonal = (value) => {
    const valueMaxLength = isCompany ? 9 : 11
    const croppedValue = value.length <= valueMaxLength ? value : personal

    setPersonal(croppedValue)

    const validatePersonal = isCompany ? isValidCompanyCode(croppedValue) : isValidPersonalNumber(croppedValue)
    const validationError = isCompany ? texts.errorCompanyId : texts.errorPersonalId

    if (!validatePersonal && contactType === 'registrant') {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Personal`]: value ? validationError : texts.pleaseFillOut }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, [`${contactType}Personal`]: '' }
      })
    }
  }

  return (
    <>
      {
        showNames && (
          <>
            <Grid container>
              <Grid lg={6}>
                <Box sx={{ margin: '0px 10px 20px 0px' }}>
                  <FormTextField
                    type='text'
                    label={texts.name}
                    placeholder={texts.name}
                    value={name}
                    required={true}
                    error={errors[`${contactType}Name`] ? true : false}
                    helpertext={errors[`${contactType}Name`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Name`]}</Box>}
                    disabled={isDisabled}
                    lines='1'
                    handleChange={handleChangeName}
                  />
                </Box>
              </Grid>
              <Grid lg={6}>
                <Box sx={{ margin: '0px 0px 20px 10px' }}>
                  <FormTextField
                    type='text'
                    label={texts.lastname}
                    placeholder={texts.lastname}
                    value={lastname}
                    required={true}
                    error={errors[`${contactType}Lastname`] ? true : false}
                    helpertext={errors[`${contactType}Lastname`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Lastname`]}</Box>}
                    disabled={isDisabled}
                    lines='1'
                    handleChange={handleChangeLastname}
                  />
                </Box> 
              </Grid>
            </Grid>
          </>
        )
      }

      {
        showOrg && (
          <Grid container>
            <Grid lg={6}>
              <Box sx={{ margin: '0px 10px 20px 0px' }}>
                <FormTextField
                  type='text'
                  label={texts.organizationName}
                  placeholder={texts.organizationName}
                  value={org}
                  required={true}
                  error={errors[`${contactType}Org`] ? true : false}
                  helpertext={errors[`${contactType}Org`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Org`]}</Box>}
                  disabled={isDisabled}
                  lines='1'
                  handleChange={handleChangeOrg}
                />
              </Box>
            </Grid>
          </Grid>
        )
      }

      {
        contactType === 'registrant' && (
          <Grid container>
            <Grid lg={6}>
              <Box sx={{ margin: '0px 10px 20px 0px' }}>
                <FormTextField
                  type='text'
                  label={ isCompany ? texts.organizationCode : texts.personalNumber }
                  placeholder={ isCompany ? texts.organizationCode : texts.personalNumber }
                  value={personal}
                  required={true}
                  error={errors[`${contactType}Personal`] ? true : false}
                  helpertext={errors[`${contactType}Personal`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Personal`]}</Box>}
                  disabled={isDisabled}
                  lines='1'
                  handleChange={handleChangePersonal}
                />
              </Box>
            </Grid>
            <Grid lg={6}>
              <Box sx={{ margin: '0px 0px 20px 10px' }}>
                <FormTextField
                  type='text'
                  label={texts.address}
                  placeholder={texts.address}
                  value={address}
                  required={true}    
                  error={errors[`${contactType}Address`] ? true : false}
                  helpertext={errors[`${contactType}Address`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Address`]}</Box>}
                  lines='1'
                  handleChange={handleChangeAddress}
                />
              </Box>
            </Grid>
          </Grid>
        )
      }

      <Grid container>
        <Grid lg={6}>
          <Box sx={{ margin: '0px 10px 20px 0px' }}>
            <FormTextField
              type='text'
              label={texts.email}
              placeholder={texts.email}
              value={email}
              required={true}
              error={errors[`${contactType}Email`] ? true : false}
              helpertext={errors[`${contactType}Email`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Email`]}</Box>}
              disabled={isDisabled}
              lines='1'
              handleChange={handleChangeEmail}
            />
          </Box>
        </Grid>
        <Grid lg={6}>
          <Box sx={{ margin: '0px 0px 20px 10px' }}>
            <FormTextField
              type='text'
              label={texts.phoneNumber}
              placeholder={texts.phoneNumber}
              value={phone}
              required={true}
              error={errors[`${contactType}Phone`] ? true : false}
              helpertext={errors[`${contactType}Phone`] && <Box sx={errorHelperStyles} component='span'>{errors[`${contactType}Phone`]}</Box>}
              lines='1'
              handleChange={handleChangePhone}
            />
          </Box> 
        </Grid>
      </Grid>
    </>
  )
}

export default DomainGeoContactForm