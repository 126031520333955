import React from 'react'
import { useTheme, alpha } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import CellElement from './CellElement'

const RowElement = ({ record, tableFields }) => {
  const theme = useTheme()

  const rowStyles = {
    background: 'white',

    '&:hover': {
      background: alpha(theme.palette.chineseSilver.main, 0.1),
    }
  }

  return (
    <TableRow sx={rowStyles}>
      {
        tableFields.map((item, index) => (
          <CellElement key={index} {...item} record={record} />
        ))
      }
    </TableRow>
  )
}

export default RowElement