import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { PageLabelBox, IconSecondaryBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { setCurrentAccount, setCurrentAccountName, setCurrentAccountType } from '../../store/reducers/settings'
import FormTextField from '../common/formFields/FormTextField'
import TableElement from '../common/table/TableElement'
import CompactTableElement from '../common/table/CompactTableElement'
import BackdropElement from '../common/BackdropElement'

const AccountsContent = () => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)

  const [accounts, setAccounts] = useState([])
  const [tableFields, setTableFields] = useState([])
  const [tableData, setTableData] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [search, setSearch] = useState('')
  
  const handleChangeSearchKeyword = (value) => {
    setSearchKeyword(value)
  }

  const handleSearchSubmit = useCallback(() => {
    setSearch(searchKeyword.toLocaleLowerCase())
  }, [searchKeyword])

  const handleSearchReset = () => {
    setSearchKeyword('')
    setSearch('')
  }

  const handleChangeCurrentAccount = useCallback((record) => {
    dispatch(setCurrentAccount({ currentAccount: record.id }))
    dispatch(setCurrentAccountName({ currentAccountName: record.name }))
    dispatch(setCurrentAccountType({ currentAccountType: record.account_type }))
    navigate('/')
  }, [dispatch, navigate])

  useEffect(() => {
    setTableFields([
      {
        keyValue: 'name',
        content: texts.name,
        action: handleChangeCurrentAccount,
      },
      {
        keyValue: 'id_number',
        content: texts.companyIdOrPersonalId,
      },
      {
        keyValue: 'account_owner',
        content: texts.email,
      },
      {
        keyValue: 'status',
        content: texts.status,
      },
    ])
  }, [texts, handleChangeCurrentAccount])
  
  useEffect(() => {
    const fetchAccounts = async () => {
      setBackdropOpen(true)

      const requestUrl = settings.domain + settings.clientsUi + apiUsers.getAccounts

      const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.length > 0) {
        setAccounts(search ? response.filter(item =>
          (item.name && item.name.toLowerCase().includes(search)) ||
          (item.lastname && item.lastname.toLowerCase().includes(search)) ||
          (item.company_name && item.company_name.toLowerCase().includes(search)) ||
          (item.company_id && item.company_id.includes(search)) ||
          (item.personal_id && item.personal_id.includes(search))
        ) : response)
      } else {
        setAccounts([])
      }

      setBackdropOpen(false)
    }

    fetchAccounts()
  }, [userData, dispatch, navigate, search])

  useEffect(() => {
    const activeStyles = {
      color: theme.palette.greenCrayola.main,
    }

    const deactivatedStyles = {
      color: theme.palette.vividRed.main,
    }

    if (accounts.length > 0 && tableFields.length > 0) {
      let table_data = []

      accounts.forEach(acc => {
        let account_object = { id: acc.id, account_type: acc.account_type }

        tableFields.forEach(field => {
          if (field.keyValue === 'name') {
            account_object.name = acc.account_type === 'company' ? acc.company_name : `${acc.name} ${acc.lastname}`
          } else if (field.keyValue === 'id_number') {
            account_object.id_number = acc.account_type === 'company' ? acc.company_id : acc.personal_id
          } else if (field.keyValue === 'status') {
            account_object.status = acc.status === 'active' ? (
              <Box sx={activeStyles}>{texts.active}</Box>
            ) : (
              <Box sx={deactivatedStyles}>{texts.deactivated}</Box>
            )
          } else if (acc[field.keyValue]) {
            account_object[field.keyValue] = acc[field.keyValue]
          } else {
            account_object[field.keyValue] = ''
          }
        })

        table_data.push(account_object)
      })

      setTableData(table_data)
    }
  }, [accounts, tableFields, texts, theme])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSearchSubmit()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleSearchSubmit])

  const searchAdornment = (
    <InputAdornment position='start'>
      <IconButton onClick={handleSearchSubmit} edge='end'>
        <SearchIcon />
      </IconButton>      
    </InputAdornment>
  )
  
  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        <PageLabelBox>{texts.accounts}</PageLabelBox>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
        <Box>
          <FormTextField
            type='text'
            label={texts.search}
            placeholder={texts.search}
            value={searchKeyword}
            handleChange={handleChangeSearchKeyword}
            endAdornment={searchAdornment}
            fieldWidth={ width >= 900 ? '500px' : `${width/1.3 - 60}px` }
          />
        </Box>
        {
          searchKeyword && (
            <Box sx={{ marginLeft: '10px' }}>
              <IconSecondaryBox onClick={handleSearchReset}>
                <CloseIcon sx={{ fontSize: '36px' }} />
              </IconSecondaryBox>
            </Box>
          )
        }
      </Box>
      
      {
        width >= 900 ?
        <TableElement
          tableData={tableData}
          tableFields={tableFields}
        /> :
        <CompactTableElement
          tableData={tableData}
          tableFields={tableFields}
        />
      }      

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default AccountsContent