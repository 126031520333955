import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { SuccessButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestDomains } from '../../network/request'
import Header from '../common/Header'
import BackdropElement from '../common/BackdropElement'
import parse from 'html-react-parser'

const DomainEppCodeGenerate = () => {
  const theme = useTheme()
  const { height } = useWindowDimensions()
  const { uid } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)
  const lang = useSelector(state => state.settings.currentLang)
  const userData = useSelector(state => state.userData)

  const isAuthenticated = userData && userData.tokens && userData.tokens.accessToken

  const [notificationText, setNotificationText] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)

  const wrapperStyle = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.xlarge.fontSize,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${height - 80}px`,
  }

  const handleSubmit = () => {
    navigate(isAuthenticated ? '/domains' : '/')
  }

  useEffect(() => {
    const fetchEppCodegenerate = async () => {
      const postData = { uid, lang }

      const response = await requestDomains(settings.fetchDomains + `/?action=client-epp-code-generate`, 'POST', postData, userData, dispatch, navigate)
      
      let notification_text = texts.errorOccured

      if (response && response.status && response.status === 100) {
        if (response.data && response.data.message) {
          if (response.data.result && response.data.result === 1) {
            notification_text = parse(response.data.message)
          } else {
            notification_text = parse(texts.errorOccuredWithoutExclamationMark + ': ' + response.data.message)
          }          
        }
      }
      
      setNotificationText(notification_text)

      setBackdropOpen(false)
    }

    if (uid) {
      fetchEppCodegenerate()
    } else {
      navigate('/')
    }
  }, [uid, userData, dispatch, navigate, texts, lang])
  
  return (
    <>
      <Header />

      <Container maxWidth='xl'>
        <Box sx={wrapperStyle}>
          <Box sx={{ marginBottom: '50px' }}>{notificationText}</Box>
          <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit} sx={{ paddingLeft: '36px' }}>{texts.continue} <NavigateNextIcon fontSize='large' /></SuccessButton>
        </Box>
      </Container>

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainEppCodeGenerate