import React from 'react'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

const CircleIcon = ({
  type,
  size,
  bg,
  color,
  action,
}) => {
  const theme = useTheme()

  const hoverAlpha = action ? 0.7 : 1

  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `${size/2}px`,
    background: theme.palette[bg] ? theme.palette[bg].main : 'none',
    color: theme.palette[color] ? theme.palette[color].main : 'white',
    cursor: action ? 'pointer' : 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    '&:hover': {
      background: theme.palette[bg] ? alpha(theme.palette[bg].main, hoverAlpha) : 'none',
    },
  }
  
  return (
    <Box sx={style} onClick={ action ? action : () => {} }>
      { type === 'add' && <AddIcon sx={{ fontSize: `${size/1.5}px` }} /> }
      { type === 'close' && <CloseIcon sx={{ fontSize: `${size/1.5}px` }} /> }
    </Box>
  )
}

export default CircleIcon