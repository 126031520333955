import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Container from '@mui/material/Container'
import settings from '../../config/settings'
import { requestWebpage } from '../../network/request'
import { apiUsers } from '../../network/urls'
import Header from '../common/Header'
import LoginForm from './LoginForm'

const EmailConfirmPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userId, validationString } = useParams()

  const webpageData = useSelector(state => state.webpageData)

  useEffect(() => {
    const sendRequest = async () => {
      const postData = {
        user_id: userId,
        validation_string: validationString,
      }

      const requestUrl = settings.domain + settings.clientsUi + apiUsers.getUsersEmailConfirm
    
      const response = await requestWebpage(requestUrl, 'PUT', postData, webpageData, dispatch, navigate)
      
      if (response && response.status && response.status === 'confirmed') {
        navigate('/', { state: { emailConfirmOk: true } })
      } else {
        navigate('/', { state: { errorOccured: true } })
      }
    }

    if (userId && validationString) {
      sendRequest()
    } else {
      navigate('/', { state: { errorOccured: true } })
    }
  }, [dispatch, navigate, userId, validationString, webpageData])

  return (
    <>
      <Header />
      <Container maxWidth='xl'>
        <LoginForm />
      </Container>
    </>
  )
}

export default EmailConfirmPage