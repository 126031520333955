import React,  { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import settings from '../../config/settings'
import { requestDomains } from '../../network/request'
import { getTextedDate } from '../../helpers/date'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import DomainDetailsHead from './DomainDetailsHead'
import DomainDetailsSubmenu from './DomainDetailsSubmenu'
import DomainDetailsInfo from './DomainDetailsInfo'
import DomainDetailsPayment from './DomainDetailsPayment'
import DomainDetailsNameservers from './DomainDetailsNameservers'
import DomainDetailsTransfer from './DomainDetailsTransfer'
import DomainDetailsContact from './DomainDetailsContact'
import DomainDetailsDns from './DomainDetailsDns'
import DomainDetailsDnsNotification from './DomainDetailsDnsNotification'
import DomainNameserversAdd from './DomainNameserversAdd'
import DomainNameserversDelete from './DomainNameserversDelete'
import DomainContactEdit from './DomainContactEdit'
import DomainDnsDelete from './DomainDnsDelete'
import DomainPay from './DomainPay'
import DomainEppCodeRequest from './DomainEppCodeRequest'
import ModalWindow from '../common/ModalWindow'
import BackdropElement from '../common/BackdropElement'

const DomainDetails = ({ domainUid, domainType }) => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const [domainDetails, setDomainDetails] = useState('')
  const [activePage, setActivePage] = useState('details')
  const [isCompany, setIsCompany] = useState(false)
  const [isGrenaNameservers, setIsGrenaNameservers] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('lg')
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [update, setUpdate] = useState(1)

  const submenu = [
    {
      title: texts.details,
      page: 'details',
    },
    {
      title: texts.contactInformation,
      page: 'contacts',
    },
    {
      title: texts.dnsService,
      page: 'dns',
    },
  ]

  const getDomainStatusName = (status) => {
    if (status === 'active') {
      return texts.active
    } else if (status === 'pending') {
      return texts.waitingForPayment
    } else if (status === 'expires') {
      return texts.expires
    } else if (status === 'expired') {
      return texts.expired
    }
  }

  const getDomainStatusColor = (status) => {
    if (status === 'active') {
      return theme.palette.greenCrayola.main
    } else if (status === 'pending') {
      return theme.palette.vividRed.main
    } else if (status === 'expires') {
      return theme.palette.royalOrange.main
    } else if (status === 'expired') {
      return theme.palette.vividRed.main
    } else {
      return theme.palette.vividRed.main
    }
  }

  const dnsManageRequest = async (postData) => {
    let result = false
    setBackdropOpen(true)
    
    const response = await requestDomains(settings.fetchDomains + `/?action=client-manage-dns&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
    
    if (response && response.status && response.status === 100) {
      setUpdate(prev => prev + 1)
      result = true
    } else {
      let errorTitle = texts.addRecord

      if (postData && postData.action && postData.action === 'edit') {
        errorTitle = texts.editRecord
      } else if (postData && postData.action && postData.action === 'delete') {
        errorTitle = texts.deleteRecord
      }

      handleOpenNotification({
        title: errorTitle,
        text: texts.errorOccured,
      })
    }

    setBackdropOpen(false)
    return result
  }

  const handleOpenNotification = (props) => {
    setModalOpen(true)
    setModalWidth(props.width ? props.width : 'sm')
    setModalTitle(props.title)
    setModalContent(props.text)
  }

  const handleOpenNameserversAdd = (props) => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(texts.addNsRecords)
    setModalContent(
      <DomainNameserversAdd
        modalTitle={texts.addNsRecords}
        setModalOpen={setModalOpen}
        setUpdate={setUpdate}
        handleOpenNotification={handleOpenNotification}
        {...props}
        />
    )
  }

  const handleOpenNameserversDelete = (props) => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(texts.deleteNsRecord)
    setModalContent(
      <DomainNameserversDelete
        modalTitle={texts.deleteNsRecord}
        setModalOpen={setModalOpen}
        setUpdate={setUpdate}
        handleOpenNotification={handleOpenNotification}
        {...props}        
      />
    )
  }

  const handleOpenContactEdit = (props) => {
    let modalTitle = texts.editDomainRegistrant.replace('###DOMAIN###', domainDetails.name)

    if (props.contactType === 'admin') {
      modalTitle = texts.editDomainAdmin.replace('###DOMAIN###', domainDetails.name)
    } else if (props.contactType === 'tech') {
      modalTitle = texts.editDomainTechPerson.replace('###DOMAIN###', domainDetails.name)
    }

    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(modalTitle)
    setModalContent(
      <DomainContactEdit
        modalTitle={modalTitle}
        setModalOpen={setModalOpen}
        setUpdate={setUpdate}
        handleOpenNotification={handleOpenNotification}
        {...props}
      />
    )
  }

  const handleOpenDnsDelete = (props) => {
    setModalOpen(true)
    setModalWidth('lg')
    setModalTitle(texts.deleteRecord)
    setModalContent(
      <DomainDnsDelete
        setModalOpen={setModalOpen}
        dnsManageRequest={dnsManageRequest}
        domainType={domainType}
        domainUid={domainDetails.uid}
        {...props}
      />
    )
  }

  const handleOpenDnsNotification = (props) => {
    setModalOpen(true)
    setModalWidth('sm')
    setModalTitle(props.modalTitle)
    setModalContent(
      <DomainDetailsDnsNotification
        setModalOpen={setModalOpen}
        setUpdate={setUpdate}
        handleOpenNotification={handleOpenNotification}
        {...props}
      />
    )
  }

  const handleOpenPay = () => {
    setModalOpen(true)
    setModalWidth('md')
    setModalTitle(texts.domainPayModalLabel.replace('###DOMAIN###', domainDetails.name))
    setModalContent(
      <DomainPay
        setModalOpen={setModalOpen}
        domainType={domainType}
        domainName={domainDetails.name}
        domainUid={domainDetails.uid}
        domainStatus={domainDetails.status}
        backUrl={domainDetails.back_url}
      />
    )
  }

  const handleOpenEppCodeRequest = (props) => {
    setModalOpen(true)
    setModalWidth('sm')
    setModalTitle(props.domainName + ' ' + texts.transferDomain)
    setModalContent(<DomainEppCodeRequest handleOpenNotification={handleOpenNotification} {...props} />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchDomainDetails = async () => {
      setBackdropOpen(true)

      const response = await requestDomains(settings.fetchDomains + `/?action=client-domain-details&account_id=${currentAccount}&domain_uid=${domainUid}&domain_type=${domainType}`, 'GET', '', userData, dispatch, navigate)

      if (response && response.status && response.status === 100) {
        setDomainDetails(response.data)
      } else {
        setDomainDetails('')
      }

      setBackdropOpen(false)
    }

    fetchDomainDetails()
  }, [userData, dispatch, navigate, domainUid, currentAccount, domainType, update])

  useEffect(() => {
    if (domainDetails && domainDetails.registrant && (domainDetails.registrant.org || domainDetails.registrant.org_eng)) {
      setIsCompany(true)
    } else {
      setIsCompany(false)
    }

    if (domainDetails && domainDetails.ns.length > 0) {
      let result = false

      domainDetails.ns.forEach(item => {
        if (item.name === 'ns4.grena.ge' || item.name === 'ns5.grena.ge') {
          result = true
        }
      })

      setIsGrenaNameservers(result)
    } else {
      setIsGrenaNameservers(false)
    }
  }, [domainDetails])
  
  return (
    <>
      {
        domainDetails && (
          <>
            <DomainDetailsHead
              name={domainDetails.name}
              statusName={getDomainStatusName(domainDetails.status)}
              statusColor={getDomainStatusColor(domainDetails.status)}
            />

            <DomainDetailsSubmenu
              submenu={submenu}
              hasDns={domainDetails.has_dns}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          </>
        )
      }

      {
        (domainDetails && activePage === 'details') && (
          <Grid container>
            <Grid xs={12} lg={6}>
              <Box>
                <DomainDetailsInfo
                  name={domainDetails.name}
                  domainType={domainType}
                  domainUid={domainDetails.uid}
                  registrationDate={getTextedDate(domainDetails.regdate)}
                  expirationDate={getTextedDate(domainDetails.expdate)}
                  statusName={getDomainStatusName(domainDetails.status)}
                  statusColor={getDomainStatusColor(domainDetails.status)}
                  hiddenWhoIs={domainDetails.hw}
                  setBackdropOpen={setBackdropOpen}
                  setUpdate={setUpdate}
                  handleOpenNotification={handleOpenNotification}
                />
              </Box>                
            </Grid>
            <Grid xs={12} lg={6}>
              <Box>
                <DomainDetailsPayment
                  price={domainDetails.price}
                  invoiceUrl={domainDetails.invoice_url}
                  handleOpenPay={handleOpenPay}
                />

                <Box>
                  <Grid container>
                    <Grid xs={12} lg={ (domainType === 'eng' && isAdmin) ? 9 : 12 }>
                      <Box>
                        <DomainDetailsNameservers
                          domainUid={domainDetails.uid}
                          domainName={domainDetails.name}
                          domainType={domainType}
                          nameservers={domainDetails.ns}
                          hasDns={domainDetails.has_dns}
                          handleOpenDnsNotification={handleOpenDnsNotification}
                          handleOpenNameserversAdd={handleOpenNameserversAdd}
                          handleOpenNameserversDelete={handleOpenNameserversDelete}
                        />
                      </Box>                      
                    </Grid>

                    {
                      (domainType === 'eng' && isAdmin) && (
                        <Grid xs={12} lg={3}>
                          <Box>
                            <DomainDetailsTransfer
                              domainName={domainDetails.name}
                              domainUid={domainDetails.uid}
                              eppCode={domainDetails.epp_code}
                              handleOpenEppCodeRequest={handleOpenEppCodeRequest}
                              handleOpenNotification={handleOpenNotification}
                            />
                          </Box>
                        </Grid>
                      )
                    }                    
                  </Grid>
                </Box>
              </Box>           
            </Grid>
          </Grid>
        )
      }

      {
        (domainDetails && activePage === 'contacts') && (
          <Grid container>
            {
              domainDetails.registrant && (
                <Grid xs={12} lg={12}>
                  <DomainDetailsContact
                    contactType='registrant'
                    domainType={domainType}
                    isCompany={isCompany}
                    contactUid={domainDetails.registrant.uid}
                    name={domainDetails.registrant.name}
                    nameEng={domainDetails.registrant.name_eng}
                    lastname={domainDetails.registrant.lastname}
                    lastnameEng={domainDetails.registrant.lastname_eng}
                    org={domainDetails.registrant.org}
                    orgEng={domainDetails.registrant.org_eng}
                    address={domainDetails.registrant.address}
                    addressEng={domainDetails.registrant.address_eng}
                    personal={domainDetails.registrant.personal}
                    phone={domainDetails.registrant.phone}
                    email={domainDetails.registrant.email}
                    handleOpenContactEdit={handleOpenContactEdit}
                  />
                </Grid>
              )
            }

            {
              (domainDetails.admin && isCompany) && (
                <Grid xs={12} lg={6}>
                  <Box>
                    <DomainDetailsContact
                      contactType='admin'
                      domainType={domainType}
                      contactUid={domainDetails.admin.uid}
                      name={domainDetails.admin.name}
                      nameEng={domainDetails.admin.name_eng}
                      lastname={domainDetails.admin.lastname}
                      lastnameEng={domainDetails.admin.lastname_eng}
                      org={domainDetails.admin.org}
                      orgEng={domainDetails.admin.org_eng}
                      address={domainDetails.admin.address}
                      addressEng={domainDetails.admin.address_eng}
                      personal={domainDetails.admin.personal}
                      phone={domainDetails.admin.phone}
                      email={domainDetails.admin.email}
                      handleOpenContactEdit={handleOpenContactEdit}
                    />
                  </Box>
                </Grid>
              )
            }

            {
              (domainDetails.tech && isCompany) && (
                <Grid xs={12} lg={6}>
                  <Box>
                    <DomainDetailsContact
                      contactType='tech'
                      domainType={domainType}
                      contactUid={domainDetails.tech.uid}
                      name={domainDetails.tech.name}
                      nameEng={domainDetails.tech.name_eng}
                      lastname={domainDetails.tech.lastname}
                      lastnameEng={domainDetails.tech.lastname_eng}
                      org={domainDetails.tech.org}
                      orgEng={domainDetails.tech.org_eng}
                      address={domainDetails.tech.address}
                      addressEng={domainDetails.tech.address_eng}
                      personal={domainDetails.tech.personal}
                      phone={domainDetails.tech.phone}
                      email={domainDetails.tech.email}
                      handleOpenContactEdit={handleOpenContactEdit}
                    />
                  </Box>                  
                </Grid>
              )
            }
          </Grid>
        )
      }

      {
        (domainDetails && activePage === 'dns') && (
          <DomainDetailsDns
            domainUid={domainDetails.uid}
            domainName={domainDetails.name}
            domainType={domainType}
            dnsRecords={domainDetails.dns_records}
            nameservers={domainDetails.ns}
            isGrenaNameservers={isGrenaNameservers}
            handleOpenDnsDelete={handleOpenDnsDelete}
            dnsManageRequest={dnsManageRequest}
            handleOpenDnsNotification={handleOpenDnsNotification}
          />
        )
      }

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth={modalWidth}
        onModalClose={handleCloseModal}
      />

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainDetails