import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import {
  HomeVisualHostingBox,
  HomeVisualHostingTitleEmailsBox,
  HomeVisualHostingTitleDomainBox,
  HomeVisualHostingTitleStorageBox,
  HomeVisualHostingValueEmailsBox,
  HomeVisualHostingValueDomainBox,
  HomeVisualHostingValueStorageBox,
} from '../../themes/styles'

const HomeVisualHosting = ({ hostingDomains }) => {
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)

  const [emailsCount, setEmailsCount] = useState(0)
  const [storageCount, setStorageCount] = useState(0)

  const handleNavigate = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/hosting', '_blank')
    } else if (e.type === 'click') {
      navigate('/hosting')
    }
  }

  useEffect(() => {
    let emails_count = 0
    let storage_count = 0

    hostingDomains.forEach(hos => {
      emails_count += parseFloat(hos.emails)
      storage_count += parseFloat(hos.storage)
    })

    setEmailsCount(emails_count)
    setStorageCount(storage_count)
  }, [hostingDomains])

  return (
    <HomeVisualHostingBox onClick={handleNavigate} onMouseDown={handleNavigate}>
      <HomeVisualHostingTitleEmailsBox>{texts.email}</HomeVisualHostingTitleEmailsBox>      
      <HomeVisualHostingTitleDomainBox>{texts.domain_s}</HomeVisualHostingTitleDomainBox>
      
      <HomeVisualHostingTitleStorageBox>
        <Box>{texts.storage}</Box>
        <Box>({texts.gb})</Box>
      </HomeVisualHostingTitleStorageBox>

      <HomeVisualHostingValueEmailsBox>{emailsCount}</HomeVisualHostingValueEmailsBox>
      <HomeVisualHostingValueDomainBox>{hostingDomains.length}</HomeVisualHostingValueDomainBox>
      <HomeVisualHostingValueStorageBox>{storageCount}</HomeVisualHostingValueStorageBox>
    </HomeVisualHostingBox>
  )
}

export default HomeVisualHosting