const enTexts = {
  virtualServers: 'Virtual Servers',
  hosting: 'Hosting',
  domains: 'Domains',
  internet: 'Internet',
  hostingService: 'Hosting Service',  
  internetService: 'Internet Service',
  order: 'Order',
  register: 'Register',
  join: 'Join',
  myServers: 'My Servers',
  cpu: 'CPU',
  cores: 'Cores',
  ram: 'RAM',
  hdd: 'HDD',
  gb: 'GB',
  email: 'E-mail',
  domain: 'Domain',
  domain_s: 'Domains',
  storage: 'Storage',
  myDomains: 'My Domains',
  registration: 'Registration',
  local: 'Local',
  global: 'Global',
  mbps: 'Mbps',
  technicalAssistance: 'Technical Assistance',
  serverName: 'Server Name',
  status: 'Status',
  receivePassword: 'Receive Password',
  ipAddresses: 'IP Addresses',
  accessPoint: 'Access Point',
  on: 'On',
  off: 'Off',
  location: 'Location',
  locationAddress: 'Location Address',
  pingGraph: 'Ping Graph',
  searchByDomainName: 'Search by Domain Name',
  allDomains: 'All Domains',
  active: 'Active',
  cancelled: 'Cancelled',
  corporateEmails: 'Corporate E-mails',
  items: 'items',
  paymentHistory: 'Payment History',
  tariffs: 'Tariffs',
  rules: 'Rules',
  registerNewDomain: 'Register New Domain',
  transferDomain: 'Transfer Domain',
  expires: 'Expires',
  expired: 'Expired',
  waitingForPayment: 'Waiting for Payment',
  sapasuri: 'Price',
  expirationDate: 'Expiration Date',
  period: 'Period',
  paymentDate: 'Payment Date',
  gel: 'GEL',
  renew: 'Renew',
  transfer: 'Transfer',
  year: 'year',
  years: 'years',
  free: 'Free',
  details: 'Details',
  dnsService: 'DNS Service',
  registrationDate: 'Registration Date',
  hideWhoIs: 'Hide WhoIs',
  price: 'Price',
  invoice: 'Invoice',
  pay: 'Pay',
  nsServers: 'NS Servers',
  useGrenaDomainServersAndFreeDnsService: 'Use GRENA Domain Servers and free DNS service',
  contactInformation: 'Contact Information',
  registrant: 'Registrant',
  administrator: 'Administrator',
  technicalPerson: 'Technical Person',
  name: 'Name',
  lastname: 'Lastname',
  organizationName: 'Organization name',
  address: 'Address',
  phoneNumber: 'Phone number',
  organizationCode: 'Organization code',
  personalNumber: 'Personal number',
  actions: 'Actions',
  save: 'Save',
  domainEngRulesTitle: '.GE Domain Registration and Administration Rules',
  domainGeoRulesTitle: '.გე Domain Registration Rules',
  nothingHasFound: 'Nothing has found',
  successfull: 'Successfull',
  _from: 'from',
  _to: 'to',
  dnsManagementActivation: 'DNS management activation',
  dnsManagementDeactivation: 'DNS management deactivation',
  dnsManagementActivationNotification: 'DNS records will be activated only after adding Grena NS servers (ns4.grena.ge, ns5.grena.ge).',
  dnsManagementDeactivateNotification: 'Are you sure you want to deactivate DNS service?',
  confirm: 'Confirm',
  password: 'Password',
  login: 'Login',
  wrongEmailOrPassword: 'Wrong e-mail or password',
  addRecord: 'Add Record',
  recordType: 'Record Type',
  notActive: 'Not Active',
  close: 'Close',
  invalidDomainName: 'Invalid domain name',
  domainIsUnavailable: 'Domain is unavailable',
  domainRegistration: 'Domain registration',
  domainTransfer: 'Domain transfer',
  _domainRegistration: ' domain registration',
  _domainTransfer: ' domain transfer',
  nsRecord: 'NS record',
  ipAddress: 'IP address',
  setIpAddress: 'Set IP address',
  agreeWith: 'Agree with',
  and: 'and',
  domainRegistrationAgreement: 'domain name registration agreement',
  domainRegistrationRules: '.ge domain registration and administration rules',
  domainRegistrationRulesGeo: '.გე domain registration rules',
  personalDataProcessingPolicy: 'personal data processing policy',
  organizationTypeVerificationDocument: 'Organization type verification document',
  uploadFileMax5Mb: 'Upload file (max 5MB)',
  transferEppCode: 'Transfer (EPP) code',
  invalidTransferEppCode: 'Invalid transfer (EPP) code',
  continue: 'Continue',
  addNsRecords: 'Add NS Records',
  deleteNsRecord: 'Delete NS Record',
  deleteNsRecordNotification: 'Are you sure you want to delete ###NS### record?',
  editDomainRegistrant: 'Edit ###DOMAIN### domain registrant',
  editDomainAdmin: 'Edit ###DOMAIN### domain administrator',
  editDomainTechPerson: 'Edit ###DOMAIN### domain technical person',
  editRecord: 'Edit Record',
  deleteRecord: 'Delete Record',
  accountSettings: 'Account Settings',
  users: 'Users',
  changePassword: 'Change Password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  passwordRequirements: 'Password must be at least 8 characters long and contain uppercase letters, lowercase letters, numbers and special symbols',
  pleaseFillOut: 'Please fill out',
  passwordNotMatch: 'Password does not match',
  incorrectOldPassword: 'Incorrect old password',
  errorOccured: 'An error occured!',
  errorOccuredWithoutExclamationMark: 'An error occured',
  account: 'Account',
  createNewAccount: 'Create new account',
  accountType: 'Account type',
  accountTypePerson: 'Individual',
  accountTypeCompany: 'Organization',
  companyName: 'Organization name',
  personalId: 'Personal number',
  companyId: 'Organization code',
  errorPersonalId: 'Personal number must contain 11 symbols',
  errorCompanyId: 'Organization code must contain 9 symbols',
  accountCreated: 'Account ###ACCOUNT### has been successfully created',
  loginError: 'Incorrect e-mail or password',
  reCaptchaError: 'reCAPTCHA check failed',
  forgotPassword: 'Forogt password?',
  restore: 'Restore',
  notRegistered: 'Not registered?',
  haveNotReceivedEmailConfirmationLink: 'Have not received e-mail confirmation link?',
  resend: 'Resend',
  signUp: 'Sign Up',
  alreadyHaveAnAccount: 'I already have an account',
  registerErrorEmail: 'Incorrect e-mail format',
  registerErrorConfirmPassword: 'Password does not match',
  registerErrorPersonalId: 'Personal number must contain 11 symbols',
  registerErrorCompanyId: 'Organization code must contain 9 symbols',
  registerOk1: 'You have successfully registered',
  registerOk2: 'Confirmation link has been sent to your e-mail',
  passwordRecovery: 'Password Recovery',
  passwordRecoveryOk: 'Confirmation link has been sent to your e-mail',
  incorrectEmail: 'Incorrect e-mail',
  enterNewPassword: 'Enter New Password',
  passwordHasChanged: 'Password has changed',
  emailIsConfirmed: 'Your e-mail has been confirmed',
  error: 'Error',
  confirmEmail: 'Confirm E-mail',
  enterEmail: 'Enter e-mail',
  userAlreadyExists: 'User with ###EMAIL### e-mail already exists',
  userNotExists: 'User does not exist or is blocked',
  userAlreadyActive: 'User is already active',
  registerNewAccount: 'Register new account',
  logInToProfile: 'Login to profile',
  send: 'Send',
  activate: 'Activate',
  deactivate: 'Deactivate',
  deactivated: 'Deactivated',
  activateAccount: 'Activate Account',
  deactivateAccount: 'Deactivate Account',
  activateAccountWarning: 'Are you sure you want to activate ###ACCOUNT### account?',
  deactivateAccountWarning: 'Are you sure you want to deactivate ###ACCOUNT### account?',
  role: 'Role',
  roles: 'roles',
  groups: 'groups',
  addNewUser: 'Add new user',
  admin: 'Admin',
  onlyView: 'Only view',
  owner: 'Owner',
  user: 'User',
  youHaveNotActiveAccount: 'You have not an active account',
  deleteUser: 'Delete User',
  deleteUserWarning: 'Are you sure you want to delete ###USER### user?',
  linkOldDomainAccount: 'Link domain.grena.ge/დომენი.გრენა.გე account',
  linkAccount: 'Link account',
  website: 'Website',
  username: 'Username',
  usernameAndPasswordError: 'Incorrect username or password',
  accountAlreadyLinked: 'Account is already linked',
  pleaseCheck: 'Please check',
  pleaseUploadFile: 'Please upload file',
  errorPhone: 'Incorrect phone format',
  domainAddOk: '<b>###DOMAIN###</b> domain registration request is sent to the .GE domain registrar.<br /><br />To E-mail address indicated by you ###EMAIL### will be sent information and invoice related to the <b>###DOMAIN###</b> domain registration<br /><br />Before the domain registration completes, the registrar has the right to request additional information/documentation in case if your application contains incomplete/incorrect information or doesn’t meet the necessary requirements for the registration of .GE domain,  in accordance with the registration and administration rules.<br /><br />for more information and inquiries, please contact: domains@grena.ge or 2250590<br /><br />Thank you for using our service.<br /><br />.GE domain registrar<br /><br />Georgian Research and Educational Networking Association “GRENA”',
  domainGeoAddOk: '<b>###DOMAIN###</b> domain registration request is sent to the .გე domain registrar.<br /><br />To E-mail address indicated by you ###EMAIL### will be sent information and invoice related to the <b>###DOMAIN###</b> domain registration<br /><br />Before the domain registration completes, the registrar has the right to request additional information/documentation in case if your application contains incomplete/incorrect information or doesn’t meet the necessary requirements for the registration of .GE domain,  in accordance with the registration and administration rules.<br /><br />for more information and inquiries, please contact: domains@grena.ge or 2250590<br /><br />Thank you for using our service.<br /><br />.GE domain registrar<br /><br />Georgian Research and Educational Networking Association “GRENA”',
  emailChangeNotification: 'To change your e-mail<br />please follow the link sent to your old e-mail',
  emailHasChanged: '###DOMAIN### domain registrant e-mail has been changed',
  notification: 'Notification',
  nsChangeNotification: 'Are you sure you want to change NS records?',
  domainEppCodeIs: '###DOMAIN### domain EPP code:',
  domainEppCodeGenerateWarning: 'Are you sure you want to generate ###DOMAIN### domain EPP code?',
  domainEppCodeRequestSuccess: 'To generate EPP code,<br />please follow the link sent to your e-mail',
  domainPayModalLabel: 'Pay ###DOMAIN### domain annual fee',
  domainAnnualFee: 'Domain annual fee',
  comissionFee: 'Comission fee',
  sum: 'Sum',
  term: 'Term',
  domainWillBeActivatedIn: '###DOMAIN### domain will be activated during 72 hours',
  contentFormSendOk: 'Your request was successfully submitted.<br />Our consultant will contact you soon.',
  vpsPasswordHasBeenSent: 'Your request has been sent. If the specified e-mail address is in your contract contact list, the requested authorization parameters will be sent to this e-mail address, otherwise authorization parameters cannot be sent to an unknown e-mail address.',
  passwords: 'Passwords',
  selectedPasswords: 'Selected passwords',
  companyIdOrPersonalId: 'Company ID / Personal ID',
  accounts: 'Accounts',
  search: 'Search',
  noVpsNotification: 'You have not purchased a VPS service',
  noHostingNotification: 'You have not purchased a Hosting service',
  noDomainsNotification: 'You have not registered domains',
  noInternetNotification: 'You have not purchased an Internet service',
  syncWithOldPortal: 'Sync with old portal',
  writeYourOrganizationName: 'Write your organization name',
  myVps: 'My VPS',
  myHosting: 'My Hosting',
  priceCustom: 'Custom',
  month: 'Month',
  traffic: 'Traffic',
  speed: 'Speed',
  backup: 'Backup',
  cpuCores: 'CPU CORES',
  gbRam: 'GB RAM',
  ssd: 'GB SSD',
  corpEmails: 'Corporate emails',
  hostingDomains: 'Domains',
  websites: 'Websites',
  small: 'Small',
  medium: 'Medium',
  services: 'Services',
  oldDomainInstructionText1: 'If your domains are registered on the old portal',
  oldDomainInstructionText2: 'see:',
  oldDomainInstructionText3: 'Instructions for transferring from domain.grena.ge',
  oldDomainInstructionText4: 'Instructions for transferring from დომენი.გრენა.გე',
  oldDomainInstructionText5: 'Domains transfer from the old portal',
}

export default enTexts