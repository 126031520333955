import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { NoResultBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import parse from 'html-react-parser'
import { requestDomains } from '../../network/request'
import { getTextedDate } from '../../helpers/date'
import DomainsHead from './DomainsHead'
import DomainsSubheader from './DomainsSubheader'
import TableElement from '../common/table/TableElement'
import CompactTableElement from '../common/table/CompactTableElement'
import DomainDetails from './DomainDetails'
import CardElement from '../common/CardElement'
import BackdropElement from '../common/BackdropElement'
import ModalWindow from '../common/ModalWindow'

const DomainsContent = () => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { uid } = useParams()

  const userData = useSelector(state => state.userData)
  const texts = useSelector(state => state.settings.langTexts)
  const lang = useSelector(state => state.settings.currentLang)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [domains, setDomains] = useState([])
  const [filteredDomains, setFilteredDomains] = useState([])
  const [tableFields, setTableFields] = useState([])
  const [tableData, setTableData] = useState([])
  const [filterStatus, setFilterStatus] = useState('all')
  const [filterKeyword, setFilterKeyword] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [cardOpen, setCardOpen] = useState(false)
  const [cardContent, setCardContent] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [update, setUpdate] = useState(1)
  const [fetched, setFetched] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('lg')

  const handleOpenDomainDetails = (domainRecord) => {
    if (domainRecord.status !== 'pending' || domainRecord.invoice_sent) {
      setCardOpen(true)
      setCardContent(<DomainDetails domainUid={domainRecord.uid} domainType={domainRecord.domainType} />)
    }    
  }

  const handleOpenNotification = (props) => {
    setModalOpen(true)
    setModalWidth(props.width ? props.width : 'sm')
    setModalTitle(props.title)
    setModalContent(props.text)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [filterStatus, filterKeyword])

  useEffect(() => {
    if (uid && location.pathname.includes('domain-registrant-email-change')) {
      (async () => {
        const postData = { uid }

        const response = await requestDomains(settings.fetchDomains + `/?action=client-email-change&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
        
        if (response && response.status && response.status === 100) {
          handleOpenNotification({
            title: texts.notification,
            text: texts.emailHasChanged,
          })
        } else {
          handleOpenNotification({
            title: texts.notification,
            text: texts.errorOccured,
          })
        }     

        navigate('/domains')
      })()
    }

    if (uid && location.pathname.includes('domain-epp-code-generate')) {
      (async () => {
        const postData = { uid, lang }

        const response = await requestDomains(settings.fetchDomains + `/?action=client-epp-code-generate&account_id=${currentAccount}`, 'POST', postData, userData, dispatch, navigate)
        
        let responseText = texts.errorOccured

        if (response && response.status && response.status === 100) {
          if (response.data && response.data.message) {
            if (response.data.result && response.data.result === 1) {
              responseText = parse(response.data.message)
            } else {
              responseText = texts.errorOccuredWithoutExclamationMark + ': ' + parse(response.data.message)
            }          
          }
        }
        
        handleOpenNotification({
          title: texts.notification,
          text: responseText,
        })

        setBackdropOpen(false)
        navigate('/domains')
      })()

      setTimeout(() => setBackdropOpen(false), 10000)
    }    
  }, [uid, currentAccount, userData, dispatch, navigate, location, texts, lang])
  
  useEffect(() => {
    (async () => {
      setBackdropOpen(true)
      setFetched(false)

      const response = await requestDomains(settings.fetchDomains + `/?action=client-domains&account_id=${currentAccount}`, 'GET', '', userData, dispatch, navigate)
      
      if (response && response.status && response.status === 100) {
        setDomains(response.data)
      } else {
        setDomains([])
        setBackdropOpen(false)        
      }
      
      setFetched(true)
    })()

    setTimeout(() => setBackdropOpen(false), 10000)
  }, [update, currentAccount, userData, dispatch, navigate])

  useEffect(() => {
    setTableFields([
      {
        keyValue: 'name',
        content: texts.domain,
        action: handleOpenDomainDetails,
      },
      {
        keyValue: 'status',
        content: texts.status,
        action: handleOpenDomainDetails,
        customHeadStyle: {
          textAlign: 'center',
        },
        customBodyStyle: {
          textAlign: 'center',
        },
      },
      {
        keyValue: 'price',
        content: texts.sapasuri,
        action: handleOpenDomainDetails,
        customHeadStyle: {
          textAlign: 'center',
        },
        customBodyStyle: {
          textAlign: 'center',
        },
      },
      {
        keyValue: 'expdate',
        content: texts.expirationDate,
        action: handleOpenDomainDetails,
        customHeadStyle: {
          textAlign: 'right',
        },
        customBodyStyle: {
          textAlign: 'right',
        },
      },
    ])
  }, [texts])

  useEffect(() => {
    if (domains.length > 0) {
      let tempArray = domains
      const hasExtension = filterKeyword.includes('.ge') || filterKeyword.includes('.გე')

      if (filterStatus && filterStatus !== 'all') {
        tempArray = tempArray.filter(item => item.status === filterStatus)
      }

      if (filterKeyword && hasExtension) {
        tempArray = tempArray.filter(item => item.name === filterKeyword)
      } else if (filterKeyword && !filterKeyword.includes('.')) {
        tempArray = tempArray.filter(item => item.name.includes(filterKeyword))
      }

      setTotalPages(Math.ceil(tempArray.length / settings.domainsCount))
      
      tempArray = tempArray.filter((item, index) => index >= settings.domainsCount * (currentPage - 1) && index < settings.domainsCount * currentPage)

      setFilteredDomains(tempArray)
    } else {
      setFilteredDomains([])
      setTotalPages(1)
    }
  }, [domains, filterStatus, filterKeyword, currentPage])

  useEffect(() => {
    const errorStyle = {
      color: theme.palette.vividRed.main
    }
  
    const warningStyle = {
      color: theme.palette.royalOrange.main
    }

    const getDomainStatusName = (status) => {
      if (status === 'active') {
        return texts.active
      } else if (status === 'pending') {
        return texts.waitingForPayment
      } else if (status === 'expires') {
        return texts.expires
      } else if (status === 'expired') {
        return texts.expired
      }
    }

    if (filteredDomains.length > 0 && tableFields.length > 0) {
      let tempArray = []

      filteredDomains.forEach(item => {
        let tempObject = {
          uid: item.uid,
          domainType: item.domainType,
        }

        tableFields.forEach(item2 => {
          if (item[item2.keyValue] && item2.keyValue === 'price') {
            tempObject[item2.keyValue] = item[item2.keyValue] + ' ' + texts.gel
          } else if (item[item2.keyValue] && item2.keyValue === 'expdate') {
            tempObject[item2.keyValue] = getTextedDate(item[item2.keyValue])
          } else if (item[item2.keyValue] && item2.keyValue === 'status') {
            let statusStyle = {}

            if (item[item2.keyValue] === 'pending' || item[item2.keyValue] === 'expired') {
              statusStyle = errorStyle
            } else if (item[item2.keyValue] === 'expires') {
              statusStyle = warningStyle
            }

            tempObject[item2.keyValue] = <Box sx={statusStyle}>{getDomainStatusName(item[item2.keyValue])}</Box>         
          } else if (item[item2.keyValue]) {
            tempObject[item2.keyValue] = item[item2.keyValue]
          } else {
            tempObject[item2.keyValue] = ''
          }
        })

        tempArray.push(tempObject)
      })

      setTableData(tempArray)
    } else {
      setTableData([])
    }

    if (fetched && !location.pathname.includes('domain-epp-code-generate')) {
      setBackdropOpen(false)
    }
  }, [filteredDomains, tableFields, texts, theme, fetched, location])

  useEffect(() => {
    if (location.state && location.state.updateDomains) {
      setUpdate((prev) => prev + 1)
      navigate('/domains')
    }
  }, [navigate, location])
  
  return (
    <>
      <DomainsHead />

      <DomainsSubheader
        domains={domains}
        filterKeyword={filterKeyword}
        setFilterKeyword={setFilterKeyword}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setBackdropOpen={setBackdropOpen}
        setUpdate={setUpdate}
      />

      {
        width >= 900 ?
        <TableElement
          tableData={tableData}
          tableFields={tableFields}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        /> :
        <CompactTableElement
          tableData={tableData}
          tableFields={tableFields}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      } 

      { (tableData.length === 0 && !backdropOpen) && <NoResultBox>{texts.noDomainsNotification}</NoResultBox> }

      <CardElement
        open={cardOpen}
        setOpen={setCardOpen}
        content={cardContent}
        setContent={setCardContent}
        maxWidth='1300'
      />

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth={modalWidth}
        onModalClose={handleCloseModal}
      />

      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default DomainsContent