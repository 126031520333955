import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { SecondaryButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import BackdropElement from '../common/BackdropElement'

const AccountUserDelete = ({ setModalOpen, setUpdateUsers, accountId, deleteRecord }) => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const notificationStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.large.fontSize,
    textAlign: 'center',
    minWidth: width > settings.desktopBreakpoint ? '400px' : 'auto',
    marginBottom: '20px',
  }

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)

  const [backdropOpen, setBackdropOpen] = useState(false)

  const handleSubmit = async () => {
    setBackdropOpen(true)

    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getUsers + '/' + accountId + '/' + deleteRecord.id
    
    const response = await requestClients(requestUrl, 'DELETE', '', userData, dispatch, navigate)
    
    if (response && response === true) {
      setModalOpen(false)
      setUpdateUsers((prev) => prev + 1)
    }

    setBackdropOpen(false)    
  }

  return (
    <>
      <Box sx={notificationStyles}>{texts.deleteUserWarning.replace('###USER###', deleteRecord.email)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SecondaryButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.confirm}</SecondaryButton>
      </Box>
      <BackdropElement open={backdropOpen} />
    </>
  )
}

export default AccountUserDelete