import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  HomeVisualDomainsEngBox,
  HomeVisualDomainsGeoBox,
  HomeVisualDomainsTitleBox,
  HomeVisualDomainsExtensionBox,
  HomeVisualDomainsEngValueBox,
  HomeVisualDomainsGeoValueBox,
} from '../../themes/styles'

const HomeVisualDomains = ({ domains }) => {
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)

  const [engCount, setEngCount] = useState(0)
  const [geoCount, setGeoCount] = useState(0)

  const handleNavigate = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/domains', '_blank')
    } else if (e.type === 'click') {
      navigate('/domains')
    }
  }

  useEffect(() => {
    const eng_domains = domains.filter(item => item.domainType === 'eng')
    const geo_domains = domains.filter(item => item.domainType === 'geo')

    setEngCount(eng_domains.length)
    setGeoCount(geo_domains.length)
  }, [domains])
  
  return (
    <>
      <HomeVisualDomainsEngBox onClick={handleNavigate} onMouseDown={handleNavigate}>
        <HomeVisualDomainsEngValueBox>{ engCount !== 0 ? engCount : '+' }</HomeVisualDomainsEngValueBox>
        <HomeVisualDomainsTitleBox>{ engCount !== 0 ? texts.myDomains : texts.registration }</HomeVisualDomainsTitleBox>
        <HomeVisualDomainsExtensionBox>.ge</HomeVisualDomainsExtensionBox>
      </HomeVisualDomainsEngBox>

      <HomeVisualDomainsGeoBox onClick={handleNavigate} onMouseDown={handleNavigate}>
        <HomeVisualDomainsGeoValueBox>{ geoCount !== 0 ? geoCount : '+' }</HomeVisualDomainsGeoValueBox>
        <HomeVisualDomainsTitleBox>{ geoCount !== 0 ? texts.myDomains : texts.registration }</HomeVisualDomainsTitleBox>
        <HomeVisualDomainsExtensionBox>.გე</HomeVisualDomainsExtensionBox>
      </HomeVisualDomainsGeoBox>
    </>
  )
}

export default HomeVisualDomains