import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import ModalWindow from './ModalWindow'

const TemporaryNotification = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const portalLinkStyle = {
    color: theme.palette.secondary.main,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  }

  const buttonStyle = width < 900 ? {
    fontSize: theme.typography.regular.fontSize,
    marginBottom: '30px',
  } : {
    marginBottom: '30px',
  }

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  const handleOpenInstructionsModal = (content) => {
    setModalOpen(true)
    setModalTitle(texts.oldDomainInstructionText5)
    setModalContent(content)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const content = (
    <Box
      sx={{
        fontFamily: theme.typography.georgian.fontFamily,
        fontSize: theme.typography.regular.fontSize,
        marginBottom: '30px',
        textAlign: 'center',
      }}
    >
      <Box>
        <Box component='span'>{texts.oldDomainInstructionText1} </Box>
        <Box component='span' sx={portalLinkStyle} onClick={() => handleClick('https://domains.grena.ge/')}>domains.grena.ge</Box>
        <Box component='span'> / </Box>
        <Box component='span' sx={portalLinkStyle} onClick={() => handleClick('https://დომენი.გრენა.გე/')}>დომენი.გრენა.გე</Box>
        <Box component='span'>, {texts.oldDomainInstructionText2}</Box>
      </Box>
      <Box sx={portalLinkStyle} onClick={() => handleClick('https://domain.grena.ge/documents/instruction_eng.pdf')}>{texts.oldDomainInstructionText3}</Box>
      <Box sx={portalLinkStyle} onClick={() => handleClick('https://xn--oodcmkgd.xn--loddg6as.xn--node/documents/instruction_geo.pdf')}>{texts.oldDomainInstructionText4}</Box>
    </Box>
  )

  return (
    <>
      {
        width >= 1200 ?
        <>
          {content}
        </> :
        <>
          <SuccessButton variant='contained' disableRipple={true} sx={buttonStyle} onClick={() => handleOpenInstructionsModal(content)}>{texts.oldDomainInstructionText5}</SuccessButton>
        </>
      }

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth='lg'
        onModalClose={handleCloseModal}
      />
    </>
  )
}

export default TemporaryNotification