import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import { BorderedBlockBox, SubtitleBox, DnsErrorNotificationBox, IconWarningBox, IconErrorBox } from '../../themes/styles'
import CircleIcon from '../common/icons/CircleIcon'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import TableElement from '../common/table/TableElement'
import CompactTableElement from '../common/table/CompactTableElement'
import DomainDetailsDnsAdd from './DomainDetailsDnsAdd'
import FormCheckboxField from '../common/formFields/FormCheckboxField'
import BackdropElement from '../common/BackdropElement'

const DomainDetailsDns = ({
  domainUid,
  domainName,
  domainType,
  dnsRecords,
  nameservers,
  isGrenaNameservers,
  handleOpenDnsDelete,
  dnsManageRequest,
  handleOpenDnsNotification,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const containerStyles = {
    float: 'right',
    display: 'inline-block',
    transition: 'all 1s',
  }

  const [addForm, setAddForm] = useState(false)
  const [addFormDisplay, setAddFormDisplay] = useState('none')
  const [addFormWidth, setAddFormWidth] = useState('0px')
  const [editRecord, setEditRecord] = useState('')
  const [containerWidth, setContainerWidth] = useState('100%')
  const [tableFields, setTableFields] = useState([])
  const [tableData, setTableData] = useState([])
  const [useGrenaNs, setUseGrenaNs] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(true)

  const closeIconStyles = {
    marginRight: '15px',
    color: theme.palette.chineseSilver.main,
    cursor: 'pointer',

    '&:hover': {
      color: alpha(theme.palette.chineseSilver.main, 0.7),
    }
  }

  const switchLabelStyles = {
    color: theme.palette.weldonBlue.main,
    fontSize: theme.typography.small.fontSize,
  }

  const tooltipTitleStyles = {
    fontFamily: theme.typography.regular.fontFamily,
  }

  const handleToggleAddForm = (value) => {
    setAddForm(value)
    setEditRecord('')
  }

  const handleChangeUseGrenaNs = (value) => {
    if (value) {
      handleOpenDnsNotification({
        modalTitle: texts.useGrenaDomainServersAndFreeDnsService,
        domainType,
        domainUid,
        actionType: 'set_grena_ns',
      })
    }
  }

  useEffect(() => {
    if (addForm) {
      setContainerWidth('calc(60% - 10px)')

      setTimeout(() => {
        setAddFormDisplay('inline-block')
        setAddFormWidth(width >= 900 ? 'calc(40% - 10px)' : '90%')
      }, width >= 900 ? 1000 : 10)
    } else {
      setAddFormDisplay('none')
      setAddFormWidth('0px')

      setTimeout(() => {
        setContainerWidth('100%')
      }, 100)
    }
  }, [addForm, width])

  useEffect(() => {
    const tableFieldsFull = [
      {
        keyValue: 'name',
        content: 'Name',
      },
      {
        keyValue: 'ttl',
        content: 'TTL',
      },
      {
        keyValue: 'class',
        content: 'Class',
      },
      {
        keyValue: 'type',
        content: 'Type',
      },
      {
        keyValue: 'content',
        content: 'Record',
        valueType: 'list',
        customBodyStyle: { wordBreak: 'break-all' }
      },
      {
        keyValue: 'actions',
        content: '',
        customBodyStyle: { textAlign: 'right' }
      },
    ]

    const tableFieldsReduced = [
      {
        keyValue: 'name',
        content: 'Name',
      },
      {
        keyValue: 'content',
        content: 'Record',
        valueType: 'list',
        customBodyStyle: { wordBreak: 'break-all' }
      },
    ]

    if (addForm) {
      setTableFields(tableFieldsReduced)
    } else {
      setTimeout(() => {
        setTableFields(tableFieldsFull)
      }, 1000)      
    }    
  }, [addForm])

  useEffect(() => {
    const scrollToId = (id) => {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const handleOpenEditForm = (record) => {
      setAddForm(true)
      setEditRecord(record)
      scrollToId('dns_container')
    }

    const handleClickRecordDelete = (record) => {
      handleOpenDnsDelete({ record })
    }

    const getContent = (type, content, record) => {
      if (type === 'SOA') {
        const splittedContent = content.split(' ')
        let labeledContent = []

        splittedContent.forEach((item, index) => {
          let valueLabel = ''

          if (index === 2) {
            valueLabel = 'Serial Number: '
          } else if (index === 3) {
            valueLabel = 'Refresh: '
          } else if (index === 4) {
            valueLabel = 'Retry: '
          } else if (index === 5) {
            valueLabel = 'Expire: '
          } else if (index === 6) {
            valueLabel = 'Minimum TTL: '
          }

          labeledContent.push(valueLabel + item)
        })

        return labeledContent
      } else if (type === 'MX') {
        return [
          'Priority: ' + record.prio,
          'Destination: ' + content,
        ]
      } else if (type === 'SRV') {
        const splittedContent = content.split(' ')
        let labeledContent = []

        splittedContent.forEach((item, index) => {
          let valueLabel = ''

          if (index === 0) {
            valueLabel = 'Weight: '
          } else if (index === 1) {
            valueLabel = 'Port: '
          } else if (index === 2) {
            valueLabel = 'Hostname: '
          }

          labeledContent.push(valueLabel + item)
        })

        return [
          'Priority: ' + record.prio,
          ...labeledContent,
        ]
      } else {
        return [content]
      }
    }

    if (dnsRecords.length > 0 && tableFields.length > 0) {
      let tempArray = []

      dnsRecords.forEach(item => {
        let tempObject = { uid: item.uid }

        tableFields.forEach(item2 => {
          if (item2.keyValue === 'actions' && isAdmin) {
            tempObject[item2.keyValue] = item.type === 'SOA' ? (
              <IconWarningBox onClick={() => handleOpenEditForm(item)}>
                <EditIcon />
              </IconWarningBox>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconWarningBox onClick={() => handleOpenEditForm(item)}>
                  <EditIcon />
                </IconWarningBox>
                <IconErrorBox sx={{ marginLeft: '5px' }} onClick={() => handleClickRecordDelete(item)}>
                  <DeleteIcon />
                </IconErrorBox>
              </Box>
            )
          } else if (item2.keyValue === 'class') {
            tempObject[item2.keyValue] = 'IN'
          } else if (item[item2.keyValue] && item2.keyValue === 'content') {
            tempObject[item2.keyValue] = getContent(item.type, item[item2.keyValue], item)
          } else if (item[item2.keyValue]) {
            tempObject[item2.keyValue] = item[item2.keyValue]
          } else {
            tempObject[item2.keyValue] = ''
          }
        })

        tempArray.push(tempObject)
      })

      setTableData(tempArray)
      setBackdropOpen(false)
    } else {
      setTableData([])
    }
  }, [dnsRecords, tableFields, handleOpenDnsDelete, isAdmin])

  useEffect(() => {
    const getIsGrenaNameservers = () => {
      let result = false

      const nameserver_object = nameservers.find(item => item.name === 'ns4.grena.ge' || item.name === 'ns5.grena.ge')

      if (nameserver_object) {
        result = true
      }

      return result
    }

    setUseGrenaNs(getIsGrenaNameservers())
  }, [nameservers])
  
  return (
    <>
      {
        !backdropOpen && (
          <Box id='dns_container' sx={{ position: 'relative' }}>
            <BorderedBlockBox sx={{ display: addFormDisplay, width: addFormWidth, }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
                <Box sx={closeIconStyles} onClick={() => handleToggleAddForm(false)}>
                  <CloseIcon fontSize='large' />
                </Box>
                <SubtitleBox>{ editRecord ? texts.editRecord : texts.addRecord }</SubtitleBox>
              </Box>
              
              <Box sx={{ marginLeft: '42px' }}>
                <DomainDetailsDnsAdd
                  domainUid={domainUid}
                  domainName={domainName}
                  domainType={domainType}
                  editRecord={editRecord}
                  dnsManageRequest={dnsManageRequest}
                  handleToggleAddForm={handleToggleAddForm}
                />
              </Box>
            </BorderedBlockBox>

            {
              (width >= 900 || !addForm) &&
              <BorderedBlockBox sx={{ ...containerStyles, width: containerWidth, }}>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    {
                      isAdmin &&
                      <CircleIcon type='add' size='32' bg='weldonBlue' action={() => handleToggleAddForm(true)} />
                    }                  

                    {
                      !isGrenaNameservers && (
                        <>
                          <DnsErrorNotificationBox sx={{ margin: '0px 5px 0px 20px' }}>{texts.notActive}</DnsErrorNotificationBox>
                          <Tooltip placement='right' title={<Box sx={tooltipTitleStyles}>{texts.dnsManagementActivationNotification}</Box>}>
                            <IconErrorBox>
                              <InfoIcon />
                            </IconErrorBox>
                          </Tooltip>
                        </>
                      )
                    }                 
                  </Box>

                  {
                    !isGrenaNameservers && (
                      <Box>
                        <FormCheckboxField
                          label={texts.useGrenaDomainServersAndFreeDnsService}
                          checked={useGrenaNs}
                          disabled={useGrenaNs}
                          handleChange={handleChangeUseGrenaNs}
                          customLabelStyle={switchLabelStyles}
                        />
                      </Box>
                    )
                  }                
                </Box>

                <Box sx={{ marginLeft: '42px' }}>
                  {
                    width >= 900 ?
                    <TableElement
                      tableData={tableData}
                      tableFields={tableFields}
                      tableWidth='100%'
                    /> :
                    <CompactTableElement
                      tableData={tableData}
                      tableFields={tableFields}
                    />
                  }                
                </Box>        
                
              </BorderedBlockBox>
            }
            
          </Box>
        )
      }

      <BackdropElement open={backdropOpen} />
    </>    
  )
}

export default DomainDetailsDns