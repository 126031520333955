import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import Header from '../common/Header'
import LoginForm from './LoginForm'
import ModalWindow from '../common/ModalWindow'

const LoginPage = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const texts = useSelector(state => state.settings.langTexts)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const notificationStyles = {
      fontFamily: theme.typography.regular.fontFamily,
      fontSize: theme.typography.large.fontSize,
      textAlign: 'center',
      minWidth: width > settings.desktopBreakpoint ? '400px' : 'auto'
    }

    const handleOpenModal = (props) => {
      setModalOpen(true)
      setModalTitle(props.title)
      setModalContent(props.content)
    }

    if (location.state && location.state.SignUpOk) {
      handleOpenModal({
        title: texts.registerNewAccount,
        content: (
          <Box sx={notificationStyles}>
            <Box>{texts.registerOk1}</Box>
            <Box>{texts.registerOk2}</Box>
          </Box>
        )
      })      
    }

    if (location.state && location.state.emailConfirmOk) {
      handleOpenModal({
        title: texts.confirmEmail,
        content: <Box sx={notificationStyles}>{texts.emailIsConfirmed}</Box>
      })      
    }

    if (location.state && location.state.PasswordResetOk) {
      handleOpenModal({
        title: texts.passwordRecovery,
        content: <Box sx={notificationStyles}>{texts.passwordRecoveryOk}</Box>
      })      
    }

    if (location.state && location.state.PasswordResetConfirmOk) {
      handleOpenModal({
        title: texts.passwordRecovery,
        content: <Box sx={notificationStyles}>{texts.passwordHasChanged}</Box>
      })      
    }

    if (location.state && location.state.ValidationEmailResendOk) {
      handleOpenModal({
        title: texts.confirmEmail,
        content: <Box sx={notificationStyles}>{texts.registerOk2}</Box>
      })      
    }

    if (location.state && location.state.passwordUpdateOk) {
      handleOpenModal({
        title: texts.changePassword,
        content: <Box sx={notificationStyles}>{texts.passwordHasChanged}</Box>
      })     
    }

    if (location.state && location.state.errorOccured) {
      handleOpenModal({
        title: texts.error,
        content: <Box sx={notificationStyles}>{texts.errorOccured}</Box>
      })
    }

    if (location.state) {
      navigate('/')
    }
  }, [navigate, location, texts, theme, width])

  return (
    <>
      <Header />
      <Container maxWidth='xl'>
        <LoginForm />
      </Container>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth='lg'
        onModalClose={handleCloseModal}
      />
    </>
  )
}

export default LoginPage