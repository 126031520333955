import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import WifiIcon from '@mui/icons-material/Wifi'
import SpeedIcon from '@mui/icons-material/Speed'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PublicIcon from '@mui/icons-material/Public'
import { DatabaseBackup } from 'lucide-react'
import { SuccessButton } from '../../themes/styles'

const HostingTariffsItem = (props) => {
  const {
    name,
    price,
    backup,
    traffic,
    bandwidth,
    corp_emails,
    domains,
    services,
    handleOpenAddForm,
    handleOpenServices,
  } = props

  const theme = useTheme()

  const lang = useSelector(state => state.settings.currentLang)
  const texts = useSelector(state => state.settings.langTexts)

  const priceValue = price ? (
    <>
      <Box component='span'>{price} &#8382; / </Box>
      <Box component='span'>{texts.month}</Box>
    </>  
  ) : texts.priceCustom

  const wrapperStyle = {
    padding: '30px 50px',
    background: '#ffffff',
    border: `${theme.palette.primary.main} 1px solid`,
    borderRadius: '8px',
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',

    '@media(max-width: 1199px)': {
      margin: '0px 10px',
      padding: '20px 20px',
    },

    '@media(max-width: 320px)': {
      margin: '0px 10px',
      padding: '10px 10px',
    },
  }

  const titleStyle = {
    color: theme.palette.primary.main,
    fontSize: '20px',
    lineHeight: '28px',
    borderBottom: '1px solid rgba(7, 77, 120, 0.5)',
    paddingBottom: '10px',
    marginBottom: '10px',
    textAlign: 'center',

    '@media(max-width: 400px)': {
      minHeight: '70px',
    },

    '@media(max-width: 320px)': {
      fontSize: '16px',
    },
  }

  const priceStyle = {
    color: theme.palette.secondary.main,
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: '30px',
    textAlign: 'center',

    '@media(max-width: 320px)': {
      fontSize: '20px',
    },
  }

  const itemOptionStyle = {
    color: theme.palette.primary.main,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '60px',
    borderBottom: '1px solid rgba(7, 77, 120, 0.2)',

    '@media (max-width: 1800px)': {
      fontSize: '14px',
      lineHeight: '20px',
      minHeight: '50px',
    },
  }

  const itemIconStyle = {
    color: theme.palette.primary.main,
    marginRight: '20px',

    '@media(max-width: 320px)': {
      marginRight: '10px',
    },
  }

  const itemServicesStyle = {
    fontSize: '16px',
    lineHeight: '24px',
    borderBottom: `${theme.palette.primary.main} 1px solid`,
    paddingBottom: '5px',
    cursor: 'pointer',
    transition: 'all 0.2s',

    '&:hover': {
      borderBottom: `#ffffff 1px solid`,
    }
  }

  const handleSubmit = () => {
    handleOpenAddForm(name[lang])
  }

  return (
    <>
      <Box sx={wrapperStyle}>
        <Box sx={titleStyle}>{name[lang]}</Box>
        <Box sx={priceStyle}>{priceValue}</Box>

        <Box sx={itemOptionStyle}>
          <Box sx={itemIconStyle}>
            <WifiIcon fontSize='large' />
          </Box>
          <Box>{texts.traffic} {traffic[lang]}</Box>
        </Box>

        <Box sx={itemOptionStyle}>
          <Box sx={itemIconStyle}>
            <SpeedIcon fontSize='large' />
          </Box>
          <Box>{texts.speed} {bandwidth[lang]}</Box>
        </Box>

        <Box sx={itemOptionStyle}>
          <Box sx={itemIconStyle}>
            <DatabaseBackup size={32} />
          </Box>
          <Box>{backup[lang]} {texts.backup}</Box>
        </Box>

        <Box sx={itemOptionStyle}>
          <Box sx={itemIconStyle}>
            <AlternateEmailIcon fontSize='large' />
          </Box>
          <Box>{texts.corpEmails} {corp_emails}</Box>
        </Box>

        <Box sx={itemOptionStyle}>
          <Box sx={itemIconStyle}>
            <PublicIcon fontSize='large' />
          </Box>
          <Box>{texts.hostingDomains} {domains}</Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0px' }}>
          <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.order}</SuccessButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', }}>
          <Box sx={itemServicesStyle} onClick={() => handleOpenServices(services)}>{texts.services}</Box>
        </Box>        
      </Box>
    </>
  )
}

export default HostingTariffsItem