import React from 'react'
import { PageMenuItemBox, PageMenuItemActiveBox } from '../../themes/styles'

const DomainDetailsSubmenuItem = ({ title, page, activePage, setActivePage }) => {
  return (
    <>
      {
        page === activePage ? (
          <PageMenuItemActiveBox sx={{ margin: '0px 5px' }}>{title}</PageMenuItemActiveBox>
        ) : (
          <PageMenuItemBox sx={{ margin: '0px 5px' }} onClick={() => setActivePage(page)}>{title}</PageMenuItemBox>
        )
      }
    </>
  )
}

export default DomainDetailsSubmenuItem