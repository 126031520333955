import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'
import { SuccessButton, CircleWarningBox } from '../../themes/styles'
import useWindowDimensions from '../../helpers/windowDimensions'
import settings from '../../config/settings'
import { requestClients } from '../../network/request'
import { apiUsers } from '../../network/urls'
import { isValidPassword } from '../../helpers/string'
import { emptyUserData } from '../../store/reducers/userData'
import FormTextField from '../common/formFields/FormTextField'
import BackdropElement from '../common/BackdropElement'

const PasswordUpdateForm = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formWidth = width > settings.desktopBreakpoint ? '600px' : ''
  const passwordWidth = width > settings.desktopBreakpoint ? '560px' : 'calc(100% - 40px)'

  const tooltipStyles = {
    fontFamily: theme.typography.regular.fontFamily,
  }

  const alertStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.vividRed.main,
  }

  const errorHelperStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.small.fontSize,
    color: theme.palette.vividRed.main,
  }

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)

  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [backdropOpen, setBackdropOpen] = useState(false)

  const handleChangeOldPassword = (value) => {
    setOldPassword(value)

    if (oldPassword) {
      setErrors((prev) => {
        return { ...prev, oldPassword: '' }
      })
    }
  }

  const handleChangePassword = (value) => {
    setPassword(value)

    if (isValidPassword(value)) {
      setErrors((prev) => {
        return { ...prev, password: '' }
      })
    }
  }

  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value)

    if (value && value === password) {
      setErrors((prev) => {
        return { ...prev, confirmPassword: '' }
      })
    }
  }

  const sendRequest = async (postData) => {
    const requestUrl = settings.domain + settings.clientsUi + apiUsers.getUsersPasswordUpdate

    const response = await requestClients(requestUrl, 'PUT', postData, userData, dispatch, navigate)
    
    setBackdropOpen(false)

    if (response && response.status && response.status === 'updated') {
      dispatch(emptyUserData())
      navigate('/', { state: { passwordUpdateOk: true } })
    } else if (response && response.detail && response.detail === 'Invalid old password provided.') {
      setErrors((prev) => {
        return { ...prev, oldPassword: texts.incorrectOldPassword }
      })
    } else {
      setErrors((prev) => {
        return { ...prev, general: texts.errorOccured }
      })
    }
  }

  const handleSubmit = () => {
    setBackdropOpen(true)
    let errorsObject = {}

    if (!oldPassword) {
      errorsObject.oldPassword = texts.pleaseFillOut
    }

    if (!password) {
      errorsObject.password = texts.pleaseFillOut
    }

    if (password && !isValidPassword(password)) {
      errorsObject.password = texts.passwordRequirements
    }

    if (!confirmPassword) {
      errorsObject.confirmPassword = texts.pleaseFillOut
    }

    if (isValidPassword(password) && confirmPassword && password !== confirmPassword) {
      errorsObject.confirmPassword = texts.passwordNotMatch
    }

    if (Object.keys(errorsObject).length === 0) {
      const postData = {
        old_password: oldPassword,
        password,
        confirm_password: confirmPassword,
      }

      sendRequest(postData)
    } else {
      setBackdropOpen(false)
      setErrors(errorsObject)
    }    
  }

  return (
    <Box sx={{ minWidth: formWidth }}>

      {
        errors.general && (
          <Box sx={{ marginBottom: '20px' }}>
            <Alert severity='error' sx={alertStyles}>{errors.general}</Alert>
          </Box>
        )
      }

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          type='password'
          label={texts.oldPassword}
          placeholder={texts.oldPassword}
          value={oldPassword}
          required={true}
          error={errors.oldPassword ? true : false}
          helpertext={errors.oldPassword && <Box sx={errorHelperStyles} component='span'>{errors.oldPassword}</Box>}
          lines='1'
          handleChange={handleChangeOldPassword}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Box>
          <FormTextField
            type='password'
            label={texts.newPassword}
            placeholder={texts.newPassword}
            value={password}
            required={true}
            error={errors.password ? true : false}
            helpertext={errors.password && <Box sx={errorHelperStyles} component='span'>{errors.password}</Box>}
            lines='1'
            handleChange={handleChangePassword}
            fieldWidth={passwordWidth}
          />
        </Box>
        <Box sx={{ paddingTop: '16px' }}>
          <Tooltip placement='left' title={<Box sx={tooltipStyles} component='span'>{texts.passwordRequirements}</Box>}>
            <CircleWarningBox>i</CircleWarningBox>
          </Tooltip>
        </Box>        
      </Box>

      <Box sx={{ marginBottom: '20px' }}>
        <FormTextField
          type='password'
          label={texts.confirmPassword}
          placeholder={texts.confirmPassword}
          value={confirmPassword}
          required={true}
          error={errors.confirmPassword ? true : false}
          helpertext={errors.confirmPassword && <Box sx={errorHelperStyles} component='span'>{errors.confirmPassword}</Box>}
          lines='1'
          handleChange={handleChangeConfirmPassword}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.save}</SuccessButton>
      </Box>

      <BackdropElement open={backdropOpen} />

    </Box>
  )
}

export default PasswordUpdateForm