import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { HomeCardBox, HomeCardLabelBox, HomeCardButtonWrapperBox, HomeCardButtonBox } from '../../themes/styles'
import homeButtonImageVps from '../../themes/images/home_button_vps.png'
import homeButtonImageHosting from '../../themes/images/home_button_hosting.png'
import homeButtonImageDomains from '../../themes/images/home_button_domains.png'
import homeButtonImageInternet from '../../themes/images/home_button_internet.png'
import useWindowDimensions from '../../helpers/windowDimensions'
import { requestClients, requestDomains } from '../../network/request'
import HomeVisualVps from './HomeVisualVps'
import HomeVisualHosting from './HomeVisualHosting'
import HomeVisualDomains from './HomeVisualDomains'
import HomeVisualInternet from './HomeVisualInternet'
import ModalWindow from '../common/ModalWindow'
import ModalNotification from '../common/ModalNotification'
import ContentForm from '../common/forms/ContentForm'

const HomeCard = ({ service, requestUrl }) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const [dataList, setDataList] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalWidth, setModalWidth] = useState('lg')
  const [isLoading, setIsLoading] = useState(true)

  const serviceLabels = {
    vps: texts.virtualServers,
    hosting: texts.hosting,
    domains: texts.domains,
    internet: texts.internet,
  }

  const serviceColors = {
    vps: theme.palette.lavenderIndigo.main,
    hosting: theme.palette.greenCrayola.main,
    domains: theme.palette.secondary.main,
    internet: theme.palette.royalOrange.main,
  }

  const serviceButtonImages = {
    vps: homeButtonImageVps,
    hosting: homeButtonImageHosting,
    domains: homeButtonImageDomains,
    internet: homeButtonImageInternet,
  }

  const serviceButtonLabels = {
    vps: texts.order,
    hosting: texts.order,
    domains: texts.register,
    internet: texts.join,
  }

  const label = serviceLabels[service]
  const color = serviceColors[service]
  const buttonStyles = { background: `url(${serviceButtonImages[service]}) no-repeat center` }
  const buttonLabel = serviceButtonLabels[service]

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleOpenNotification = ({ title, content, size }) => {
    setModalOpen(true)
    setModalWidth(size ? size : 'sm')
    setModalTitle(title)
    setModalContent(<ModalNotification setModalOpen={setModalOpen} notificationText={content} />)
  }

  const handleOpenVpsAddForm = () => {
    setModalOpen(true)
    setModalTitle(texts.order)
    setModalWidth('lg')
    setModalContent(
      <ContentForm
        formName='contact_virtualization'
        handleCloseModal={handleCloseModal}
        handleOpenNotification={handleOpenNotification}
      />
    )
  }

  const handleOpenHostingAddForm = () => {
    setModalOpen(true)
    setModalTitle(texts.order)
    setModalWidth('lg')
    setModalContent(
      <ContentForm
        formName='contact_hosting'
        handleCloseModal={handleCloseModal}
        handleOpenNotification={handleOpenNotification}
      />
    )
  }

  const handleClickInactiveCard = () => {
    if (service === 'vps') {
      handleOpenVpsAddForm()
    }

    if (service === 'hosting') {
      handleOpenHostingAddForm()
    }

    if (service === 'domains') {
      navigate('/domains')
    }

    if (service === 'internet') {
      navigate('/internet')
    }
  }

  const handleClickCardLabel = () => {
    if (width < 900) {
      if (service === 'vps') {
        navigate('/vps')
      }
  
      if (service === 'hosting') {
        navigate('/hosting')
      }
  
      if (service === 'domains') {
        navigate('/domains')
      }
  
      if (service === 'internet') {
        navigate('/internet')
      }
    }    
  }

  useEffect(() => {
    (async () => {
      if (currentAccount) {
        if (service === 'domains') {
          const response = await requestDomains(requestUrl, 'GET', '', userData, dispatch, navigate)

          setDataList( response && response.status && response.status === 100 && response.data && response.data.length > 0 ? response.data.filter(item => item.status !== 'pending') : [] )
        } else {
          const response = await requestClients(requestUrl, 'GET', '', userData, dispatch, navigate)
          
          if (service === 'vps') {
            setDataList( response && response.length > 0 ? response.filter(item => item.state === 'running') : [] )
          } else {
            setDataList( response && response.length > 0 ? response.filter(item => item.status === 'active') : [] )
          }
        }
        
        setIsLoading(false)
      }      
    })()

    setTimeout(() => setIsLoading(false), 10000)
  }, [userData, dispatch, navigate, currentAccount, requestUrl, service])

  useEffect(() => {
    setIsActive(dataList.length > 0)
  }, [dataList])

  return (
    <>
      <HomeCardBox>
        <HomeCardLabelBox sx={{ color }} onClick={handleClickCardLabel}>{label}</HomeCardLabelBox>
        {
          width >= 900 &&
          <HomeCardButtonWrapperBox>
            {
              isLoading ?
              <>
                <Skeleton variant="rounded" height={40} sx={{ width: '80%', margin: '10px 0px' }} />
                <Skeleton variant="rounded" height={40} sx={{ width: '80%', margin: '10px 0px' }} />
                <Skeleton variant="rounded" height={40} sx={{ width: '80%', margin: '10px 0px' }} />
              </> :
              <>
                { !isActive && <HomeCardButtonBox sx={buttonStyles} onClick={handleClickInactiveCard}>{buttonLabel}</HomeCardButtonBox> }
              
                { (isActive && service === 'vps') && <HomeVisualVps virtualMachines={dataList} /> }

                { (isActive && service === 'hosting') && <HomeVisualHosting hostingDomains={dataList} /> }

                { (isActive && service === 'domains') && <HomeVisualDomains domains={dataList} /> }

                { (isActive && service === 'internet') && <HomeVisualInternet netLocations={dataList} /> }
              </>
            }            
          </HomeCardButtonWrapperBox>
        }        
      </HomeCardBox>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalWidth={modalWidth}
        onModalClose={handleCloseModal}
      />
    </>
  )
}

export default HomeCard