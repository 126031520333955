import React from 'react'
import DrawerElement from '../common/DrawerElement'
import HostingContent from './HostingContent'

const Hosting = () => {
  return (
    <DrawerElement content={<HostingContent />} />
  )
}

export default Hosting