import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { BorderedBlockBox, SubtitleBox } from '../../themes/styles'
import CircleIcon from '../common/icons/CircleIcon'
import useWindowDimensions from '../../helpers/windowDimensions'
import { getIsAccountOwner, getHasRole, getIsSuperadmin } from '../../helpers/users'
import FormCheckboxField from '../common/formFields/FormCheckboxField'
import DomainDetailsNameserversItem from './DomainDetailsNameserversItem'

const DomainDetailsNameservers = ({
  domainUid,
  domainName,
  domainType,
  nameservers,
  hasDns,
  handleOpenDnsNotification,
  handleOpenNameserversAdd,
  handleOpenNameserversDelete,
}) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const texts = useSelector(state => state.settings.langTexts)
  const userData = useSelector(state => state.userData)
  const currentAccount = useSelector(state => state.settings.currentAccount)

  const isSuperadmin = getIsSuperadmin(userData)
  const isAccountOwner = getIsAccountOwner(currentAccount, userData)
  const hasAdminRole = getHasRole('client-domains-admin', currentAccount, userData)
  const isAdmin = isSuperadmin || isAccountOwner || hasAdminRole

  const [useGrenaNs, setUseGrenaNs] = useState(false)
  const [dnsActivation, setDnsActivation] = useState(false)
  const [containerMargin, setContainerMargin] = useState('0px')
  
  const switchLabelStyles = {
    color: theme.palette.weldonBlue.main,
    fontSize: theme.typography.small.fontSize,
  }

  const handleChangeUseGrenaNs = (value) => {
    if (value) {
      handleOpenDnsNotification({
        modalTitle: texts.useGrenaDomainServersAndFreeDnsService,
        domainType,
        domainUid,
        actionType: 'set_grena_ns',
      })
    }
  }

  const handleChangeDnsActivation = (value) => {
    if (!useGrenaNs) {
      handleOpenDnsNotification({
        modalTitle: value ? texts.dnsManagementActivation : texts.dnsManagementDeactivation,
        domainType,
        domainUid,
        actionType: 'dns_service',
        action: value,
      })
    }
  }

  const handleClickNameserverAdd = () => {
    handleOpenNameserversAdd({ domainUid, domainName, domainType })
  }

  useEffect(() => {
    const getIsGrenaNameservers = () => {
      let result = false

      const nameserver_object = nameservers.find(item => item.name === 'ns4.grena.ge' || item.name === 'ns5.grena.ge')

      if (nameserver_object) {
        result = true
      }

      return result
    }

    setUseGrenaNs(getIsGrenaNameservers())
  }, [nameservers])

  useEffect(() => {
    if (useGrenaNs || hasDns) {
      setDnsActivation(true)
    } else {
      setDnsActivation(false)
    }
  }, [useGrenaNs, hasDns])

  useEffect(() => {
    if (width < 1200) {
      setContainerMargin('0px 0px 20px 0px')
    } else if (domainType === 'eng') {
      setContainerMargin('0px 10px')
    } else {
      setContainerMargin('0px 0px 0px 10px')
    }
  }, [width, domainType])

  return (
    <BorderedBlockBox
      sx={{
        minHeight: '310px',
        margin: containerMargin,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' }}>
        <SubtitleBox>{texts.nsServers}</SubtitleBox>
        {
          isAdmin &&
          <Box sx={{ marginLeft: '10px' }}>
            <CircleIcon type='add' size='32' bg='weldonBlue' action={handleClickNameserverAdd} />
          </Box>
        }        
      </Box>

      <Box sx={{ margin: '10px 0px' }}>
        <FormCheckboxField
          label={texts.useGrenaDomainServersAndFreeDnsService}
          checked={useGrenaNs}
          disabled={useGrenaNs || !isAdmin}
          handleChange={handleChangeUseGrenaNs}
          customLabelStyle={switchLabelStyles}
        />
      </Box>

      <Box sx={{ margin: '10px 0px' }}>
        <FormCheckboxField
          label={texts.dnsManagementActivation}
          checked={dnsActivation}
          disabled={useGrenaNs || !isAdmin}
          handleChange={handleChangeDnsActivation}
          customLabelStyle={switchLabelStyles}
        />
      </Box>

      {
        nameservers.map((item, index) => (
          <DomainDetailsNameserversItem
            key={index}
            {...item}
            domainUid={domainUid}
            domainType={domainType}
            handleOpenNameserversDelete={handleOpenNameserversDelete}
          />
        ))
      }
    </BorderedBlockBox>
  )
}

export default DomainDetailsNameservers