import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { SuccessButton } from '../../themes/styles'

const DomainDnsDelete = ({ setModalOpen, domainType, domainUid, record, dnsManageRequest }) => {
  const texts = useSelector(state => state.settings.langTexts)

  const handleSubmit = async () => {
    setModalOpen(false)

    const postData = {
      domainType,
      domainUid,
      action: 'delete',
      recordId: record.id,
      type: record.type,
    }

    await dnsManageRequest(postData)
  }

  return (
    <>
      <Box>{texts.deleteNsRecordNotification.replace('###NS###', record.type)}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '30px' }}>
        <SuccessButton variant='contained' disableRipple={true} onClick={handleSubmit}>{texts.confirm}</SuccessButton>
      </Box>
    </>
  )
}

export default DomainDnsDelete